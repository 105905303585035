import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import { actionUpdateManagementVehicle } from "../../services/VehicleService";

import Select from "react-select";

const tableLengthList = configDb.data.dataTableLength;

const energyType = [
  { value: "1", label: "Internal Combustion" },
  { value: "2", label: "Electric" },
  { value: "3", label: "Natural Gas" },
  { value: "4", label: "None" },
];

const fuelType = [
  { value: "1", label: "Diesel" },
  { value: "2", label: "Gasoline/Petrol" },
  { value: "3", label: "Other" },
];

const useFuelType = [{ value: "1", label: "Use Fixed Fuel Combustion" }];

const ManagementVehicle = ({ setActiveTab, vehicleDetails }) => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const VehicleData = localStorage.getItem("vehicleId");

  const [vehicleData, setVehicleData] = useState({
    energy_type_id: "",
    fuel_type: "",
    fuel_consumption_per_mileage: "",
    fuel_consumption_per_hour: "",
    fuel_tank_capacity: "",
    co2_emissions: "",
    fuel_consumption: "",
    fuel_medium_level: "",
    fuel_low_level: "",
    cost_per_km: "",
    cost_per_hour: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  useEffect(() => {
    if (vehicleDetails) {
      setVehicleData({
        energy_type_id: vehicleDetails?.energy?.energy_type_id,
        fuel_type: vehicleDetails?.energy?.fuel_type,
        fuel_consumption_per_mileage:
          vehicleDetails?.energy?.fuel_consumption_per_mileage,
        fuel_consumption_per_hour:
          vehicleDetails?.energy?.fuel_consumption_per_hour,
        fuel_tank_capacity: vehicleDetails?.energy?.fuel_tank_capacity,
        co2_emissions: vehicleDetails?.energy?.co2_emissions,
        fuel_consumption: vehicleDetails?.energy?.fuel_consumption,
        fuel_medium_level: vehicleDetails?.energy?.fuel_medium_level,
        fuel_low_level: vehicleDetails?.energy?.fuel_low_level,
        cost_per_km: vehicleDetails?.cost_per_hour,
        cost_per_hour: vehicleDetails?.cost_per_hour,
      });
    }
  }, [vehicleDetails]);

  const validateForm = () => {
    const {
      cost_per_km,
      cost_per_hour,
      fuel_consumption_per_mileage,
      fuel_consumption_per_hour,
      fuel_tank_capacity,
      co2_emissions,
      fuel_medium_level,
      fuel_low_level,
    } = vehicleData;

    const isValidNumberOrEmpty = (value) => {
      return value === "" || !isNaN(value);
    };

    if (
      !isValidNumberOrEmpty(cost_per_km) ||
      !isValidNumberOrEmpty(cost_per_hour) ||
      !isValidNumberOrEmpty(fuel_consumption_per_mileage) ||
      !isValidNumberOrEmpty(fuel_consumption_per_hour) ||
      !isValidNumberOrEmpty(fuel_tank_capacity) ||
      !isValidNumberOrEmpty(co2_emissions) ||
      !isValidNumberOrEmpty(fuel_medium_level) ||
      !isValidNumberOrEmpty(fuel_low_level)
    ) {
      toast.error(
        "Please enter valid numeric values for all fields where applicable."
      );
      return false;
    }

    if (fuel_medium_level !== "" && fuel_low_level !== "") {
      if (parseFloat(fuel_low_level) >= parseFloat(fuel_medium_level)) {
        toast.error("Fuel low level must be less than Fuel medium level.");
        return false;
      }
    }

    return true;
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    if (validateForm()) {
      let data = {
        vehicle_id: VehicleData,
        energy_type_id: vehicleData?.energy_type_id,
        fuel_type: vehicleData?.fuel_type,
        fuel_consumption_per_mileage: vehicleData?.fuel_consumption_per_mileage,
        fuel_consumption_per_hour: vehicleData?.fuel_consumption_per_hour,
        fuel_tank_capacity: vehicleData?.fuel_tank_capacity,
        co2_emissions: vehicleData?.co2_emissions,
        fuel_consumption: vehicleData?.fuel_consumption,
        fuel_medium_level: vehicleData?.fuel_medium_level,
        fuel_low_level: vehicleData?.fuel_low_level,
        cost_per_km: vehicleData?.cost_per_km,
        cost_per_hour: vehicleData?.cost_per_hour,
      };

      if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
        data.companyId = vehicleData.companyId;
      }

      let updateData = {
        vehicle_id: vehicleDetails.id,
        uuid: vehicleDetails?.energy?.uuid,
        energy_type_id: vehicleData?.energy_type_id,
        fuel_type: vehicleData?.fuel_type,
        fuel_consumption_per_mileage: vehicleData?.fuel_consumption_per_mileage,
        fuel_consumption_per_hour: vehicleData?.fuel_consumption_per_hour,
        fuel_tank_capacity: vehicleData?.fuel_tank_capacity,
        co2_emissions: vehicleData?.co2_emissions,
        fuel_consumption: vehicleData?.fuel_consumption,
        fuel_medium_level: vehicleData?.fuel_medium_level,
        fuel_low_level: vehicleData?.fuel_low_level,
        cost_per_km: vehicleData?.cost_per_km,
        cost_per_hour: vehicleData?.cost_per_hour,
      };

      if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
        updateData.companyId = vehicleData.companyId;
      }

      if (vehicleDetails?.energy?.uuid) {
        modifymanagament(actionUpdateManagementVehicle, updateData);
      } else {
        modifymanagament(actionUpdateManagementVehicle, data);
      }
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("extra");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Cost Per KM"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={vehicleData.cost_per_km}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              cost_per_km: e.target.value,
                            });
                          }}
                          name={`cost_per_km`}
                          maxLength={20}
                          placeholder="Cost Per KM"
                          min="0"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Cost per hour"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Cost per hour"
                          name={`cost_per_hour`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              cost_per_hour: e.target.value,
                            });
                          }}
                          value={vehicleData.cost_per_hour}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Energy type"}</Label>
                      <div className="mb-3">
                        <Select
                          options={energyType}
                          value={energyType.find(
                            (option) =>
                              option.value === vehicleData.energy_type_id
                          )}
                          onChange={(selectedOption) =>
                            setVehicleData((prevData) => ({
                              ...prevData,
                              energy_type_id: selectedOption.value,
                            }))
                          }
                          placeholder={"Select Energy type"}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Fuel consumption per mileage(L/100km)"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id="fuel_consumption_per_mileage"
                          placeholder="Fuel consumption per mileage (L/100km)"
                          name={`fuel_consumption_per_mileage`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              fuel_consumption_per_mileage: e.target.value,
                            });
                          }}
                          value={vehicleData.fuel_consumption_per_mileage}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Fuel consumption per hour( L/hour)"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Fuel consumption per hour( L/hour)"
                          name={`fuel_consumption_per_hour`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              fuel_consumption_per_hour: e.target.value,
                            });
                          }}
                          value={vehicleData.fuel_consumption_per_hour}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Fuel tank capacity(L)"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id="fuel_tank_capacity"
                          placeholder="Fuel tank capacity(L)"
                          name={`fuel_tank_capacity`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              fuel_tank_capacity: e.target.value,
                            });
                          }}
                          value={vehicleData.fuel_tank_capacity}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"CO2 emissions (kg/l)"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id="co2_emissions"
                          placeholder="CO2 emissions (kg/l)"
                          name={`co2_emissions`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              co2_emissions: e.target.value,
                            });
                          }}
                          value={vehicleData.co2_emissions}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Fuel type"}</Label>
                      <div className="mb-3">
                        <Select
                          options={fuelType}
                          value={fuelType.find(
                            (option) => option.value === vehicleData.fuel_type
                          )}
                          onChange={(selectedOption) =>
                            setVehicleData((prevData) => ({
                              ...prevData,
                              fuel_type: selectedOption.value,
                            }))
                          }
                          placeholder={"Select Fuel type"}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Fuel Consumption"}
                      </Label>
                      <div className="mb-3">
                        <Select
                          options={useFuelType}
                          value={useFuelType.find(
                            (option) =>
                              option.value === vehicleData.fuel_consumption
                          )}
                          onChange={(selectedOption) =>
                            setVehicleData((prevData) => ({
                              ...prevData,
                              fuel_consumption: selectedOption.value,
                            }))
                          }
                          placeholder={"Select Fuel Consumption"}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Fuel medium level"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Fuel medium level"
                          name={`fuel_medium_level`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              fuel_medium_level: e.target.value,
                            });
                          }}
                          value={vehicleData.fuel_medium_level}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Fuel low level"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Fuel low level"
                          name={`fuel_low_level`}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              fuel_low_level: e.target.value,
                            });
                          }}
                          value={vehicleData.fuel_low_level}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementVehicle;
