import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { Button } from "reactstrap";
import { getEmployeeDetails } from "../../services/EmployeeService";

const EmployeeDetails = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [employeeDetails, setEmployeeDetails] = useState("");

  useEffect(function () {
    if (uuid) {
      getEmployeeDetails({ uuid })
        .then((response) => {
          setEmployeeDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, []);

  return (
    <>
      <div>
        <div className="inner-header py-3">
          <div className="left-block">
            <h5>Employee Details</h5>
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            marginTop: "30px",
            marginBottom: "-53px",
            display: "flex",
            fontSize: "28px",
          }}
        >
          <Button
            style={{ color: "#fff", backgroundColor: "#b1b4b7" }}
            onClick={() => {
              navigate("/user");
            }}
          >
            <i className="bi bi-arrow-left"></i>
          </Button>
        </div>
        <div className="container-event mt-5">
          <div className="row">
            <div className="col-xl-3">
              <div className="card">
                <div className="imgg-tume">
                  <img
                    src={employeeDetails?.userImage}
                    className="card-img-top"
                    alt="..."
                  />
                </div>
                <div className="employeeDetails">
                  <div style={{ fontSize: "15px", fontWeight: "700" }}>
                    Name:
                    <span style={{ color: "gray" }}>
                      {" "}
                      {employeeDetails?.name}
                    </span>
                  </div>
                  <div style={{ fontSize: "15px", fontWeight: "700" }}>
                    Email:
                    <span style={{ color: "gray" }}>
                      {" "}
                      {employeeDetails?.email}
                    </span>
                  </div>
                  <div style={{ fontSize: "15px", fontWeight: "700" }}>
                    Number:
                    <span style={{ color: "gray" }}>
                      {" "}
                      +91 {employeeDetails?.number}
                    </span>
                  </div>
                  {employeeDetails?.user_role == 4 && (
                    <div style={{ fontSize: "15px", fontWeight: "700" }}>
                      DOB:
                      <span style={{ color: "gray" }}>
                        {" "}
                        {employeeDetails?.dob}
                      </span>
                    </div>
                  )}
                  {employeeDetails?.user_role == 4 && (
                    <div style={{ fontSize: "15px", fontWeight: "700" }}>
                      Address:
                      <span style={{ color: "gray" }}>
                        {" "}
                        {employeeDetails?.address}, {employeeDetails?.stateName}
                        , {employeeDetails?.countryName}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {employeeDetails?.user_role == 4 && (
              <div className="col-xl-9">
                <div className="card">
                  <div className="row">
                    <div className="col-xl-6">
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "700",
                          padding: "20px",
                        }}
                      >
                        LicenseFront
                        <div className="imgg-tume-license">
                          <img
                            src={employeeDetails?.licenseFront}
                            className="card-img-top"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "700",
                          padding: "20px",
                        }}
                      >
                        LicenseFront
                        <div className="imgg-tume-license">
                          <img
                            src={employeeDetails?.licenseBack}
                            className="card-img-top"
                            alt="..."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeDetails;
