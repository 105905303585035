import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfigDB from "../../config";
import {
  actionOrderDetails,
  actionOrderStatusChange,
} from "../../services/TripService";
import { toast } from "react-toastify";
import Utils from "../../utils";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { getOrderStateList } from "../../services/OrderStateService";

const tableLengthList = ConfigDB.data.dataTableLength;

const OrderDetails = () => {
  const { uuid } = useParams();
  const [tripDetails, setTripDetails] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: 0,
  });
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (tripDetails) {
      setSelectedStatus(tripDetails?.status_id);
    }
  }, [tripDetails]);

  useEffect(
    function () {
      let data = params;

      getOrderStateList(data)
        .then((response) => {
          setStatusList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setIsPopupOpen(true);
  };

  useEffect(
    function () {
      let data = {
        uuid: uuid,
      };
      actionOrderDetails(data)
        .then((response) => {
          setTripDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [uuid]
  );

  const onstatusChange = (e) => {
    e.preventDefault();
    let data = { uuid: tripDetails.uuid, status_id: selectedStatus };
    actionOrderStatusChange(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setIsPopupOpen(false);
        navigate("/order");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <div>
        <div className="inner-header py-3">
          <div className="left-block">
            <h5>Order Details</h5>
          </div>
        </div>
        <div
          style={{
            padding: "20px",
            marginTop: "30px",
            marginBottom: "-53px",
            display: "flex",
            fontSize: "28px",
          }}
        >
          <Button
            style={{ color: "#fff", backgroundColor: "#b1b4b7" }}
            onClick={() => {
              navigate("/order");
            }}
          >
            <i className="bi bi-arrow-left"></i>
          </Button>
        </div>

        <div className="container-event-profle">
          <div className="profle-block tab-content" id="nav-tabContent">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div
                      className="col"
                      style={{ marginLeft: "-35px", marginTop: "10px" }}
                    >
                      <div>
                        Total Distance:
                        <span style={{ color: "gray" }}>
                          {" "}
                          {tripDetails?.total_distance} km
                        </span>
                      </div>
                    </div>
                    <div className="col" style={{ marginTop: "10px" }}>
                      <div>
                        Total Duration:
                        <span style={{ color: "gray" }}>
                          {" "}
                          {tripDetails?.total_duration} min
                        </span>
                      </div>
                    </div>

                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col" style={{ marginTop: "14px" }}>
                        <div>
                          <i
                            className="bi bi-flag-fill me-2"
                            style={{ color: "#7ED328" }}
                          ></i>
                          <span style={{ color: "gray" }}>
                            {tripDetails?.source_place || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <table
                      className="table"
                      style={{ marginBottom: "33px", marginTop: "15px" }}
                    >
                      <thead>
                        <tr></tr>
                      </thead>
                      <tbody>
                        {tripDetails?.trip_details?.map((item, i) => {
                          return (
                            <tr>
                              <td></td>

                              <td>{item?.total_distance} Km</td>
                              <td>{item?.total_duration} min</td>
                              <td>{item?.source_place}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <div className="row">
                      <div className="col">
                        <div>
                          <i
                            className="bi bi-flag-fill me-2"
                            style={{ color: "red" }}
                          ></i>
                          <span style={{ color: "gray" }}>
                            {tripDetails?.destination_place || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="row" style={{ marginLeft: "40px" }}>
                    <div className="col">
                      <div>
                        <h5 style={{ fontSize: "17px", fontWeight: "500" }}>
                          Order Details:
                        </h5>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{ width: "240px", marginTop: "-14px" }}
                    >
                      <div className="mb-3">
                        {/* <Select
                          options={statusList}
                          value={statusList.find(
                            (option) => option.value === orderStatus
                          )}
                          onChange={(selectedOption) => {
                            setOrderStatus(selectedOption.value);
                            setIsPopupOpen(true);
                          }}
                          placeholder={"Select Status"}
                        /> */}

                        <select
                          id="status"
                          className="form-select"
                          value={selectedStatus}
                          onChange={handleStatusChange}
                        >
                          <option value="">Select status</option>
                          {statusList.map((status) => (
                            <option key={status.id} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col" style={{ marginTop: "10px" }}>
                        <div>
                          Reference Number:
                          <span style={{ color: "gray" }}>
                            {" "}
                            {tripDetails?.reference_number}
                          </span>
                        </div>
                      </div>
                      <div className="col" style={{ marginTop: "10px" }}>
                        <div>
                          Order Pin:
                          <span style={{ color: "gray" }}>
                            {" "}
                            {tripDetails?.pin}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isPopupOpen}>
        <ModalHeader>{`Order Status Change`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to order status change?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            outline
            onClick={handleClosePopup}
            className={`btn-square`}
          >{`Close`}</Button>
          <Button
            color="secondary"
            type={`button`}
            className={`btn-square`}
            onClick={onstatusChange}
          >{`Save`}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default OrderDetails;
