import React from "react";
import About1 from "../../assets/images/about1.jpg";
import About2 from "../../assets/images/about2.jpg";
import Team1 from "../../assets/images/team1.jpg";
import Team2 from "../../assets/images/team2.jpg";
import Team3 from "../../assets/images/team3.jpg";
import Team4 from "../../assets/images/team4.jpg";
import Counter from "./Counter";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <div>
        <section className="page-title page-title-mini">
          <div className="container">
            <div className="page-title-row">
              <div className="page-title-content">
                <h1>About Us</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/">Pages</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>
        <section className="why-choose-section">
          <div className="container">
            <div className="row col-mb-50 mb-0">
              <div className="col-lg-4">
                <div className="heading-block title-bottom-border">
                  <h4>
                    Why choose <span>Us</span>.
                  </h4>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quasi quidem minus id omnis, nam expedita, ea fuga commodi
                  voluptas iusto, hic autem deleniti dolores explicabo labore
                  enim repellat earum perspiciatis.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="heading-block title-bottom-border">
                  <h4>
                    Our <span>Mission</span>.
                  </h4>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quasi quidem minus id omnis, nam expedita, ea fuga commodi
                  voluptas iusto, hic autem deleniti dolores explicabo labore
                  enim repellat earum perspiciatis.
                </p>
              </div>
              <div className="col-lg-4">
                <div className="heading-block title-bottom-border">
                  <h4>
                    What we <span>Do</span>.
                  </h4>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Quasi quidem minus id omnis, nam expedita, ea fuga commodi
                  voluptas iusto, hic autem deleniti dolores explicabo labore
                  enim repellat earum perspiciatis.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Counter />
        {/* ======= About Us Section ======= */}
        <section className="about-section ">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>About Us</h2>
              <p>
                Aperiam dolorum et et wuia molestias qui eveniet numquam nihil
                porro incidunt dolores placeat sunt id nobis omnis tiledo stran
                delop
              </p>
            </div>
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="about-image">
                  <img
                    alt="Team working together in an office"
                    loading="lazy"
                    width={360}
                    height={300}
                    decoding="async"
                    data-nimg={1}
                    src={About1}
                    style={{ color: "transparent" }}
                  />
                  <img
                    alt="Company event with employees collaborating"
                    loading="lazy"
                    width={360}
                    height={300}
                    decoding="async"
                    data-nimg={1}
                    src={About2}
                    style={{ color: "transparent" }}
                  />
                </div>
                <a href="#" className="glightbox play-btn" />
              </div>
              <div className="col-lg-6">
                <div className="content ps-0 ps-lg-5">
                  <div className="position-relative mt-4"></div>
                  <p className="fst-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <ul>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i className="bi bi-check-circle-fill" /> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                  </ul>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End About Us Section */}
        <section className="services-area-bg">
          <div className="container">
            <div className="section-title">
              <h2>We Offer Professional Solutions</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-lg-4 col-sm-6 aos-init"
                data-aos="fade-in"
                data-aos-duration={1000}
                data-aos-delay={100}
              >
                <div className="single-services-box">
                  <div className="icon">
                    <i className="bi bi-bar-chart" />
                  </div>
                  <h3>
                    <a href="#">Data Analysts</a>
                  </h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 aos-init"
                data-aos="fade-in"
                data-aos-duration={1000}
                data-aos-delay={200}
              >
                <div className="single-services-box">
                  <div className="icon">
                    <i className="bi bi-gear-wide-connected" />
                  </div>
                  <h3>
                    <a href="#">Automatic Optimization</a>
                  </h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 aos-init"
                data-aos="fade-in"
                data-aos-duration={1000}
                data-aos-delay={300}
              >
                <div className="single-services-box">
                  <div className="icon">
                    <i className="bi bi-lock" />
                  </div>
                  <h3>
                    <a href="#">Security &amp; Surveillance</a>
                  </h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 aos-init"
                data-aos="fade-in"
                data-aos-duration={1000}
                data-aos-delay={400}
              >
                <div className="single-services-box">
                  <div className="icon">
                    <i className="bi bi-balloon-heart-fill" />
                  </div>
                  <h3>
                    <a href="#">Healthcare &amp; Manufacturing</a>
                  </h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 aos-init"
                data-aos="fade-in"
                data-aos-duration={1000}
                data-aos-delay={500}
              >
                <div className="single-services-box">
                  <div className="icon">
                    <i className="bi bi-database-fill-gear" />
                  </div>
                  <h3>
                    <a href="#">Software Engineers</a>
                  </h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6 aos-init"
                data-aos="fade-in"
                data-aos-duration={1000}
                data-aos-delay={600}
              >
                <div className="single-services-box">
                  <div className="icon">
                    <i className="bi bi-person" />
                  </div>
                  <h3>
                    <a href="#">IT Professionals</a>
                  </h3>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="team-area">
          <div className="container">
            <div className="section-title">
              <h2>Meet Our Team</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-sm-6">
                <div className="single-team-box">
                  <div className="image">
                    <img alt="Team Image" src={Team1} />
                    <div className="social">
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-facebook" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-twitter-x" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="content">
                    <h3>John Smith</h3>
                    <span>CEO &amp; Founder</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-team-box">
                  <div className="image">
                    <img alt="Team Image" src={Team2} />
                    <div className="social">
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-facebook" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-twitter-x" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="content">
                    <h3>Lucy Eva</h3>
                    <span>React Developer</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-team-box">
                  <div className="image">
                    <img alt="Team Image" src={Team3} />
                    <div className="social">
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-facebook" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-twitter-x" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="content">
                    <h3>Steven Smith</h3>
                    <span>Web Developer</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-team-box">
                  <div className="image">
                    <img alt="Team Image" src={Team4} />
                    <div className="social">
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-facebook" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-twitter-x" />
                      </a>
                      <a href="#" target="_blank" rel="noreferrer">
                        <i className="bi bi-instagram" />
                      </a>
                    </div>
                  </div>
                  <div className="content">
                    <h3>Sarah Taylor</h3>
                    <span>Designer</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
