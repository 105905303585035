import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";

const GeneralFilters = ({ setActiveTab, setGeneralData, addFiltersData }) => {
  const [employeeData, setEmployeeData] = useState({
    name: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (addFiltersData?.uuid) {
      setEmployeeData(addFiltersData);
    }
  }, [addFiltersData]);

  const handleEmployeeForm = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;

    if (!employeeData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    setGeneralData(employeeData);

    setActiveTab("vehicle");
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleEmployeeForm}>
              <h4>General</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Filter name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={
                          addFiltersData?.uuid
                            ? employeeData?.name
                            : employeeData?.name
                        }
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        placeholder="Filter name"
                      />

                      {errors.name && (
                        <span className="input-error">{errors.name}</span>
                      )}
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Next`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralFilters;
