import React from "react";
import ContactImage from "../../assets/images/contact.png";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div>
      <div>
        <section className="page-title page-title-mini">
          <div className="container">
            <div className="page-title-row">
              <div className="page-title-content">
                <h1>Contact Us</h1>
              </div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/">Pages</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact Us
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>
        <section className="contact-area">
          <div className="container">
            <div className="section-title">
              <span>Message Us</span>
              <h2>Drop us Message for any Query</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="contact-form">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-12">
                  <div className="contact-image">
                    <img alt="" src={ContactImage} />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            placeholder="Name"
                            className="form-control"
                            required
                            type="text"
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            placeholder="Email"
                            className="form-control"
                            required
                            type="email"
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            placeholder="Phone number"
                            className="form-control"
                            required
                            type="text"
                            name="number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            placeholder="Subject"
                            className="form-control"
                            required
                            type="text"
                            name="subject"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="text"
                            cols={30}
                            rows={6}
                            placeholder="Write your message..."
                            className="form-control"
                            required
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <button type="submit" className="btn">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="contact-info">
              <div className="contact-info-content">
                <h3>Contact us by Phone Number or Email Address</h3>
                <h2>
                  <span className="number">+91 130 629 8615</span>
                  <br />
                  <span className="email">info@contact.com</span>
                </h2>
                <ul className="social">
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <i className="bi bi-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <i className="bi bi-youtube" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <i className="bi bi-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <i className="bi bi-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      <i className="bi bi-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
