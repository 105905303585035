import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo.png";
import { actionFuelCostReports } from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as XLSX from "xlsx";
import moment from "moment/moment";

const CostFuelList = () => {
  const [alarmsDetailsList, setAlarmsDetailsList] = useState([]);
  const storedData = localStorage.getItem("costFuelReportData");
  const parsedData = JSON.parse(storedData);

  useEffect(() => {
    let data = {
      vehicle_id: parsedData?.vehicle_id,
      reportDate: parsedData?.reportDate,
    };
    actionFuelCostReports(data)
      .then((response) => {
        setAlarmsDetailsList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const exportToExcel = () => {
    if (!alarmsDetailsList || alarmsDetailsList.length === 0) {
      toast.error("No data to export!");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(alarmsDetailsList);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Alarms");

    XLSX.writeFile(workbook, "fuel_cost_details.xlsx");
  };

  return (
    <div>
      <h1>Cost Fuel Reports</h1>
      {/* {alarmsDetailsList?.length > 0 ? ( */}
      <>
        <div className="tablePage">
          <div className="container-fluid">
            <div className="tablePageMain mt-2">
              <div className>
                <div className="logoimg">
                  <img src={Logo} alt="logoimg" />
                </div>
              </div>
              <div className>
                <div className="Content">
                  <div>
                    <h3>COST AND FUEL</h3>
                    <h5 style={{ cursor: "pointer" }} onClick={exportToExcel}>
                      Export to Excel
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {alarmsDetailsList?.length > 0 ? (
              <div className="custom-table mt-4">
                <table className="table table-striped table-hover">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Vehicle Name</th>
                      <th scope="col">Fuel Rate GPS</th>
                      <th scope="col">Trip Odometer</th>
                      <th scope="col">Fuel Used GPS</th>
                      <th scope="col">Total Cost</th>
                      <th scope="col">Cost Odometer</th>
                      <th scope="col">Date</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Individual Cost</th>
                      <th scope="col">Total Odometer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alarmsDetailsList?.map((alarm) => {
                      return (
                        <tr>
                          <td>{alarm?.vehicle_name}</td>
                          <td>{alarm?.fuel_rate_gps}</td>
                          <td>{alarm?.trip_odometer}</td>
                          <td>{alarm?.fuel_used_gps}</td>
                          <td>{alarm?.cost[0]?.total_cost}</td>
                          <td>{alarm?.cost[0]?.odometer}</td>
                          <td>
                            {moment(alarm?.cost[0]?.cost_date).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td>
                            {moment(
                              alarm?.recurring_costs[0]?.expirey_date
                            ).format("DD/MM/YYYY")}
                          </td>

                          <td>{alarm?.recurring_costs[0]?.individual_cost}</td>

                          <td>{alarm?.total_odometer}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No Cost Fuel details available.</p>
            )}
          </div>
        </div>
      </>
      {/* ) : (
      )} */}
    </div>
  );
};

export default CostFuelList;
