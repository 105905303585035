import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DrawingManager,
} from "@react-google-maps/api";
import React, { useCallback, useRef, useState } from "react";
import ConfigDB from "../../config";
import {
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const containerStyle = {
  width: "100%",
  height: "990px",
};

const center = {
  lat: 38.78939581924669,
  lng: -9.386177246912233,
  placeName: "Tanzania",
};
const initialZoom = 7;

const MapArea = ({ setGeoFenceRadiuss, AreaData }) => {
  const [geoFenceRadius, setGeoFenceRadius] = useState(null);
  const [originZone, setOriginZone] = useState(null);
  const [map, setMap] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: ConfigDB.data.GOOGLE_MAP_KEY,
    libraries: ["places", "drawing"],
  });

  if (loadError) {
    console.error("Google Maps API failed to load: ", loadError);
  }

  const mapRef = useRef();

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  });

  const mapOptions = {
    styles: [
      {
        elementType: "geometry",
        stylers: [{ color: "#f5f5f5" }],
      },
      {
        elementType: "labels.icon",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [{ color: "##f0eef0" }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#bdd6a4" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#bdd6a4", visibility: "on" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e", visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [{ color: "#757575" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#dadada" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#616161" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9e9e9e" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#e5e5e5" }],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#b8edff" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#b8edff" }],
      },

      {
        featureType: "poi.medical",
        elementType: "geometry",
        stylers: [{ color: "#f0eef0" }],
      },

      {
        featureType: "poi.medical",
        elementType: "geometry.fill",
        stylers: [{ visibility: "off" }],
      },
    ],
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
  };

  const panToZone = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setOriginZone({ lat, lng, placeName });
  }, []);

  const onCircleComplete = (circle) => {
    const vehicle_radius = circle.getRadius();
    const center = circle.getCenter();
    setGeoFenceRadius({
      center: center.toJSON(),
      vehicle_radius: vehicle_radius,
    });
    setGeoFenceRadiuss({
      center: center.toJSON(),
      vehicle_radius: vehicle_radius,
    });
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  });

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={initialZoom}
          onLoad={onMapLoad}
          onUnmount={onUnmount}
          options={{
            ...mapOptions,
            gestureHandling: "auto",
            scrollwheel: true,
          }}
        >
          <DrawingManager
            onLoad={(drawingManager) => {
              drawingManager.setOptions({
                drawingControl: true,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
                },
                circleOptions: {
                  clickable: true,
                  editable: true,
                  draggable: true,
                  strokeColor: "#26aae16e",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#26aae147",
                  fillOpacity: 0.35,
                  zIndex: 1,
                },
              });

              const drawingControlButton = document.querySelector(
                ".gm-style-mtc > div > div > div"
              );
              if (drawingControlButton) {
                drawingControlButton.style.width = "40px";
                drawingControlButton.style.height = "40px";
              }
            }}
            onCircleComplete={onCircleComplete}
          />

          {AreaData?.area_type === "1" &&
            AreaData.radius_latitude &&
            AreaData.radius_longitude && (
              <Marker
                position={{
                  lat: parseFloat(AreaData.radius_latitude),
                  lng: parseFloat(AreaData.radius_longitude),
                }}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: Math.sqrt(AreaData.vehicle_radius),
                  strokeColor: "#26aae16e",
                  fillColor: "#26aae147",
                  fillOpacity: 1,
                  strokeWeight: 2,
                }}
              />
            )}
        </GoogleMap>
      ) : (
        <></>
      )}

      <div className="col-md-12">
        <SearchZone panToZone={panToZone} />
      </div>
    </div>
  );
};

const SearchZone = ({ panToZone, setOriginZone }) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      if (results.length === 0) {
        throw new Error("No results found");
      }
      const { lat, lng } = await getLatLng(results[0]);
      panToZone({ lat, lng });
      setOriginZone({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ padding: "10px", paddingBottom: "0" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Search Location
          </Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data?.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};
export default MapArea;
