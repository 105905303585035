import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import GeneralDriver from "./GeneralDriver";
import ProfessonalDriver from "./ProfessonalDriver";
import LicenseDriver from "./LicenseDriver";
import EmergencyDriver from "./EmergencyDriver";
import PersonalInfoDriver from "./PersonalInfoDriver";
import DriverStatus from "./DriverStatus";
import TachnographDriver from "./TachnographDriver";
import { getDriverAllDetails } from "../../services/EmployeeService";
import PrivacyMode from "./PrivacyMode";

const AddDriver = ({
  setAddDriverStatus,
  DriverData,
  setActiveTabs,
  setRefreshKey,
}) => {
  const [activeTab, setActiveTab] = useState("general");
  const [driverDetails, setDriverDetails] = useState({});

  let DriverId = localStorage.getItem("driverId");

  const isTabEnabled = DriverId || DriverData?.uuid;

  const handleTabClick = (tab) => {
    if (!isTabEnabled && tab !== "general") return;
    setActiveTab(tab);
  };

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };

  useEffect(function () {
    if (DriverData?.uuid) {
      let data = {
        uuid: DriverData?.uuid,
      };
      getDriverAllDetails(data)
        .then((response) => {
          setDriverDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, []);

  const fetchUpdatedVehicles = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5>Management</h5>
        </div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => {
              setAddDriverStatus(false);
              setActiveTabs("driver");
              fetchUpdatedVehicles();
            }}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "professonal" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("professonal");
                }}
              >
                <span data-href="#tab-1">Professonal</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "driving_license" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driving_license");
                }}
              >
                <span data-href="#tab-1">Driving License</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "emergency" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("emergency");
                }}
              >
                <span data-href="#tab-1">Emergency</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "personal_Info" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("personal_Info");
                }}
              >
                <span data-href="#tab-1">Personal Info</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "tachnograph" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("tachnograph");
                }}
              >
                <span data-href="#tab-1">Tachograph</span>
              </li>

              <li
                className={`nav-link ${activeTab === "status" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("status");
                }}
              >
                <span data-href="#tab-1">Status</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "privacyMode" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("privacyMode");
                }}
              >
                <span data-href="#tab-1">Privacy Mode</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralDriver
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
              />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "professonal" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <ProfessonalDriver
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "driving_license" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <LicenseDriver
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "emergency" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <EmergencyDriver
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "personal_Info" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <PersonalInfoDriver
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "tachnograph" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <TachnographDriver
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "status" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <DriverStatus
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "privacyMode" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <PrivacyMode
                setActiveTab={setActiveTab}
                driverDetails={driverDetails}
                setAddDriverStatus={setAddDriverStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDriver;
