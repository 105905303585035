import React, { useState } from "react";
import {
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { getVitualOdometerReportList } from "../../services/ReportsService";

const VirtualOdometerReports = () => {
  const [AlarmsData, setAlarmsData] = useState({
    show_information_by: "",
    show_information_id: "",
    show_information_type: "",
    driver_id: "",
    alarms: "",
    include_notes: "",
    subject: "",
    email: "",
  });
  const [modifyModal, setModifyModal] = useState(null);

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleAddNotificationForm = async (e) => {
    e.preventDefault();

    try {
      const data = await getVitualOdometerReportList();
      window.open("/virtual-odometer-reports", "_blank");
    } catch (err) {
      toast.error(Utils.getErrorMessage(err));
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleAddNotificationForm}>
              <h6>VIRTUAL ODOMETER</h6>
              <h6>
                This report lists the vehicles that compose the fleet and shows
                the value of the virtual odometer.
              </h6>
              <div className="d-flex justify-end">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setAlarmsData({
                          ...AlarmsData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={AlarmsData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setAlarmsData({
                          ...AlarmsData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={AlarmsData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default VirtualOdometerReports;
