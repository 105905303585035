import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";

const AccountDeletion = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isChecked) {
      alert("You must agree before submitting.");
      return;
    }

    setShowModal(true); // Show the modal instead of alert
  };

  const handleConfirmDelete = () => {
    toast.success("Account deletion request submitted!");
    setIsChecked(false);
    setEmail("");
    setShowModal(false);
  };

  return (
    <div>
      <div>
        <div>
          <section className="page-title page-title-mini">
            <div className="container">
              <div className="page-title-row">
                <div className="page-title-content">
                  <h1>Delete Account</h1>
                </div>
              </div>
            </div>
          </section>

          <section className="team-area team-area-custom">
            <div className="container" style={{ width: "50%" }}>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    required
                  />
                  <label className="form-check-label">
                    I understand that my account will be permanently deleted.
                  </label>
                </div>
                <button type="submit" className="btn btn-danger w-100">
                  Delete My Account
                </button>
              </form>
            </div>
          </section>
        </div>
      </div>

      <Modal isOpen={showModal} style={{ marginTop: "170px" }}>
        <div className="modal-content" style={{ width: "140%" }}>
          <ModalHeader>{`Confirm Account Deletion`}</ModalHeader>
          <ModalBody>Are you sure you want to delete your account?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={() => setShowModal(false)}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`button`}
              className={`btn-square`}
              onClick={handleConfirmDelete}
            >{`Yes`}</Button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default AccountDeletion;
