import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logoImg from "../assets/images/logo.svg";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const onLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const onSignup = (e) => {
    e.preventDefault();
    navigate("/company-registration");
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <div>
      <header id="header" className="fixed-top fixed-top-home-pag">
        <div className="container d-flex align-items-center">
          <a href="index.html" className="logo me-auto">
            <img src={logoImg} alt="Logo" className="img-fluid" />
          </a>

          <nav
            id="navbar"
            className={`navbar navbar-home-page ${
              isNavbarOpen ? "navbar-mobile" : ""
            }`}
          >
            <ul>
              <Link
                to="/"
                className={
                  location.pathname === "/" ? "nav-link active" : "nav-link"
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "30.05px",
                  fontFamily: '"Manrope", sans-serif',
                }}
              >
                Home
              </Link>
              <Link
                to="/about"
                className={
                  location.pathname === "/about"
                    ? "nav-link active"
                    : "nav-link"
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "30.05px",
                  fontFamily: '"Manrope", sans-serif',
                }}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                className={
                  location.pathname === "/contact"
                    ? "nav-link active"
                    : "nav-link"
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "30.05px",
                  fontFamily: '"Manrope", sans-serif',
                }}
              >
                Contact Us
              </Link>
              <Link
                to="/api-documentation"
                className={
                  location.pathname === "/api-documentation"
                    ? "nav-link active"
                    : "nav-link"
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "30.05px",
                  fontFamily: '"Manrope", sans-serif',
                }}
              >
                Api Documentation
              </Link>
              <li>
                <Link
                  className="getstarted"
                  href="#"
                  onClick={onLogin}
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "30.05px",
                    fontFamily: '"Manrope", sans-serif',
                  }}
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  className="sign-up"
                  href="#"
                  onClick={onSignup}
                  style={{
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "30.05px",
                    fontFamily: '"Manrope", sans-serif',
                  }}
                >
                  Sign up
                </Link>
              </li>
            </ul>
            <i
              className="bi bi-list mobile-nav-toggle"
              onClick={toggleNavbar}
              style={{ cursor: "pointer" }}
            ></i>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
