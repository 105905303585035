import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  createCost,
  createEntities,
  createFixedCost,
  createRecurringCost,
  createVariableCost,
  deleteCost,
  deleteEntities,
  deleteFixedCost,
  deleteRecurringCost,
  deleteVariableCost,
  getCostList,
  getCostRecurringList,
  getEntitiesAllList,
  getEntitiesList,
  getFixedAllCostList,
  getFixedCostList,
  getFleetList,
  getVariableAllCostList,
  getVariableCostList,
} from "../../services/ManagementService";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import { getVehicleList } from "../../services/VehicleService";
import moment from "moment/moment";

const tableLengthList = configDb.data.dataTableLength;

const Management = () => {
  const subscriptionTypeList = ConfigDB.data.SUBSCRIPTION_TYPE_LIST;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const location = useLocation();

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [activeTab, setActiveTab] = useState("energy");

  const [activeSubTab, setActiveSubTab] = useState("Fixed cost");
  const [totalRecords, setTotalRecords] = useState(0);
  const [fixedData, setFixedData] = useState({
    name: "",
    uuid: "",
  });
  const [variableData, setVariableData] = useState({
    name: "",
    unit: "",
    uuid: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  let errorsObjVar = {
    name: "",
    unit: "",
  };
  const [errorsVar, setErrorsVar] = useState(errorsObjVar);

  const [entitiesData, setEntitiesData] = useState({
    name: "",
    description: "",
    tax_number: "",

    address: "",
    zip_code: "",
    city: "",
    country: "",
    fixed_cost_type_id: "",
    variable_cost_type_id: "",
    contact_name: "",
    phone_number: "",
    uuid: "",
  });

  let errorsObjEnt = {
    name: "",
    description: "",
    tax_number: "",

    address: "",
    zip_code: "",
    city: "",
    country: "",
    fixed_cost_type_id: "",
    variable_cost_type_id: "",
    contact_name: "",
    phone_number: "",
  };
  const [errorsEnt, setErrorsEnt] = useState(errorsObjEnt);

  const [costData, setCostData] = useState({
    vehicle_id: "",
    entity_id: "",
    cost_type_id: "",
    total_cost: "",
    odometer: "",
    location: "",
    document: "",
    country: "",
    cost_description: "",
    notes: "",
    date: "",
    id: "",
  });

  const [costRecurringData, setCostRecurringData] = useState({
    vehicle_id: "",
    entity_id: "",
    cost_type_id: "",
    individual_cost: "",
    date: "",
    is_expiry: "0",
    expirey_date: "",
    uuid: "",
  });

  let errorsObjCost = {
    vehicle_id: "",
    entity_id: "",
    cost_type_id: "",
    total_cost: "",
    odometer: "",
    location: "",
    document: "",
    country: "",
    cost_description: "",
    notes: "",
    dates: "",
  };
  const [errorCost, setErrorCost] = useState(errorsObjCost);

  let errorsObjRec = {
    vehicle_id: "",
    entity_id: "",
    cost_type_id: "",
    individual_cost: "",
    dates: "",
  };
  const [errorCostRec, setErrorCostRec] = useState(errorsObjRec);

  const [fixedCostList, setfixedCostList] = useState([]);
  const [variableCostList, setvariableCostList] = useState([]);
  const [fixedCostAllList, setfixedCostAllList] = useState([]);
  const [variableCostAllList, setvariableCostAllList] = useState([]);
  const [modifyModal, setModifyModal] = useState(false);
  const [delFixedCost, setDelFixedCost] = useState("");
  const [delModal, setDelModal] = useState(false);
  const [modifyVariableModal, setModifyVariableModal] = useState(false);
  const [delVariableCost, setDelVariableCost] = useState("");
  const [delVariableModal, setVariableDelModal] = useState(false);
  const [entitiesList, setentitiesList] = useState([]);
  const [modifyEntitiesModal, setModifyEntitiesModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [dates, setDates] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const [entitiesAllList, setEntitiesAllList] = useState([]);
  const [fixedCosAlltList, setfixedAllCostList] = useState([]);
  const [modifyCostModal, setModifyCostModal] = useState(false);
  const [modifyRecurringCostModal, setModifyRecurringCostModal] =
    useState(false);
  const [costsList, setCostsList] = useState([]);
  const [fleetList, setFleetList] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [datesExpire, setDatesExpire] = useState("");
  const [costsRecurringList, setCostsRecurringList] = useState([]);
  const [costFixedCostData, setCostFixedCostData] = useState("");
  const [delCostModal, setDelCostModal] = useState(false);
  const [delRecCostModal, setDelRecCostModal] = useState(false);
  const [costRecCostData, setCostRecCostData] = useState("");
  const [delEntitiesModal, setDelEntitiesModal] = useState(false);
  const [costEntitiesData, setCostEntitiesData] = useState("");

  const onDeleteCostModalClose = () => {
    setDelCostModal(false);
  };

  const onDeleteRecCostModalClose = () => {
    setDelRecCostModal(false);
  };

  const onDeleteEntitiesModalClose = () => {
    setDelEntitiesModal(false);
  };
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const navigate = useNavigate();

  const onDestroydCost = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: costFixedCostData };
    deleteCost(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setCostFixedCostData("");
        setDelCostModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDestroydRecCost = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: costRecCostData };
    deleteRecurringCost(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setCostRecCostData("");
        setDelRecCostModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDestroydEntities = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: costEntitiesData };
    deleteEntities(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setCostEntitiesData("");
        setDelEntitiesModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const hamdleRecurringEdit = (e, item) => {
    e.preventDefault();
    setCostRecurringData({
      uuid: item?.uuid,
      vehicle_id: item?.vehicle_id,
      entity_id: item?.entity_id,
      cost_type_id: item?.cost_type_id,
      individual_cost: item?.individual_cost,
      date: moment(item?.date).format("YYYY-MM-DD"),
      is_expiry: item?.is_expiry,
      expirey_date: moment(item?.expirey_date).format("YYYY-MM-DD"),
    });
    setModifyRecurringCostModal(true);
  };

  const hamdleEntitiesEdit = (e, item) => {
    e.preventDefault();
    setEntitiesData({
      name: item?.entities_name,
      description: item?.description,
      tax_number: item?.tax_number,

      address: item?.address,
      zip_code: item?.zip_code,
      city: item?.city,
      country: item?.country,
      fixed_cost_type_id: item?.fixed_cost_type_id,
      variable_cost_type_id: item?.variable_cost_type_id,
      contact_name: item?.contact_name,
      phone_number: item?.phone_number,
      uuid: item?.uuid,
    });
    setModifyEntitiesModal(true);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const onAddFixedCost = (e) => {
    e.preventDefault();
    setFixedData({
      name: "",
    });
    setModifyModal(true);
  };

  const onModifyCostModalClose = (e) => {
    setCostData({
      vehicle_id: "",
      entity_id: "",
      cost_type_id: "",
      total_cost: "",
      odometer: "",
      location: "",
      document: "",
      country: "",
      cost_description: "",
      notes: "",
      date: "",
    });
    setModifyCostModal(false);
  };

  const onCostFixedCostEdit = (e, item) => {
    e.preventDefault();

    setCostData({
      id: item?.uuid,
      vehicle_id: item?.vehicle_id,
      entity_id: item?.entity_id,
      cost_type_id: item?.cost_type_id,
      total_cost: item?.total_cost,
      odometer: item?.odometer,
      location: item?.location,
      document: item?.document,
      country: item?.country,
      cost_description: item?.cost_description,
      notes: item?.notes,
      date: item?.date,
    });
    setDates(item.date ? new Date(item.date) : null);
    setModifyCostModal(true);
  };

  const onCostFixedCost = (e) => {
    e.preventDefault();
    setCostData({
      vehicle_id: "",
      entity_id: "",
      cost_type_id: "",
      total_cost: "",
      odometer: "",
      location: "",
      document: "",
      country: "",
      cost_description: "",
      notes: "",
      date: "",
    });
    setModifyCostModal(true);
  };

  const onDeleteCost = (e, item) => {
    e.preventDefault();
    setCostFixedCostData(item.uuid);
    setDelCostModal(true);
  };

  const onDeleteRecCost = (e, item) => {
    e.preventDefault();
    setCostRecCostData(item.uuid);
    setDelRecCostModal(true);
  };

  const onDeleteEntities = (e, item) => {
    e.preventDefault();
    setCostEntitiesData(item.uuid);
    setDelEntitiesModal(true);
  };

  const onModifyCostRecurringModalClose = (e) => {
    setModifyRecurringCostModal(false);
  };

  const onCostRecurringOpen = (e) => {
    setModifyRecurringCostModal(true);
  };

  const onModifyModalClose = (e) => {
    setFixedData({
      name: "",
    });
    setModifyModal(false);
  };

  const onAddVariableCost = (e) => {
    e.preventDefault();
    setVariableData({
      name: "",
      unit: "",
    });
    setModifyVariableModal(true);
  };

  const onModifyVariableModalClose = (e) => {
    setVariableData({
      name: "",
      unit: "",
    });
    setModifyVariableModal(false);
  };

  const onAddEntities = (e) => {
    e.preventDefault();
    setEntitiesData({
      name: "",
      description: "",
      tax_number: "",
      address: "",
      zip_code: "",
      city: "",
      country: "",
      fixed_cost_type_id: "",
      variable_cost_type_id: "",
      contact_name: "",
      phone_number: "",
    });
    setModifyEntitiesModal(true);
  };

  const onModifyEntitiesModalClose = (e) => {
    setEntitiesData({
      name: "",
      description: "",
      tax_number: "",
      address: "",
      zip_code: "",
      city: "",
      country: "",
      fixed_cost_type_id: "",
      variable_cost_type_id: "",
      contact_name: "",
      phone_number: "",
    });
    setModifyEntitiesModal(false);
  };

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setEntitiesData((prevData) => ({
      ...prevData,
      fixed_cost_type_id: selectedValues,
    }));
  };

  const handleChangeVariable = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setEntitiesData((prevData) => ({
      ...prevData,
      variable_cost_type_id: selectedValues,
    }));
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setCostRecurringData({
      ...costRecurringData,
      is_expiry: newValue,
    });
  };

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getFleetList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setFleetList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getFixedCostList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setfixedCostList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getCostList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setCostsList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getCostRecurringList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setCostsRecurringList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getVariableCostList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setvariableCostList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getEntitiesList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setentitiesList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(() => {
    let data = params;
    getFixedAllCostList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        list.push({ value: "All", label: "All" });
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setfixedCostAllList(list);
      })
      .catch((err) => {
        console.error("Error fetching vehicle list:", err);
      });
  }, [params]);

  useEffect(() => {
    let data = params;
    getVariableAllCostList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        list.push({ value: "All", label: "All" });
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setvariableCostAllList(list);
      })
      .catch((err) => {
        console.error("Error fetching vehicle list:", err);
      });
  }, [params]);

  useEffect(
    function () {
      let data = params;
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );
  useEffect(
    function () {
      let data = params;
      getEntitiesAllList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].entities_name,
            });
          }

          setEntitiesAllList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getFixedAllCostList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setfixedAllCostList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleChangeDate = (date) => {
    setDates(date);
  };

  const handleChangeDateExpire = (date) => {
    setDatesExpire(date);
  };

  const dateExpire = new Date(datesExpire);
  const years = dateExpire.getFullYear();
  const months = String(dateExpire.getMonth() + 1).padStart(2, "0");
  const days = String(dateExpire.getDate()).padStart(2, "0");
  const formattedDatesExpire = `${years}-${months}-${days}`;

  const date = new Date(dates);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const handleCostForm = (e) => {
    e.preventDefault();
    let dataParams = { ...params };

    let errorsObjCosts = { ...errorsObjCost };
    let error = false;

    if (!costData?.vehicle_id) {
      errorsObjCosts.vehicle_id = "Vehicle is required";
      error = true;
    }

    if (!costData?.entity_id) {
      errorsObjCosts.entity_id = "Entity is required";
      error = true;
    }

    if (!costData?.cost_type_id) {
      errorsObjCosts.cost_type_id = "Cost type is required";
      error = true;
    }

    if (!costData?.total_cost) {
      errorsObjCosts.total_cost = "Total cost is required";
      error = true;
    }
    if (!costData?.odometer) {
      errorsObjCosts.odometer = "Odometer is required";
      error = true;
    }
    if (!costData?.location) {
      errorsObjCosts.location = "Location is required";
      error = true;
    }

    if (!costData?.country) {
      errorsObjCosts.country = "Country is required";
      error = true;
    }

    if (!costData?.cost_description) {
      errorsObjCosts.cost_description = "Cost description is required";
      error = true;
    }

    if (!costData?.notes) {
      errorsObjCosts.notes = "Notes is required";
      error = true;
    }

    if (!dates) {
      errorsObjCosts.dates = "Date is required";
      error = true;
    }

    setErrorCost(errorsObjCosts);
    if (error) return;

    let data = {
      vehicle_id: costData.vehicle_id,
      entity_id: costData.entity_id,
      cost_type_id: costData.cost_type_id,
      total_cost: costData.total_cost,
      odometer: costData.odometer,
      location: costData.location,
      document: costData.document,
      country: costData.country,
      cost_description: costData.cost_description,
      notes: costData.notes,
      date: formattedDate,
    };

    let updateData = {
      uuid: costData.id,
      vehicle_id: costData.vehicle_id,
      entity_id: costData.entity_id,
      cost_type_id: costData.cost_type_id,
      total_cost: costData.total_cost,
      odometer: costData.odometer,
      location: costData.location,
      document: costData.document,
      country: costData.country,
      cost_description: costData.cost_description,
      notes: costData.notes,
      date: formattedDate,
    };
    if (costData?.id) {
      createCost(updateData)
        .then((response) => {
          toast.success(response.data.message);
          setModifyCostModal(false);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    } else {
      createCost(data)
        .then((response) => {
          toast.success(response.data.message);
          setModifyCostModal(false);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    }
  };

  const handleRecurringCostForm = (e) => {
    e.preventDefault();
    let dataParams = { ...params };

    let errorsObjRecs = { ...errorsObjRec };
    let error = false;

    if (!costRecurringData?.vehicle_id) {
      errorsObjRecs.vehicle_id = "Vehicle is required";
      error = true;
    }

    if (!costRecurringData?.entity_id) {
      errorsObjRecs.entity_id = "Entity is required";
      error = true;
    }

    if (!costRecurringData?.cost_type_id) {
      errorsObjRecs.cost_type_id = "Cost type is required";
      error = true;
    }

    if (!costRecurringData?.individual_cost) {
      errorsObjRecs.individual_cost = "Individual cost is required";
      error = true;
    }

    if (!dates) {
      errorsObjRecs.dates = "Date is required";
      error = true;
    }

    setErrorCostRec(errorsObjRecs);
    if (error) return;

    let data = {
      vehicle_id: costRecurringData.vehicle_id,
      entity_id: costRecurringData.entity_id,
      cost_type_id: costRecurringData.cost_type_id,
      individual_cost: costRecurringData.individual_cost,
      is_expiry: costRecurringData?.is_expiry,
      date: formattedDate,
      expirey_date:
        costRecurringData?.is_expiry === 1 ? formattedDatesExpire : "",
    };

    let Updatedata = {
      uuid: costRecurringData?.uuid,
      vehicle_id: costRecurringData.vehicle_id,
      entity_id: costRecurringData.entity_id,
      cost_type_id: costRecurringData.cost_type_id,
      individual_cost: costRecurringData.individual_cost,
      is_expiry: costRecurringData?.is_expiry,
      date: formattedDate,
      expirey_date:
        costRecurringData?.is_expiry === 1 ? formattedDatesExpire : "",
    };
    if (costRecurringData?.uuid) {
      createRecurringCost(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          setModifyRecurringCostModal(false);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    } else {
      createRecurringCost(data)
        .then((response) => {
          toast.success(response.data.message);
          setModifyRecurringCostModal(false);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    }
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;

    if (!fixedData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let data = {
      name: fixedData.name,
    };

    let updateData = {
      name: fixedData.name,

      uuid: fixedData.uuid,
    };

    if (fixedData?.uuid) {
      modifyFixedCost(createFixedCost, updateData);
    } else {
      modifyFixedCost(createFixedCost, data);
    }
  };

  const modifyFixedCost = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setFixedData({
          name: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onDeleteFixedCost = (e, item) => {
    e.preventDefault();
    setDelFixedCost(item.uuid);
    setDelModal(true);
  };

  const onEditFixedCost = (e, item) => {
    e.preventDefault();
    setFixedData({
      uuid: item.uuid,
      name: item.name,
    });
    setModifyModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelFixedCost("");
    setDelModal(false);
  };

  const onDestroyFixedCost = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delFixedCost };
    deleteFixedCost(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelFixedCost("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVariableCost = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObjVar };
    let error = false;

    if (!variableData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    if (!variableData?.unit) {
      errorObj.unit = "Unit is required";
      error = true;
    }

    setErrorsVar(errorObj);
    if (error) return;

    let data = {
      name: variableData.name,
      unit: variableData.unit,
    };

    let updateData = {
      name: variableData.name,
      unit: variableData.unit,
      uuid: variableData.uuid,
    };

    if (variableData?.uuid) {
      modifyVariableCost(createVariableCost, updateData);
    } else {
      modifyVariableCost(createVariableCost, data);
    }
  };

  const modifyVariableCost = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVariableData({
          name: "",
          unit: "",
        });
        setModifyVariableModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const handleEnitiesForm = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObjEnt };
    let error = false;

    if (!entitiesData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    if (!entitiesData?.description) {
      errorObj.description = "Description is required";
      error = true;
    }

    if (!entitiesData?.tax_number) {
      errorObj.tax_number = "Tax number is required";
      error = true;
    }

    if (!entitiesData?.address) {
      errorObj.address = "Address is required";
      error = true;
    }

    if (!entitiesData?.zip_code) {
      errorObj.zip_code = "Zipcode is required";
      error = true;
    }

    if (!entitiesData?.city) {
      errorObj.city = "City is required";
      error = true;
    }

    if (!entitiesData?.country) {
      errorObj.country = "Country is required";
      error = true;
    }

    if (!entitiesData?.fixed_cost_type_id) {
      errorObj.fixed_cost_type_id = "Fixed Cost Type is required";
      error = true;
    }

    if (!entitiesData?.variable_cost_type_id) {
      errorObj.variable_cost_type_id = "Variable Cost Type is required";
      error = true;
    }

    if (!entitiesData?.contact_name) {
      errorObj.contact_name = "Contact name is required";
      error = true;
    }

    if (!entitiesData?.phone_number) {
      errorObj.phone_number = "Phone Number is required";
      error = true;
    }

    setErrorsEnt(errorObj);
    if (error) return;

    let data = {
      name: entitiesData.name,
      description: entitiesData.description,
      tax_number: entitiesData.tax_number,
      address: entitiesData.address,
      zip_code: entitiesData.zip_code,
      city: entitiesData.city,
      country: entitiesData.country,
      fixed_cost_type_id: entitiesData.fixed_cost_type_id,
      variable_cost_type_id: entitiesData.variable_cost_type_id,
      contact_name: entitiesData.contact_name,
      phone_number: entitiesData.phone_number,
    };

    let updateData = {
      uuid: entitiesData.uuid,
      name: entitiesData.name,
      description: entitiesData.description,
      tax_number: entitiesData.tax_number,
      address: entitiesData.address,
      zip_code: entitiesData.zip_code,
      city: entitiesData.city,
      country: entitiesData.country,
      fixed_cost_type_id: entitiesData.fixed_cost_type_id,
      variable_cost_type_id: entitiesData.variable_cost_type_id,
      contact_name: entitiesData.contact_name,
      phone_number: entitiesData.phone_number,
    };
    if (entitiesData.uuid) {
      modifyEnities(createEntities, updateData);
    } else {
      modifyEnities(createEntities, data);
    }
  };

  const modifyEnities = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setEntitiesData({
          name: "",
          description: "",
          tax_number: "",
          address: "",
          zip_code: "",
          city: "",
          country: "",
          fixed_cost_type_id: "",
          variable_cost_type_id: "",
          contact_name: "",
          phone_number: "",
        });
        setModifyEntitiesModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onDeleteVariableCost = (e, item) => {
    e.preventDefault();
    setDelVariableCost(item.uuid);
    setVariableDelModal(true);
  };

  const onEditVariableCost = (e, item) => {
    e.preventDefault();
    setVariableData({
      uuid: item.uuid,
      name: item.name,
      unit: item.unit,
    });
    setModifyVariableModal(true);
  };

  const onDeleteVariableModalClose = (e) => {
    e.preventDefault();
    setDelVariableCost("");
    setVariableDelModal(false);
  };

  const onDestroyVariableCost = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delVariableCost };
    deleteVariableCost(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelVariableCost("");
        setVariableDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5>Management</h5>
        </div>
      </div>
      <div className="leaflet-control-management managementui-tab">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${activeTab === "energy" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("energy");
                }}
              >
                <span data-href="#tab-1">Energy</span>
              </li>

              <li
                className={`nav-link ${activeTab === "fleet" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("fleet");
                }}
              >
                <span data-href="#tab-2">Fleet</span>
              </li>
              <li
                className={`nav-link ${activeTab === "costs" ? "active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("costs");
                }}
              >
                <span data-href="#tab-3">Costs</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "recurring Costs" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("recurring Costs");
                }}
              >
                <span data-href="#tab-3">Recurring Costs</span>
              </li>
              <li
                className={`nav-link ${
                  activeTab === "entities" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("entities");
                }}
              >
                <span data-href="#tab-4">Entities</span>
              </li>
              <li
                className={`nav-link ${
                  activeTab === "preferences" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("preferences");
                }}
              >
                <span data-href="#tab-5">Preferences</span>
              </li>
            </ul>
          </nav>
          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "energy" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <div className="inner-header py-3 d-none">
                <div className="left-block">
                  <h5>Energy</h5>
                </div>
              </div>

              <div className="container-event">
                <div className="page-header-row">
                  <div className="row">
                    <div className="col">
                      <div className="row row-cols-1 row-cols-lg-5 g-2 g-lg-3 company-section company-section-Area">
                        <div className="col">
                          <div className="selected-block">
                            <p>Show</p>
                            <select
                              className="form-select"
                              aria-label="Events Only"
                              defaultValue={params.limit}
                              onChange={(e) => handleParams(e, "length")}
                            >
                              {tableLengthList &&
                                tableLengthList.map((item, i) => (
                                  <option value={item} key={i}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="selected-block selected-mobile">
                            <p>Search</p>
                            <div className="search-bar">
                              <i className="bi bi-search"></i>
                              <input
                                type="text"
                                className="form-control"
                                value={params.search}
                                onChange={(e) => handleParams(e, "search")}
                                placeholder="Search"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="page-table-row">
                  <table
                    className={`table table-striped ${
                      isLoading ? "loading" : ""
                    }`}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th scope={`col`}>{`Vehicle`}</th>
                        <th scope={`col`}>{`Mileage`}</th>
                        <th scope={`col`}>{`Driving Time`}</th>
                        <th scope={`col`}>{`Initial Fuel`}</th>
                        <th scope={`col`}>{`Manual Records`}</th>
                        <th scope={`col`}>{`Ditection`}</th>
                        <th scope={`col`}>{`Drainage`}</th>
                        <th scope={`col`}>{`Measured Consumption`}</th>
                        <th scope={`col`}>{`Average Consumption`}</th>
                        <th scope={`col`}>{`Average Consumption per hours`}</th>
                        <th scope={`col`}>{`Fuel Consumption idling`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {fleetList &&
                            fleetList?.data?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.vehicle_name}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.odometer}Km
                                  </td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                  <td style={{ cursor: "pointer" }}>-</td>
                                </tr>
                              );
                            })}
                        </>
                      )}

                      {fleetList && fleetList?.data?.length === 0 && (
                        <tr>
                          <td
                            colSpan={6}
                            className={`text-center`}
                          >{`Record Not Found`}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={`float-right`}>
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "fleet" ? "show active" : ""
              }`}
              id="tab-2"
            >
              <div className="inner-header py-3 d-none">
                <div className="left-block">
                  <h5>Fleet</h5>
                </div>
              </div>

              <div className="container-event">
                <div className="page-header-row">
                  <div className="row">
                    <div className="col">
                      <div className="row row-cols-1 row-cols-lg-5 g-2 g-lg-3 company-section company-section-Area">
                        <div className="col">
                          <div className="selected-block">
                            <p>Show</p>
                            <select
                              className="form-select"
                              aria-label="Events Only"
                              defaultValue={params.limit}
                              onChange={(e) => handleParams(e, "length")}
                            >
                              {tableLengthList &&
                                tableLengthList.map((item, i) => (
                                  <option value={item} key={i}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col">
                          <div className="search-bar selected-block selected-mobile">
                            <p>Search</p>
                            <div className="search-bar">
                              <i className="bi bi-search"></i>
                              <input
                                type="text"
                                className="form-control"
                                value={params.search}
                                onChange={(e) => handleParams(e, "search")}
                                placeholder="Search"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="page-table-row">
                  <table
                    className={`table table-striped ${
                      isLoading ? "loading" : ""
                    }`}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th scope={`col`}>{`License Plate`}</th>
                        <th scope={`col`}>{`Total Cost`}</th>
                        <th scope={`col`}>{`Total Mileage`}</th>
                        <th scope={`col`}>{`Total Cost/KM`}</th>
                        <th scope={`col`}>{`Used Cost/KM`}</th>
                        <th scope={`col`}>{`Difference`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {fleetList &&
                            fleetList?.data?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.vehicle_name}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.total_cost}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.odometer}Km
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.price_per_distance}{" "}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.price_per_distance / 2}{" "}
                                  </td>

                                  <td style={{ cursor: "pointer" }}>
                                    {item.price_per_distance -
                                      item.price_per_distance / 2 || "-"}{" "}
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}

                      {fleetList && fleetList?.data?.length === 0 && (
                        <tr>
                          <td
                            colSpan={6}
                            className={`text-center`}
                          >{`Record Not Found`}</td>
                        </tr>
                      )}

                      {/* <div className="row">
                              <div className="col">
                                <div
                                  className="col-md-6"
                                  style={{ display: "flex", justifyContent: "end" }}
                                >
                                  <div></div>
                                </div>
                                <div
                                  className="col-md-6"
                                  style={{ display: "flex", justifyContent: "end" }}
                                >
                                  <div style={{marginRight:"-49px"}}>sdd</div>
                                </div>
                              </div>
                            </div> */}
                    </tbody>
                  </table>
                </div>
                <div className={`float-right`}>
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "costs" ? "show active" : ""
              }`}
              id="tab-3"
            >
              <div className="inner-header py-3 ">
                <div className="left-block w-auto">
                  <Button
                    type={`button`}
                    onClick={onCostFixedCost}
                    className={`add-btn btn  float-left`}
                  >
                    {`Add Cost`}
                    <i className="plus-circle">
                      <img src={plusIcon} alt="" className="plus-icons" />
                    </i>
                  </Button>
                </div>
              </div>

              <div className="container-event">
                <div className="page-header-row">
                  <div className="row">
                    <div className="col">
                      <div className="row d-flex justify-content-between company-section company-section-Area">
                        <div className="col-xl-2">
                          <div className="selected-block">
                            <p>Show</p>
                            <select
                              className="form-select"
                              aria-label="Events Only"
                              defaultValue={params.limit}
                              onChange={(e) => handleParams(e, "length")}
                            >
                              {tableLengthList &&
                                tableLengthList.map((item, i) => (
                                  <option value={item} key={i}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-xl-2">
                          <div
                            className="selected-block selected-mobile"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <div className="search-bar">
                              <i className="bi bi-search"></i>
                              <input
                                type="text"
                                className="form-control"
                                value={params.search}
                                onChange={(e) => handleParams(e, "search")}
                                placeholder="Search"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="page-table-row">
                  <table
                    className={`table table-striped ${
                      isLoading ? "loading" : ""
                    }`}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th scope={`col`}>{`Date`}</th>
                        <th scope={`col`}>{`Vehicle`}</th>
                        <th scope={`col`}>{`Cost Type`}</th>
                        <th scope={`col`}>{`Entity`}</th>
                        <th scope={`col`}>{`Total Cost`}</th>
                        {/* <th scope={`col`}>{`Unit`}</th> */}
                        <th scope={`col`}>{`Cost Description`}</th>
                        <th scope={`col`}>{`Odometer`}</th>
                        <th scope={`col`}>{`Action`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {costsList &&
                            costsList?.data?.map((item, i) => {
                              const formattedDate = moment(item.date).format(
                                "DD-MM-YYYY"
                              );
                              return (
                                <tr key={i}>
                                  <td style={{ cursor: "pointer" }}>
                                    {formattedDate}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.vehicle_name}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.cost_type_name}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.entities_name}{" "}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.total_cost}{" "}
                                  </td>

                                  <td style={{ cursor: "pointer" }}>
                                    {item.cost_description || "-"}{" "}
                                  </td>

                                  <td style={{ cursor: "pointer" }}>
                                    {item.odometer}Km
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        onCostFixedCostEdit(e, item)
                                      }
                                      className="btn btn-info add-btn-circle me-2 editBtn"
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>

                                    <button
                                      type={`button`}
                                      onClick={(e) => onDeleteCost(e, item)}
                                      className="btn btn-danger add-btn-circle btn-circle me-2"
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}

                      {costsList && costsList?.data?.length === 0 && (
                        <tr>
                          <td
                            colSpan={6}
                            className={`text-center`}
                          >{`Record Not Found`}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={`float-right`}>
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>

              {/* <AddCost/> */}
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "recurring Costs" ? "show active" : ""
              }`}
              id="tab-3"
            >
              <div className="inner-header py-3 ">
                <div className="left-block w-auto">
                  <Button
                    type={`button`}
                    onClick={onCostRecurringOpen}
                    className={`add-btn btn  float-left`}
                  >
                    {`Recurring Cost`}
                    <i className="plus-circle">
                      <img src={plusIcon} alt="" className="plus-icons" />
                    </i>
                  </Button>
                </div>
              </div>

              <div className="container-event">
                <div className="page-header-row">
                  <div className="row">
                    <div className="col">
                      <div className="row d-flex justify-content-between company-section company-section-Area">
                        <div className="col-xl-2">
                          <div className="selected-block">
                            <p>Show</p>
                            <select
                              className="form-select"
                              aria-label="Events Only"
                              defaultValue={params.limit}
                              onChange={(e) => handleParams(e, "length")}
                            >
                              {tableLengthList &&
                                tableLengthList.map((item, i) => (
                                  <option value={item} key={i}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="col-xl-2">
                          <div
                            className="selected-block selected-mobile"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <div className="search-bar">
                              <i className="bi bi-search"></i>
                              <input
                                type="text"
                                className="form-control"
                                value={params.search}
                                onChange={(e) => handleParams(e, "search")}
                                placeholder="Search"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="page-table-row">
                  <table
                    className={`table table-striped ${
                      isLoading ? "loading" : ""
                    }`}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th scope={`col`}>{`Date`}</th>
                        <th scope={`col`}>{`Vehicle`}</th>
                        <th scope={`col`}>{`Cost Type`}</th>
                        <th scope={`col`}>{`Entity`}</th>
                        <th scope={`col`}>{`Individual Cost`}</th>
                        <th scope={`col`}>{`Expiry Date`}</th>

                        <th scope={`col`}>{`Action`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {costsRecurringList &&
                            costsRecurringList?.data?.map((item, i) => {
                              const formattedDate = moment(item.date).format(
                                "DD-MM-YYYY"
                              );

                              const formattedExpiryDate = item.expirey_date
                                ? moment(item.expirey_date).format("DD-MM-YYYY")
                                : "-";

                              return (
                                <tr key={i}>
                                  <td style={{ cursor: "pointer" }}>
                                    {formattedDate}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.vehicle_name}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.cost_type_name}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.entities_name}{" "}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.individual_cost} €
                                  </td>

                                  <td style={{ cursor: "pointer" }}>
                                    {formattedExpiryDate}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        hamdleRecurringEdit(e, item)
                                      }
                                      className="btn btn-info add-btn-circle me-2 editBtn"
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>

                                    <button
                                      type={`button`}
                                      onClick={(e) => onDeleteRecCost(e, item)}
                                      className="btn btn-danger add-btn-circle btn-circle me-2"
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}

                      {costsList && costsList?.data?.length === 0 && (
                        <tr>
                          <td
                            colSpan={6}
                            className={`text-center`}
                          >{`Record Not Found`}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={`float-right`}>
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>

              {/* <AddCost/> */}
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "entities" ? "show active" : ""
              }`}
              id="tab-4"
            >
              <div className="inner-header py-3">
                <div className="left-block w-auto">
                  <Button
                    type={`button`}
                    onClick={onAddEntities}
                    className={`add-btn btn  float-left`}
                  >
                    {`Add Entities`}
                    <i className="plus-circle">
                      <img src={plusIcon} alt="" className="plus-icons" />
                    </i>
                  </Button>
                </div>
              </div>

              <div className="container-event">
                <div className="page-header-row">
                  <div className="row">
                    <div className="col">
                      <div className="row d-flex justify-content-between company-section company-section-Area">
                        <div className="col-xl-2">
                          <div className="selected-block">
                            <p>Show</p>
                            <select
                              className="form-select"
                              aria-label="Events Only"
                              defaultValue={params.limit}
                              onChange={(e) => handleParams(e, "length")}
                            >
                              {tableLengthList &&
                                tableLengthList.map((item, i) => (
                                  <option value={item} key={i}>
                                    {item}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-xl-2">
                          <div className="search-bar">
                            <i className="bi bi-search"></i>
                            <input
                              type="text"
                              className="form-control"
                              value={params.search}
                              onChange={(e) => handleParams(e, "search")}
                              placeholder="Search"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="page-table-row">
                  <table
                    className={`table table-striped ${
                      isLoading ? "loading" : ""
                    }`}
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th scope={`col`}>{`Name`}</th>
                        <th scope={`col`}>{`Description`}</th>
                        <th scope={`col`}>{`Tax Number`}</th>
                        <th scope={`col`}>{`Cost Type`}</th>
                        <th scope={`col`}>{`Action`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {entitiesList &&
                            entitiesList?.data?.map((item, i) => (
                              <tr key={i}>
                                <td style={{ cursor: "pointer" }}>
                                  {item.entities_name}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  {item.description}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  {item.tax_number}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  {item.fixed_cost_name} ,
                                  {item.variable_cost_name}
                                </td>
                                <td style={{ cursor: "pointer" }}>
                                  <button
                                    type="button"
                                    onClick={(e) => hamdleEntitiesEdit(e, item)}
                                    className="btn btn-info add-btn-circle me-2 editBtn"
                                  >
                                    <i className="bi bi-pencil-fill"></i>
                                  </button>

                                  <button
                                    type={`button`}
                                    onClick={(e) => onDeleteEntities(e, item)}
                                    className="btn btn-danger add-btn-circle btn-circle me-2"
                                  >
                                    <i className="bi bi-trash-fill"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}

                      {entitiesList && entitiesList?.data?.length === 0 && (
                        <tr>
                          <td
                            colSpan={6}
                            className={`text-center`}
                          >{`Record Not Found`}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={`float-right`}>
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "preferences" ? "show active" : ""
              }`}
              id="tab-4"
            >
              <nav className="tab-nav">
                <ul className="sub-tabs tabs-vehicle">
                  {" "}
                  {/* Sub-tabs container */}
                  <li
                    className={`sub-tab ${
                      activeSubTab === "Fixed cost" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubTabClick("Fixed cost");
                    }}
                  >
                    <span data-href="#sub-tab-1">Fixed cost</span>
                  </li>
                  <li
                    className={`sub-tab ${
                      activeSubTab === "Variable cost" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubTabClick("Variable cost");
                    }}
                  >
                    <span data-href="#sub-tab-2">Variable cost</span>
                  </li>
                </ul>
              </nav>
              <div className="dashboard-block tab-content" id="nav-tabContent">
                <div
                  className={`tab-pane fade ${
                    activeSubTab === "Fixed cost" ? "show active" : ""
                  }`}
                  id="sub-tab-1"
                >
                  <div className="page-header-row">
                    <div className="row">
                      <div
                        className="col mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginRight: "12px",
                        }}
                      >
                        <Button
                          type={`button`}
                          onClick={onAddFixedCost}
                          className={`add-btn btn  float-right`}
                        >
                          {`Add`}
                          <i className="plus-circle">
                            <img src={plusIcon} alt="" className="plus-icons" />
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="page-table-row page-tableactiviti">
                    <table className={`table table-striped`} width="100%">
                      <thead>
                        <tr>
                          <th scope={`col`}>{`Designation`}</th>
                          <th scope={`col`}>{`Action`}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {fixedCostList &&
                              fixedCostList?.data?.map((item, i) => (
                                <tr key={i}>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.name}
                                  </td>

                                  <td>
                                    <button
                                      onClick={(e) => onEditFixedCost(e, item)}
                                      className={`btn btn-info add-btn-circle me-2`}
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>

                                    <button
                                      type={`button`}
                                      onClick={(e) =>
                                        onDeleteFixedCost(e, item)
                                      }
                                      className="btn btn-danger add-btn-circle btn-circle me-2"
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        )}

                        {fixedCostList && fixedCostList?.data?.length === 0 && (
                          <tr>
                            <td
                              colSpan={6}
                              className={`text-center`}
                            >{`Record Not Found`}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className={`float-right`}>
                    <Pagination
                      activePage={params.page}
                      itemsCountPerPage={parseInt(params.limit)}
                      totalItemsCount={parseInt(totalRecords)}
                      pageRangeDisplayed={5}
                      itemClass={`page-item`}
                      linkClass={`page-link`}
                      onChange={(e) => handleParams(e, "pagination")}
                    />
                  </div>
                </div>

                <div
                  className={`tab-pane fade ${
                    activeSubTab === "Variable cost" ? "show active" : ""
                  }`}
                  id="sub-tab-2"
                >
                  <div className="page-header-row">
                    <div className="row">
                      <div
                        className="col mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginRight: "12px",
                        }}
                      >
                        <Button
                          type={`button`}
                          onClick={onAddVariableCost}
                          className={`add-btn btn  float-right`}
                        >
                          {`Add`}
                          <i className="plus-circle">
                            <img src={plusIcon} alt="" className="plus-icons" />
                          </i>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="page-table-row page-tableactiviti">
                    <table className={`table table-striped`} width="100%">
                      <thead>
                        <tr>
                          <th scope={`col`}>{`Designation`}</th>
                          <th scope={`col`}>{`Unit`}</th>

                          <th scope={`col`}>{`Action`}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {variableCostList &&
                              variableCostList?.data?.map((item, i) => (
                                <tr key={i}>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.name}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {item.unit}
                                  </td>

                                  <td>
                                    <button
                                      onClick={(e) =>
                                        onEditVariableCost(e, item)
                                      }
                                      className={`btn btn-info add-btn-circle me-2`}
                                    >
                                      <i className="bi bi-pencil-fill"></i>
                                    </button>

                                    <button
                                      type={`button`}
                                      onClick={(e) =>
                                        onDeleteVariableCost(e, item)
                                      }
                                      className="btn btn-danger add-btn-circle btn-circle me-2"
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </>
                        )}

                        {variableCostList &&
                          variableCostList?.data?.length === 0 && (
                            <tr>
                              <td
                                colSpan={6}
                                className={`text-center`}
                              >{`Record Not Found`}</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                  <div className={`float-right`}>
                    <Pagination
                      activePage={params.page}
                      itemsCountPerPage={parseInt(params.limit)}
                      totalItemsCount={parseInt(totalRecords)}
                      pageRangeDisplayed={5}
                      itemClass={`page-item`}
                      linkClass={`page-link`}
                      onChange={(e) => handleParams(e, "pagination")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {fixedData.uuid ? `Edit Fixed Cost` : `Create Fixed Cost`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Designation"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={fixedData.name}
                  onChange={(e) => {
                    setFixedData({
                      ...fixedData,
                      name: e.target.value,
                    });
                  }}
                  name={`name`}
                  maxLength={30}
                  placeholder="Designation"
                />
                {errors.name && (
                  <span className="input-error">{errors.name}</span>
                )}
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Fixed Cost`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this Fixed Cost?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary bg-danger border-0"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyFixedCost}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyVariableModal}>
        <ModalHeader>
          {variableData.uuid ? `Edit Variable Cost` : `Create Variable Cost`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleVariableCost}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Designation"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={variableData.name}
                  onChange={(e) => {
                    setVariableData({
                      ...variableData,
                      name: e.target.value,
                    });
                  }}
                  name={`name`}
                  maxLength={30}
                  placeholder="Designation"
                />

                {errorsVar.name && (
                  <span className="input-error">{errorsVar.name}</span>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">
                {"Unit"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={variableData.unit}
                  onChange={(e) => {
                    setVariableData({
                      ...variableData,
                      unit: e.target.value,
                    });
                  }}
                  name={`unit`}
                  maxLength={30}
                  placeholder="Unit"
                />
                {errorsVar.unit && (
                  <span className="input-error">{errorsVar.unit}</span>
                )}
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyVariableModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delVariableModal}>
        <ModalHeader>{`Delete Variable Cost`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this Variable Cost?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteVariableModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square bg-danger border-0`}
              onClick={onDestroyVariableCost}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyEntitiesModal} size="lg">
        <ModalHeader>Create Entities</ModalHeader>
        <Form method={`post`} onSubmit={handleEnitiesForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Entities Name"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.name}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          name: e.target.value,
                        });
                      }}
                      name={`name`}
                      maxLength={30}
                      placeholder="Entities Name"
                    />
                    {errorsEnt.name && (
                      <span className="input-error">{errorsEnt.name}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Description"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.description}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          description: e.target.value,
                        });
                      }}
                      name={`description`}
                      maxLength={30}
                      placeholder="Description"
                    />
                    {errorsEnt.description && (
                      <span className="input-error">
                        {errorsEnt.description}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Tax number"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.tax_number}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          tax_number: e.target.value,
                        });
                      }}
                      name={`tax_number`}
                      maxLength={30}
                      placeholder="Tax number"
                    />
                    {errorsEnt.tax_number && (
                      <span className="input-error">
                        {errorsEnt.tax_number}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Address"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.address}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          address: e.target.value,
                        });
                      }}
                      name={`address`}
                      maxLength={30}
                      placeholder="Address"
                    />
                    {errorsEnt.address && (
                      <span className="input-error">{errorsEnt.address}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Zip Code"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.zip_code}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          zip_code: e.target.value,
                        });
                      }}
                      name={`zip_code`}
                      maxLength={30}
                      placeholder="Zip Code"
                    />
                    {errorsEnt.zip_code && (
                      <span className="input-error">{errorsEnt.zip_code}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"City"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.city}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          city: e.target.value,
                        });
                      }}
                      name={`City`}
                      maxLength={30}
                      placeholder="city"
                    />
                    {errorsEnt.city && (
                      <span className="input-error">{errorsEnt.city}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Country"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.country}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          country: e.target.value,
                        });
                      }}
                      name={`Country`}
                      maxLength={30}
                      placeholder="country"
                    />
                    {errorsEnt.country && (
                      <span className="input-error">{errorsEnt.country}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Fixed Cost Type"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <Select
                      options={fixedCostAllList}
                      isMulti
                      value={fixedCostAllList.filter(
                        (option) =>
                          Array.isArray(entitiesData.fixed_cost_type_id) &&
                          entitiesData.fixed_cost_type_id.includes(option.value)
                      )}
                      onChange={handleChange}
                      placeholder={"Select Fixed Cost Type"}
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                    {errorsEnt.fixed_cost_type_id && (
                      <span className="input-error">
                        {errorsEnt.fixed_cost_type_id}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Variable Cost Type"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <Select
                      options={variableCostAllList}
                      isMulti
                      value={variableCostAllList.filter(
                        (option) =>
                          Array.isArray(entitiesData.variable_cost_type_id) &&
                          entitiesData.variable_cost_type_id.includes(
                            option.value
                          )
                      )}
                      onChange={handleChangeVariable}
                      placeholder={"Select Variable Cost Type"}
                      classNamePrefix="select"
                      closeMenuOnSelect={false}
                    />
                    {errorsEnt.variable_cost_type_id && (
                      <span className="input-error">
                        {errorsEnt.variable_cost_type_id}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Contact Name"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.contact_name}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          contact_name: e.target.value,
                        });
                      }}
                      name={`Contact Name`}
                      maxLength={30}
                      placeholder="Contact Name"
                    />
                    {errorsEnt.contact_name && (
                      <span className="input-error">
                        {errorsEnt.contact_name}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Phone Number"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={entitiesData.phone_number}
                      onChange={(e) => {
                        setEntitiesData({
                          ...entitiesData,
                          phone_number: e.target.value,
                        });
                      }}
                      name={`Phone Number`}
                      maxLength={30}
                      placeholder="Phone Number"
                    />

                    {errorsEnt.phone_number && (
                      <span className="input-error">
                        {errorsEnt.phone_number}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyEntitiesModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyCostModal} size="lg">
        <ModalHeader>Create Cost</ModalHeader>
        <Form method={`post`} onSubmit={handleCostForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Date"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <DatePicker
                    selected={dates}
                    onChange={handleChangeDate}
                    dateFormat="yyyy-MM-dd"
                    calendarPosition="bottom-center"
                    placeholder="Select Date"
                    style={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      height: "40px",
                      margin: "1px 0",
                      padding: "2px 5px",
                      width: "217px",
                      paddingRight: "30px",
                    }}
                  />
                  {errorCost.dates && (
                    <span className="input-error">{errorCost.dates}</span>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Vehicle"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <Select
                    options={vehicleList}
                    value={vehicleList?.find(
                      (option) => option.value === costData.vehicle_id
                    )}
                    onChange={(selectedOption) =>
                      setCostData((prevData) => ({
                        ...prevData,
                        vehicle_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Vehicle"}
                  />
                  {errorCost.vehicle_id && (
                    <span className="input-error">{errorCost.vehicle_id}</span>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Entities"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <Select
                    options={entitiesAllList}
                    value={entitiesAllList?.find(
                      (option) => option.value === costData.entity_id
                    )}
                    onChange={(selectedOption) =>
                      setCostData((prevData) => ({
                        ...prevData,
                        entity_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Entities"}
                  />
                  {errorCost.entity_id && (
                    <span className="input-error">{errorCost.entity_id}</span>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Cost Type"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <Select
                    options={fixedCosAlltList}
                    value={fixedCosAlltList?.find(
                      (option) => option.value === costData.cost_type_id
                    )}
                    onChange={(selectedOption) =>
                      setCostData((prevData) => ({
                        ...prevData,
                        cost_type_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Cost Type"}
                  />

                  {errorCost.cost_type_id && (
                    <span className="input-error">
                      {errorCost.cost_type_id}
                    </span>
                  )}
                </FormGroup>
              </div>
              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Total Cost"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costData.total_cost}
                      onChange={(e) => {
                        setCostData({
                          ...costData,
                          total_cost: e.target.value,
                        });
                      }}
                      name={`total_cost`}
                      maxLength={30}
                      placeholder="Total Cost"
                    />

                    {errorCost.total_cost && (
                      <span className="input-error">
                        {errorCost.total_cost}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Odometer"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costData.odometer}
                      onChange={(e) => {
                        setCostData({
                          ...costData,
                          odometer: e.target.value,
                        });
                      }}
                      name={`odometer`}
                      maxLength={30}
                      placeholder="Odometer"
                    />
                    {errorCost.odometer && (
                      <span className="input-error">{errorCost.odometer}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Cost description"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costData.cost_description}
                      onChange={(e) => {
                        setCostData({
                          ...costData,
                          cost_description: e.target.value,
                        });
                      }}
                      name={`cost_description`}
                      maxLength={30}
                      placeholder="Cost description"
                    />

                    {errorCost.cost_description && (
                      <span className="input-error">
                        {errorCost.cost_description}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Document"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costData.document}
                      onChange={(e) => {
                        setCostData({
                          ...costData,
                          document: e.target.value,
                        });
                      }}
                      name={`document`}
                      maxLength={30}
                      placeholder="Document"
                    />
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Location"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costData.location}
                      onChange={(e) => {
                        setCostData({
                          ...costData,
                          location: e.target.value,
                        });
                      }}
                      name={`location`}
                      maxLength={30}
                      placeholder="Location"
                    />
                    {errorCost.location && (
                      <span className="input-error">{errorCost.location}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Country"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costData.country}
                      onChange={(e) => {
                        setCostData({
                          ...costData,
                          country: e.target.value,
                        });
                      }}
                      name={`country`}
                      maxLength={30}
                      placeholder="Country"
                    />

                    {errorCost.country && (
                      <span className="input-error">{errorCost.country}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-4">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Notes"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costData.notes}
                      onChange={(e) => {
                        setCostData({
                          ...costData,
                          notes: e.target.value,
                        });
                      }}
                      name={`notes`}
                      maxLength={30}
                      placeholder="Notes"
                    />
                    {errorCost.notes && (
                      <span className="input-error">{errorCost.notes}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyCostModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyRecurringCostModal}>
        <ModalHeader>Create Recurring Cost</ModalHeader>
        <Form method={`post`} onSubmit={handleRecurringCostForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">
                    {"Vehicle"}
                    <span className={`text-danger ml-1`}>*</span>
                  </Label>
                  <Select
                    options={vehicleList}
                    value={vehicleList?.find(
                      (option) => option.value === costRecurringData?.vehicle_id
                    )}
                    onChange={(selectedOption) =>
                      setCostRecurringData((prevData) => ({
                        ...prevData,
                        vehicle_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Vehicle"}
                  />
                  {errorCostRec.vehicle_id && (
                    <span className="input-error">
                      {errorCostRec.vehicle_id}
                    </span>
                  )}
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">{"Entities"}</Label>
                  <Select
                    options={entitiesAllList}
                    value={entitiesAllList?.find(
                      (option) => option.value === costRecurringData?.entity_id
                    )}
                    onChange={(selectedOption) =>
                      setCostRecurringData((prevData) => ({
                        ...prevData,
                        entity_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Entities"}
                  />
                  {errorCostRec.entity_id && (
                    <span className="input-error">
                      {errorCostRec.entity_id}
                    </span>
                  )}
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">{"Cost Type"}</Label>
                  <Select
                    options={fixedCosAlltList}
                    value={fixedCosAlltList?.find(
                      (option) =>
                        option.value === costRecurringData?.cost_type_id
                    )}
                    onChange={(selectedOption) =>
                      setCostRecurringData((prevData) => ({
                        ...prevData,
                        cost_type_id: selectedOption.value,
                      }))
                    }
                    placeholder={"Select Cost Type"}
                  />

                  {errorCostRec.cost_type_id && (
                    <span className="input-error">
                      {errorCostRec.cost_type_id}
                    </span>
                  )}
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">{"Individual Cost"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={costRecurringData.individual_cost}
                      onChange={(e) => {
                        setCostRecurringData({
                          ...costRecurringData,
                          individual_cost: e.target.value,
                        });
                      }}
                      name={`individual_cost`}
                      maxLength={30}
                      placeholder="Individual Cost"
                    />
                    {errorCostRec.individual_cost && (
                      <span className="input-error">
                        {errorCostRec.individual_cost}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">{"Next Date"}</Label>
                  <DatePicker
                    selected={dates}
                    onChange={handleChangeDate}
                    dateFormat="yyyy-MM-dd"
                    calendarPosition="bottom-center"
                    placeholder="Select Date"
                    style={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      height: "40px",
                      margin: "1px 0",
                      padding: "2px 5px",
                      width: "217px",
                      paddingRight: "30px",
                    }}
                  />
                  {errorCostRec.dates && (
                    <span className="input-error">{errorCostRec.dates}</span>
                  )}
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label">Expires</Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="is_expiry"
                        checked={costRecurringData.is_expiry === 1}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="is_expiry">
                        Expires
                      </label>
                    </div>
                  </div>
                </FormGroup>
              </div>

              {costRecurringData?.is_expiry === 1 && (
                <FormGroup>
                  <Label className="col-form-label">{"Expiration Date"}</Label>
                  <DatePicker
                    selected={datesExpire}
                    onChange={handleChangeDateExpire}
                    dateFormat="yyyy-MM-dd"
                    calendarPosition="bottom-center"
                    placeholder="Select Date"
                    style={{
                      border: "1px solid #CCCCCC",
                      borderRadius: "5px",
                      height: "40px",
                      margin: "1px 0",
                      padding: "2px 5px",
                      width: "455px",
                      paddingRight: "30px",
                    }}
                  />
                </FormGroup>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyCostRecurringModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delCostModal}>
        <ModalHeader>{`Delete Cost`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this  Cost?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteCostModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary bg-danger border-0"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroydCost}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={delRecCostModal}>
        <ModalHeader>{`Delete Recurring Cost`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this Recurring Cost?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteRecCostModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary bg-danger border-0"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroydRecCost}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={delEntitiesModal}>
        <ModalHeader>{`Delete Entities Cost`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this Entities Cost?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteEntitiesModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary bg-danger border-0"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroydEntities}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Management;
