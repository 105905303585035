import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  actionDelVehicleClass,
  actionUpdateVehicleClass,
  getVehicleClassList,
} from "../../services/VehicleClassService";
import uploadIcon from "../../assets/images/upload.svg";

const tableLengthList = configDb.data.dataTableLength;

function VehicleClass(props) {
  const auth = useSelector((x) => x.auth.value);
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [vehicleClassList, setVehicleClassList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delVehicleClass, setDelVehicleClass] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [vehicleClassData, setVehicleClassData] = useState({
    uuid: "",
    name: "",
    isCommon: false,
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { name: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [logo, setLogo] = useState(null);
  const [PreviewUrl, setPreviewUrl] = useState(null);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });
  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getVehicleClassList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setVehicleClassList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [params]
  );
  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };
  const onDeleteVehicleClass = (e, item) => {
    e.preventDefault();
    setDelVehicleClass(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelVehicleClass("");
    setDelModal(false);
  };

  const onDestroyVehicleClass = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delVehicleClass };
    actionDelVehicleClass(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelVehicleClass("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const onAddVehicleClass = (e) => {
    e.preventDefault();
    setVehicleClassData({ uuid: "", name: "" });
    setModifyModal(true);
  };
  const onEditVehicleClass = (e, item) => {
    e.preventDefault();
    setVehicleClassData({ uuid: item.uuid, name: item.className });
    setPreviewUrl(item.vehicle_image);
    setLogo(item.vehicle_image);
    setModifyModal(true);
  };
  const onModifyModalClose = (e) => {
    setVehicleClassData({ uuid: "", name: "" });
    setModifyModal(false);
    setErrors(errorsObj);
  };
  const handleVehicleClassName = (e) => {
    let data = { ...vehicleClassData };
    data.name = e.target.value;
    setVehicleClassData(data);
  };
  const handleVehicleClassForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleClassData.name) {
      errorObj.name = `Required vehicle class name`;
      error = true;
    }
    setErrors(errorObj);

    if (error) return;

    let dataParams = { ...params };

    setIsModifyButtonLoad(true);

    const formData = new FormData();
    formData.append("className", vehicleClassData.name);
    formData.append("vehicle_image", logo);

    if (vehicleClassData.uuid) {
      formData.append("uuid", vehicleClassData.uuid);
    }

    actionUpdateVehicleClass(formData)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVehicleClassData({ uuid: "", name: "", isCommon: false });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setLogo(selectedFile);
    if (selectedFile) {
      setSelectedFileName(selectedFile);
      const imageUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(imageUrl);
    } else {
      setSelectedFileName("");
    }
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Vehicle Class List</h5>
        </div>

        <div className="right-block w-auto">
          {allPermissionsList.findIndex(
            (e) => e.name == "modify_vehicle_class"
          ) > -1 && (
            <Button
              type={`button`}
              onClick={onAddVehicleClass}
              className={`add-btn btn float-right`}
            >
              {`Add Vehicle Class`}
              <i className="plus-circle">
                <img src={plusIcon} alt="" className="plus-icons" />
              </i>
            </Button>
          )}
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between company-section">
                <div className="col-xl-2">
                  <div className="selected-block">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Vehicle Class`}</th>

                {(allPermissionsList.findIndex(
                  (e) => e.name == "modify_vehicle_class"
                ) > -1 ||
                  allPermissionsList.findIndex(
                    (e) => e.name == "delete_vehicle_class"
                  ) > -1) && <th scope={`col`} width="20%">{`Action`}</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {vehicleClassList &&
                    vehicleClassList?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.className}</td>

                        <td>
                          <Button
                            type={`button`}
                            onClick={(e) => onEditVehicleClass(e, item)}
                            className={`btn btn-info add-btn-circle me-2`}
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </Button>

                          <Button
                            type={`button`}
                            onClick={(e) => onDeleteVehicleClass(e, item)}
                            className={`btn btn-danger add-btn-circle btn-square me-2`}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                </>
              )}

              {vehicleClassList && vehicleClassList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_vehicle_class"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_vehicle_class"
                      ) > -1
                        ? 3
                        : 2
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle Class`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle class?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square bg-danger border-0`}
              onClick={onDestroyVehicleClass}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {vehicleClassData.uuid
            ? `Edit Vehicle Class`
            : `Create Vehicle Class`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleClassForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Vehicle Class"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleClassData.name}
                  name={`vehicleClass_name`}
                  onChange={handleVehicleClassName}
                  maxLength={50}
                  placeholder="Vehicle Class"
                />
                {errors.name && (
                  <span className="input-error">{errors.name}</span>
                )}
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">
                {"Vehicle Image"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="upload-inner-wrapper">
                <div className="mb-3 w-100">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img src={uploadIcon} alt="" />
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      name="myfile"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="avatar-preview">
                  <img
                    src={PreviewUrl}
                    className="employee-user"
                    style={{
                      display: "flex",
                      marginLeft: "-180px",
                    }}
                  />
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default VehicleClass;
