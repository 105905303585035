import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import { getArrivalDepartureList } from "../../services/TripService";

const tableLengthList = configDb.data.dataTableLength;

const ArrivalDepartureTrip = () => {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [ScheduleList, setScheduleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getArrivalDepartureList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setScheduleList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Arrivals And Departures</h5>
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row company-section company-section-Area">
                <div className="col-md-2">
                  <div className="row">
                    <div className="col">
                      <div className="selected-block selected-mobile">
                        <p>Show</p>
                        <select
                          className="form-select form-control-show"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="col">
                    <div className="selected-block selected-mobile">
                      <p>Search</p>
                      <div className="search-bar">
                        <i className="bi bi-search"></i>
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Vehicle`}</th>
                <th scope={`col`}>{`Route`}</th>
                <th scope={`col`}>{`Departure Place`}</th>
                <th scope={`col`}>{`Arrival Place`}</th>
                <th scope={`col`}>{`Distance`}</th>
                <th scope={`col`}>{`Duration`}</th>
                <th scope={`col`}>{`Reference`}</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {ScheduleList &&
                    ScheduleList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td style={{ cursor: "pointer" }}>
                          {item.vehicle_name}
                        </td>
                        <td style={{ cursor: "pointer" }}>{item?.trip_name}</td>
                        <td style={{ cursor: "pointer" }}>
                          {item?.source_place}
                        </td>

                        <td style={{ cursor: "pointer" }}>
                          {item?.destination_place}
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {item.total_distance} km
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {item.total_duration} m
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {item.reference_number}
                        </td>
                      </tr>
                    ))}
                </>
              )}

              {ScheduleList && ScheduleList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name === "modify_trip"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name === "delete_trip"
                      ) > -1
                        ? 8
                        : 8
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>
    </>
  );
};

export default ArrivalDepartureTrip;
