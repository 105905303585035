import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import Select from "react-select";

import {
  createAccountAutomationRule,
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;
const SameSelectionType = [
  {
    value: "1",
    label: "As the one of place where the trip ended",
  },
  { value: "2", label: "As the one of vehicle executing that trip" },
  { value: "3", label: "As the one of driver executing that trip" },
];

const AssignRuleType = [
  {
    value: "1",
    label: "Assign Now",
  },
  { value: "2", label: "Transfer from another vehicle " },
];

const TransferType = [
  {
    value: "1",
    label: "Select All",
  },
  { value: "2", label: "Active routes" },
  { value: "3", label: "Pending routes" },
  { value: "4", label: "Abandoned routes" },
];

const AutomationRule = ({ setActiveTab, AccountDetails }) => {
  const [DrivingData, setDrivingData] = useState({
    department_trips: 1,
    use_same_selection: "",
    rule_not_applied_trip: 0,
    department_id: "",
    segment_trips: 1,
    segment_use_same_selection: "",
    segment_rule_not_applied_trip: 0,
    segment_id: "",
    auxiliary_vehicle: "",
    assign_rule: "",
    transfer_includes: [],
  });

  const [departmentList, setDepartmentList] = useState([]);
  const [segementList, setSegementList] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setDrivingData((prevData) => ({
      ...prevData,
      transfer_includes: selectedValues,
    }));
  };

  useEffect(function () {
    getDepartmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepartmentList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getSegmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSegementList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (AccountDetails) {
      setDrivingData({
        department_trips: AccountDetails?.automationRules?.department_trips,
        use_same_selection: AccountDetails?.automationRules?.use_same_selection,
        rule_not_applied_trip:
          AccountDetails?.automationRules?.rule_not_applied_trip,
        department_id: AccountDetails?.automationRules?.department_id,
        segment_trips: AccountDetails?.automationRules?.segment_trips,
        segment_use_same_selection:
          AccountDetails?.automationRules?.segment_use_same_selection,
        segment_rule_not_applied_trip:
          AccountDetails?.automationRules?.segment_rule_not_applied_trip,
        segment_id: AccountDetails?.automationRules?.segment_id,
        auxiliary_vehicle: AccountDetails?.automationRules?.auxiliary_vehicle,
        assign_rule: AccountDetails?.automationRules?.assign_rule,
        transfer_includes: AccountDetails?.automationRules?.transfer_includes,
      });
    }
  }, [AccountDetails]);

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      department_trips: newValue,
    });
  };

  const handleCheckboxChangeRule = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      rule_not_applied_trip: newValue,
    });
  };

  const handleCheckboxChangeRuleTrip = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      segment_rule_not_applied_trip: newValue,
    });
  };

  const handleCheckboxSegment = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      segment_trips: newValue,
    });
  };

  const handleCheckboxAuxiliary = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDrivingData({
      ...DrivingData,
      auxiliary_vehicle: newValue,
    });
  };

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      department_trips: DrivingData?.department_trips,
      segment_trips: DrivingData?.segment_trips,
      auxiliary_vehicle: DrivingData?.auxiliary_vehicle,
      department_trips: DrivingData?.department_trips,
      use_same_selection: DrivingData?.use_same_selection,
      rule_not_applied_trip: DrivingData?.rule_not_applied_trip,
      department_id: DrivingData?.department_id || null,
      segment_trips: DrivingData?.segment_trips,
      segment_use_same_selection: DrivingData?.segment_use_same_selection,
      segment_rule_not_applied_trip: DrivingData?.segment_rule_not_applied_trip,
      segment_id: DrivingData?.segment_id || null,
      auxiliary_vehicle: DrivingData?.auxiliary_vehicle,
      assign_rule: DrivingData?.assign_rule,
      transfer_includes: DrivingData?.transfer_includes,
    };

    let updateData = {
      uuid: AccountDetails?.automationRules?.uuid,
      department_trips: DrivingData?.department_trips,
      segment_trips: DrivingData?.segment_trips,
      auxiliary_vehicle: DrivingData?.auxiliary_vehicle,
      department_trips: DrivingData?.department_trips,
      use_same_selection: DrivingData?.use_same_selection,
      rule_not_applied_trip: DrivingData?.rule_not_applied_trip,
      department_id: DrivingData?.department_id || null,
      segment_trips: DrivingData?.segment_trips,
      segment_use_same_selection: DrivingData?.segment_use_same_selection,
      segment_rule_not_applied_trip: DrivingData?.segment_rule_not_applied_trip,
      segment_id: DrivingData?.segment_id || null,
      auxiliary_vehicle: DrivingData?.auxiliary_vehicle,
      assign_rule: DrivingData?.assign_rule,
      transfer_includes: DrivingData?.transfer_includes,
    };
    if (AccountDetails?.automationRules?.uuid) {
      modifymanagament(createAccountAutomationRule, updateData);
    } else {
      modifymanagament(createAccountAutomationRule, data);
    }
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        // setDrivingData({
        //   energy_type_id: "",
        //   department_trips: "",
        //   segment_trips: "",
        //   auxiliary_vehicle: "",
        // });
        setActiveTab("calculationOptions");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const selectedUse = SameSelectionType.find(
    (option) => option.value === JSON.stringify(DrivingData.use_same_selection)
  );

  const selectedDepartment = departmentList.find(
    (option) => option.value === JSON.stringify(DrivingData.department_id)
  );

  const selectedSelection = SameSelectionType.find(
    (option) =>
      option.value === JSON.stringify(DrivingData.segment_use_same_selection)
  );

  const selectedSegment = segementList.find(
    (option) => option.value === JSON.stringify(DrivingData.segment_id)
  );

  const selectedAssign = AssignRuleType.find(
    (option) => option.value === JSON.stringify(DrivingData.assign_rule)
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Department</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Use automatic associations to trips
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="department_trips"
                          checked={DrivingData.department_trips === 1}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="department_trips"
                        >
                          Driving teams
                        </label>
                      </div>
                    </div>
                  </div>

                  {DrivingData.department_trips === 1 && (
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        Use the same selection
                      </Label>
                      <div className="input-div">
                        <Select
                          options={SameSelectionType}
                          value={selectedUse}
                          onChange={(selectedOption) =>
                            setDrivingData((prevData) => ({
                              ...prevData,
                              use_same_selection: selectedOption.value,
                            }))
                          }
                          placeholder={"Use automatic associations to trips"}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      If the rule above cannot be applied, associate the trip to
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="rule_not_applied_trip"
                          checked={DrivingData.rule_not_applied_trip}
                          onChange={handleCheckboxChangeRule}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="rule_not_applied_trip"
                        >
                          If the rule above cannot be applied, associate the
                          trip to
                        </label>
                      </div>
                    </div>
                  </div>

                  {DrivingData.rule_not_applied_trip === 1 && (
                    <div className="col-md-4">
                      <h6 className="mb-0 mt-3 mb-2">Department</h6>
                      <div className="input-div">
                        <Select
                          options={departmentList}
                          value={selectedDepartment}
                          onChange={(selectedOption) =>
                            setDrivingData((prevData) => ({
                              ...prevData,
                              department_id: selectedOption.value,
                            }))
                          }
                          placeholder={"None"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>

              <h4 className="mb-3">Segment</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Use automatic associations to trips
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="segment_trips"
                          checked={DrivingData.segment_trips}
                          onChange={handleCheckboxSegment}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="segment_trips"
                        >
                          Use automatic associations to trips{" "}
                        </label>
                      </div>
                    </div>
                  </div>

                  {DrivingData.segment_trips === 1 && (
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        Use the same selection
                      </Label>
                      <div className="input-div">
                        <Select
                          options={SameSelectionType}
                          value={selectedSelection}
                          onChange={(selectedOption) =>
                            setDrivingData((prevData) => ({
                              ...prevData,
                              segment_use_same_selection: selectedOption.value,
                            }))
                          }
                          placeholder={"Use the same selection"}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      If the rule above cannot be applied, associate the trip to
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="segment_rule_not_applied_trip"
                          checked={DrivingData.segment_rule_not_applied_trip}
                          onChange={handleCheckboxChangeRuleTrip}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="segment_rule_not_applied_trip"
                        >
                          If the rule above cannot be applied, associate the
                          trip to
                        </label>
                      </div>
                    </div>
                  </div>
                  {DrivingData.segment_rule_not_applied_trip === 1 && (
                    <div className="col-xl-4">
                      <h6 className="mb-1 mt-1 Segments">Segments</h6>
                      <div className="input-div">
                        <Select
                          options={segementList}
                          value={selectedSegment}
                          onChange={(selectedOption) =>
                            setDrivingData((prevData) => ({
                              ...prevData,
                              segment_id: selectedOption.value,
                            }))
                          }
                          placeholder={"None"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>

              <h4 className="mb-3">Use Auxiliary Id Associations</h4>

              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Associate route to vehicle
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="auxiliary_vehicle"
                          checked={DrivingData.auxiliary_vehicle}
                          onChange={handleCheckboxAuxiliary}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="auxiliary_vehicle"
                        >
                          Associate route to vehicle
                        </label>
                      </div>
                    </div>
                  </div>

                  {DrivingData.auxiliary_vehicle === 1 && (
                    <div className="col-md-4">
                      <Label className="col-form-label">Assign rule</Label>
                      <div className="input-div">
                        <Select
                          options={AssignRuleType}
                          value={selectedAssign}
                          onChange={(selectedOption) =>
                            setDrivingData((prevData) => ({
                              ...prevData,
                              assign_rule: selectedOption.value,
                            }))
                          }
                          placeholder={"Assign Now"}
                        />
                      </div>
                    </div>
                  )}

                  {DrivingData.assign_rule === "2" && (
                    <div className="col-md-4">
                      <Label className="col-form-label">
                        Transfer includes
                      </Label>
                      <div className="input-div">
                        <Select
                          options={TransferType}
                          isMulti
                          value={TransferType.filter(
                            (option) =>
                              Array.isArray(DrivingData?.transfer_includes) &&
                              DrivingData?.transfer_includes.includes(
                                option.value
                              )
                          )}
                          onChange={handleChange}
                          placeholder={"Select Transfer"}
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationRule;
