import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  actionApproveRejectCompany,
  getCompanyDetail,
} from "../../services/CompanyService";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useSelector } from "react-redux";
import {
  actionCountryList,
  actionLanguageList,
  actionTimeZoneList,
} from "../../services/Common";

function CompanyDetails(props) {
  const { uuid } = useParams();
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [companyData, setCompanyData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [isApproveRejectLoad, setIsApproveRejectLoad] = useState(false);
  const [reason, setReason] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  let errorsObj = { name: "", reason: "" };
  const [errors, setErrors] = useState(errorsObj);

  const navigate = useNavigate();

  async function loadInitialData(data) {
    let responseData = {};
    await getCompanyDetail(data)
      .then((response) => {
        responseData = response?.data?.data;
        setCompanyData(responseData);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });

    await actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});

    await actionTimeZoneList({ countryId: responseData?.country })
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setTimeZoneList(list);
      })
      .catch((err) => {});

    await actionLanguageList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].language_name,
          });
        }
        setLanguageList(list);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    let data = { uuid };
    loadInitialData(data);
  }, [uuid]);

  const onApproveReject = (e) => {
    e.preventDefault();
    setIsModal(true);
  };

  const onApproveRejectClose = (e) => {
    e.preventDefault();
    setReason("");
    setErrors({ ...errorsObj, reason: "" });
    setIsModal(false);
  };

  const onApproveRejectCompany = (e) => {
    e.preventDefault();
    setIsApproveRejectLoad(true);
    let data = { uuid: companyData.uuid, status: 1, reason: "" };
    if (isReject) {
      let errorObj = { ...errorsObj };
      if (!reason) {
        errorObj.reason = `Reason for rejection is required.`;
        setErrors(errorObj);
        setIsApproveRejectLoad(false);
        return;
      }

      data.reason = reason;
      data.status = 2;
    }
    actionApproveRejectCompany(data)
      .then((response) => {
        //         let paramsObj = {...params};
        //         paramsObj.is_reload = !paramsObj.is_reload;
        //         setParams(paramsObj);
        toast.success(response.data.message);
        setCompanyData({ ...companyData, status: data.status });
        setReason("");
        setErrors({ ...errorsObj, reason: "" });
        setIsModal(false);
        navigate("/company");
      })
      .catch((err) => {
        setIsApproveRejectLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <div className="signup-content mx-auto">
        {/*{allPermissionsList.findIndex(e => e.name == 'modify_company') > -1 && (*/}
        {/*<Button type={`button`}*/}
        {/*onClick={onEditCancel}*/}
        {/*className={`btn ms-2 mb-3 ` + (isDisabled ? 'btn-info' : 'btn-danger')}>*/}
        {/*{isDisabled ? (*/}
        {/*<>*/}
        {/*{'Edit'}<i className="bi bi-pencil-fill"></i>*/}
        {/*</>)*/}
        {/*:*/}
        {/*<>*/}
        {/*{`Cancel`} <i className="bi bi-x-circle"></i>*/}
        {/*</>}*/}

        {/*</Button>*/}
        {/*)}*/}

        <form method={"post"}>
          <div className="row">
            <div className="col-xl-4">
              <Label className="col-form-label">{"Name"}</Label>
              <div className="mb-3">
                <input
                  type="text"
                  id=""
                  className="form-control"
                  value={companyData.name}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, name: e.target.value })
                  }
                  placeholder="Jordan Candor"
                />
                {/*{errors.name && (<span className='input-error'>{errors.name}</span>)}*/}
              </div>
            </div>

            <div className="col-xl-4">
              <Label className="col-form-label">{"Contact Number"}</Label>
              <div className="mb-3">
                <input
                  type="number"
                  id=""
                  className="form-control"
                  value={companyData.contact}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, contact: e.target.value })
                  }
                  placeholder="Contact"
                />
                {/*{errors.contact && (<span className='input-error'>{errors.contact}</span>)}*/}
              </div>
            </div>

            <div className="col-xl-4">
              <Label className="col-form-label">{"Logo"}</Label>

              <div className="mb-3">
                <img src={companyData?.logo} alt="" />
              </div>
            </div>
            {/*<div className="upload-btn-wrapper">*/}
            {/*<label>Add logo</label>*/}
            {/*<button className="btn">*/}
            {/*<img src={uploadIcon} alt=""/>*/}
            {/*</button>*/}
            {/*<input type="file" name="myfile"/>*/}
            {/*</div>*/}
            {/*/!*{errors.contact && (<span className='input-error'>{errors.contact}</span>)}*!/*/}
            {/*</div>*/}
            {/*</div>*/}

            <div className="col-xl-8">
              <Label className="col-form-label">{"Address"}</Label>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="1"
                  placeholder="Address"
                  disabled={isDisabled}
                  defaultValue={companyData.address}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, address: e.target.value })
                  }
                ></textarea>
                {/*{errors.address && (<span className='input-error'>{errors.address}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Tin"}</Label>
              <div className="mb-3">
                <input
                  type="text"
                  id=""
                  className="form-control"
                  value={companyData.tin}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, tin: e.target.value })
                  }
                  placeholder="Tin"
                />
                {/*{errors.tin && (<span className='input-error'>{errors.tin}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Select country"}</Label>
              <div className="mb-3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  disabled={isDisabled}
                  value={companyData.country}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, country: e.target.value })
                  }
                >
                  <option value="">Select country</option>
                  {countryList?.length > 0 &&
                    countryList.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
                {/*{errors.country && (<span className='input-error'>{errors.country}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Select timezone"}</Label>
              <div className="mb-3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  disabled={isDisabled}
                  value={companyData.c_timezone}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      c_timezone: e.target.value,
                    })
                  }
                >
                  <option value="">Select timezone</option>
                  {timeZoneList?.length > 0 &&
                    timeZoneList?.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
                {/*{errors.timeZone && (<span className='input-error'>{errors.timeZone}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Vat Number"}</Label>
              <div className="mb-3">
                <input
                  type="number"
                  id=""
                  className="form-control"
                  value={companyData.vat_number}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      vat_number: e.target.value,
                    })
                  }
                  placeholder="Vat Number"
                />
                {/*{errors.vatNumber && (<span className='input-error'>{errors.vatNumber}</span>)}*/}
              </div>
            </div>

            <div className="col-xl-4">
              <Label className="col-form-label">{"Select language"}</Label>
              <div className="mb-3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) =>
                    setCompanyData({ ...companyData, language: e.target.value })
                  }
                  disabled={isDisabled}
                  value={companyData.language}
                >
                  <option value="">Select language</option>
                  {languageList?.length > 0 &&
                    languageList.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
                {/*{errors.language && (<span className='input-error'>{errors.language}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Email"}</Label>
              <div className="mb-3">
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  value={companyData.company_email}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      company_email: e.target.value,
                    })
                  }
                  placeholder="Company email"
                />
                {/*{errors.companyEmail && (*/}
                {/*<span className='input-error'>{errors.companyEmail}</span>)}*/}
              </div>
            </div>
            <div className="line-border"></div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Contact name"}</Label>
              <div className="mb-3">
                <input
                  type="text"
                  id=""
                  className="form-control"
                  value={companyData.primary_contact_name}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      primary_contact_name: e.target.value,
                    })
                  }
                  placeholder="Contact name"
                />
                {/*{errors.contactName && (<span className='input-error'>{errors.contactName}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Contact DOB"}</Label>
              <div className="mb-3">
                <input
                  type="date"
                  id=""
                  className="form-control"
                  disabled={isDisabled}
                  value={companyData.contact_dob}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      contact_dob: e.target.value,
                    })
                  }
                  placeholder="Contact DOB"
                />
                {/*{errors.contactDOB && (<span className='input-error'>{errors.contactDOB}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Contact email"}</Label>
              <div className="mb-3">
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  disabled={isDisabled}
                  value={companyData.contact_email}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      contact_email: e.target.value,
                    })
                  }
                  placeholder="Contact email"
                />
                {/*{errors.contactEmail && (*/}
                {/*<span className='input-error'>{errors.contactEmail}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-4">
              <Label className="col-form-label">{"Contact number"}</Label>
              <div className="mb-3">
                <input
                  type="number"
                  id=""
                  className="form-control"
                  disabled={isDisabled}
                  value={companyData.contact_number}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      contact_number: e.target.value,
                    })
                  }
                  placeholder="Contact number"
                />
                {/*{errors.contact && (*/}
                {/*<span className='input-error'>{errors.contactEmail}</span>)}*/}
              </div>
            </div>
            <div className="col-xl-12 mt-3 d-flex justify-content-between align-items-center">
              <NavLink
                to={`/company`}
                role={`button`}
                end
                className={`btn btn-dark me-2`}
              >{`Back`}</NavLink>

              <div>
                {!isDisabled && (
                  <button type="submit" className={"btn btn-success me-2"}>
                    {`Submit`}
                    <i className="bi bi-arrow-left-circle"></i>
                  </button>
                )}

                {companyData.status == 0 &&
                  allPermissionsList.findIndex(
                    (e) => e.name == "approval_company"
                  ) > -1 && (
                    <>
                      <button
                        type="button"
                        onClick={(e) => {
                          onApproveReject(e);
                          setIsReject(false);
                        }}
                        className={"btn btn-success me-2"}
                      >
                        {`Approve`}
                        <i
                          className="bi bi-check-circle-fill"
                          style={{ marginLeft: "10px" }}
                        ></i>
                      </button>

                      <button
                        type="button"
                        onClick={(e) => {
                          onApproveReject(e);
                          setIsReject(true);
                        }}
                        className={"btn btn-danger me-2"}
                      >
                        {`Reject`}
                        <i
                          className="bi bi-x-circle-fill"
                          style={{ marginLeft: "10px" }}
                        ></i>
                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal isOpen={isModal}>
        <ModalHeader>{`${
          isReject ? "Reject" : "Approve"
        } Company`}</ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>{`Are you sure you want to ${
            isReject ? "reject" : "approve"
          } this company?`}</p>
        </ModalBody>
        <Form>
          {isReject && (
            <ModalBody>
              <FormGroup>
                <Label className="col-form-label">
                  {"Reason"}
                  <span className={`text-danger ml-1`}>*</span>
                </Label>
                <div className="input-div">
                  <textarea
                    className="form-control"
                    id="id_reject_reason_txt"
                    rows="3"
                    placeholder="Reject reason"
                    name={`reject_reason`}
                    disabled={isApproveRejectLoad}
                    defaultValue={reason}
                    onChange={(e) => setReason(e.target.value)}
                  ></textarea>
                  {errors.reason && (
                    <span className="input-error">{errors.reason}</span>
                  )}
                </div>
              </FormGroup>
            </ModalBody>
          )}
          <ModalFooter>
            <Button
              type={`button`}
              disabled={isApproveRejectLoad}
              onClick={onApproveRejectClose}
              className={`btn btn-dark`}
            >{`Close`}</Button>
            {!isApproveRejectLoad && (
              <Button
                type={`submit`}
                className={`btn ` + (isReject ? "btn-danger" : "btn-success")}
                onClick={onApproveRejectCompany}
              >
                {isReject ? "Reject" : "Approve"}
                {isReject ? (
                  <i className="bi bi-x-circle-fill"></i>
                ) : (
                  <i className="bi bi-check-circle-fill"></i>
                )}
              </Button>
            )}
            {isApproveRejectLoad && (
              <Button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default CompanyDetails;
