import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddUserGeneral } from "../../services/EmployeeService";
import validator from "validator";
import {
  actionCompanyList,
  actionLanguageList,
  actionTimeAllZoneList,
} from "../../services/Common";
import {
  getDepartmentAllList,
  getSegmentAllList,
} from "../../services/ManagementService";
import { actionChangePassword } from "../../services/ProfileService";

const useRoleList = [
  {
    value: 3,
    label: "Manager",
  },
  {
    value: 4,
    label: "Driver",
  },
];

const GeneralEmployee = ({ setActiveTab, usersDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const [employeeData, setEmployeeData] = useState({
    name: "",
    short_name: "",
    language_id: "",
    timezone_id: "",
    department_id: "",
    segement_id: "",
    email: "",
    password: "",
    pin: "",
    user_role: "",
    companyId: "",
    number: "",
  });

  const [languageList, setLanguageList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [segementList, setSegementList] = useState([]);
  const [passwordModel, setPasswordModel] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const errorsObj = {
    newPassword: "",
    confirmPassword: "",
    oldPassword: "",
    name: "",
    short_name: "",
    language_id: "",
    timezone_id: "",
    email: "",
    password: "",
    pin: "",
    user_role: "",
    number: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [isPasswordButtonLoad, setIsPasswordButtonLoad] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(function () {
    actionCompanyList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setCompanyList(list);
      })
      .catch((err) => {});
  }, []);

  const generatePIN = () => {
    return Math.floor(100000 + Math.random() * 900000).toString(); // Generates a 6-digit PIN
  };

  useEffect(() => {
    setEmployeeData((prev) => ({ ...prev, pin: generatePIN() }));
  }, []);

  const handlePasswordModel = () => {
    setPasswordModel(true);
  };

  const handlePasswordModelClose = () => {
    setPasswordModel(false);
  };

  const onChangePasswordSubmit = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!newPassword) {
      errorObj.newPassword = "New Password is required";
      error = true;
    } else if (
      !validator.isStrongPassword(newPassword, ConfigDB.data.strongPassword)
    ) {
      errorObj.newPassword =
        "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
      error = true;
    }

    if (!oldPassword) {
      errorObj.oldPassword = "Old Password is required";
      error = true;
    } else if (
      !validator.isStrongPassword(oldPassword, ConfigDB.data.strongPassword)
    ) {
      errorObj.oldPassword =
        "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
      error = true;
    }

    if (oldPassword === newPassword) {
      errorObj.newPassword = "New Password should not be same as Old Password";
      error = true;
    }

    if (!confirmPassword) {
      errorObj.confirmPassword = "Confirm password is required";
      error = true;
    } else if (newPassword !== confirmPassword) {
      errorObj.confirmPassword =
        "New password and confirm password does not match";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    setIsPasswordButtonLoad(true);
    let data = { password: newPassword, oldPassword: oldPassword };
    actionChangePassword(data)
      .then((response) => {
        toast.success(response.data.message);
        setIsPasswordButtonLoad(false);
        setNewPassword("");
        setOldPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
        setIsPasswordButtonLoad(false);
      });
  };

  useEffect(function () {
    actionLanguageList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].language_name,
          });
        }
        setLanguageList(list);
      })
      .catch((err) => {});

    actionTimeAllZoneList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setTimeZoneList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getDepartmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepartmentList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getSegmentAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSegementList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (usersDetails) {
      setEmployeeData({
        name: usersDetails?.name,
        short_name: usersDetails?.short_name,
        language_id: usersDetails?.language_id,
        timezone_id: usersDetails?.timezone_id,
        department_id: usersDetails?.department_id,
        segement_id: usersDetails?.segment_id,
        email: usersDetails?.email,
        pin: usersDetails?.pin,
        user_role: usersDetails?.user_role,
        companyId: usersDetails?.companyId,
        number: usersDetails?.number,
      });
    }
  }, [usersDetails]);

  const handleEmployeeForm = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;

    if (!employeeData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    if (!employeeData?.short_name) {
      errorObj.short_name = "Short Name is required";
      error = true;
    }

    if (!employeeData?.language_id) {
      errorObj.language_id = "Language is required";
      error = true;
    }

    if (!employeeData?.timezone_id) {
      errorObj.timezone_id = "Timezone is required";
      error = true;
    }

    if (!employeeData?.email) {
      errorObj.email = "Email is required";
      error = true;
    }
    // if (
    //   !validator.isStrongPassword(
    //     employeeData?.password,
    //     ConfigDB.data.strongPassword
    //   )
    // ) {
    //   errorObj.password =
    //     "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
    //   error = true;
    // }

    if (!employeeData?.pin) {
      errorObj.pin = "Pin is required";
      error = true;
    }

    if (!employeeData?.user_role) {
      errorObj.user_role = "Role is required";
      error = true;
    }

    if (JsonParse.user_role == ConfigDB.data.ROLE_ADMIN) {
      if (!employeeData.companyId) {
        errorObj.companyId = `Select Company is required`;
        error = true;
      }
    }

    if (!employeeData?.number) {
      errorObj.number = "Contact is required";
      error = true;
    } else if (employeeData?.number.length < 9) {
      errorObj.number = "Contact must be at least 9 digits";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let data = {
      name: employeeData?.name,
      email: employeeData?.email,
      userRole: employeeData?.user_role,
      short_name: employeeData?.short_name,
      language_id: employeeData?.language_id,
      timezone_id: employeeData?.timezone_id,
      department_id: employeeData?.department_id,
      segement_id: employeeData?.segement_id,
      password: employeeData?.password,
      pin: employeeData?.pin,
      number: employeeData?.number,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      data.companyId = employeeData.companyId;
    }

    let updateDatadata = {
      uuid: usersDetails?.uuid,
      name: employeeData?.name,
      email: employeeData?.email,
      userRole: employeeData?.user_role,
      short_name: employeeData?.short_name,
      language_id: employeeData?.language_id,
      timezone_id: employeeData?.timezone_id,
      department_id: employeeData?.department_id,
      segement_id: employeeData?.segement_id,
      password: "",
      pin: usersDetails?.pin,
      number: employeeData?.number,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      updateDatadata.companyId = employeeData.companyId;
    }

    if (usersDetails?.uuid) {
      modifyEmployee(createAddUserGeneral, updateDatadata);
    } else {
      modifyEmployee(createAddUserGeneral, data);
    }
  };

  const modifyEmployee = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        console.log("response", response);
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("filtering");
        localStorage.setItem("UserUUID", response.data.data.uuid);
        localStorage.setItem("UserId", response.data.data.id);
        localStorage.setItem("UserEmail", response.data.data.email);
        localStorage.setItem("UserCompanyId", response.data.data.company_id);
        localStorage.setItem("UserName", response.data.data.name);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleEmployeeForm}>
              <h4>General</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Username"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.name}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        placeholder="Username"
                      />
                      {errors.name && (
                        <span className="input-error">{errors.name}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Short name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.short_name}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            short_name: e.target.value,
                          });
                        }}
                        name={`short_name`}
                        placeholder="Short name"
                      />
                      {errors.short_name && (
                        <span className="input-error">{errors.short_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Contact Number"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.number}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            number: e.target.value,
                          });
                        }}
                        name={`number`}
                        placeholder="Contact Number"
                      />
                      {errors.number && (
                        <span className="input-error">{errors.number}</span>
                      )}
                    </div>
                  </div>

                  {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
                    <Label className="col-form-label">
                      {"Company List"}
                      <span className={`text-danger ml-1`}>*</span>
                    </Label>
                  ) : null}

                  {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
                    <div className="mb-3">
                      <Select
                        options={companyList}
                        value={companyList.find(
                          (option) => option.value === employeeData.companyId
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            companyId: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Company"}
                      />
                      {errors.companyId && (
                        <span className="input-error">{errors.companyId}</span>
                      )}
                    </div>
                  ) : null}

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Email"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.email}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            email: e.target.value,
                          });
                        }}
                        name={`email`}
                        placeholder="Email"
                      />
                      {errors.email && (
                        <span className="input-error">{errors.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Password"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.password}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            password: e.target.value,
                          });
                        }}
                        name={`password`}
                        placeholder="Password"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Pin"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={employeeData.pin}
                        onChange={(e) => {
                          setEmployeeData({
                            ...employeeData,
                            pin: e.target.value,
                          });
                        }}
                        name="pin"
                        placeholder="Pin"
                        readOnly
                      />
                      {errors.pin && (
                        <span className="input-error">{errors.pin}</span>
                      )}
                    </div>
                    {!usersDetails?.uuid && (
                      <button
                        type="button"
                        className="btn btn-primary mt-2"
                        onClick={() =>
                          setEmployeeData({
                            ...employeeData,
                            pin: generatePIN(),
                          })
                        }
                      >
                        Generate PIN
                      </button>
                    )}
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Select Role"}</Label>
                    <div className="input-div">
                      <Select
                        options={useRoleList}
                        value={useRoleList.find(
                          (option) => option.value === employeeData.user_role
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            user_role: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Role"}
                      />
                      {errors.user_role && (
                        <span className="input-error">{errors.user_role}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Language"}</Label>
                    <div className="input-div">
                      <Select
                        options={languageList}
                        value={languageList?.find(
                          (option) => option.value === employeeData.language_id
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            language_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Language"}
                      />
                      {errors.language_id && (
                        <span className="input-error">
                          {errors.language_id}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Time zone"}</Label>
                    <div className="input-div">
                      <Select
                        options={timeZoneList}
                        value={timeZoneList?.find(
                          (option) => option.value === employeeData.timezone_id
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            timezone_id: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Time zone"}
                      />
                      {errors.timezone_id && (
                        <span className="input-error">
                          {errors.timezone_id}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </FormGroup>

              <h4>Organization</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">Department</Label>
                    <div className="input-div">
                      <Select
                        options={departmentList}
                        value={departmentList?.find(
                          (option) =>
                            option.value ===
                            JSON.stringify(employeeData.department_id)
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            department_id: selectedOption.value,
                          }))
                        }
                        placeholder={"None"}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">Segement</Label>
                    <div className="input-div">
                      <Select
                        options={segementList}
                        value={segementList?.find(
                          (option) => option.value === employeeData.segement_id
                        )}
                        onChange={(selectedOption) =>
                          setEmployeeData((prevData) => ({
                            ...prevData,
                            segement_id: selectedOption.value,
                          }))
                        }
                        placeholder={"None"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>

        <Modal isOpen={passwordModel}>
          <form method={`post`} onSubmit={onChangePasswordSubmit}>
            <ModalHeader>{`Change Password`}</ModalHeader>
            <ModalBody>
              <div className="inputDiv">
                <label className="inputLabel">Old Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                {errors.oldPassword && (
                  <span className="input-error">{errors.oldPassword}</span>
                )}
              </div>
              <div className="inputDiv">
                <label className="inputLabel">New Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {errors.newPassword && (
                  <span className="input-error">{errors.newPassword}</span>
                )}
              </div>
              <div className="inputDiv">
                <label className="inputLabel">Confirm Password</label>
                <input
                  className="form-control"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errors.confirmPassword && (
                  <span className="input-error">{errors.confirmPassword}</span>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="primary"
                type={`button`}
                disabled={isPasswordButtonLoad}
                outline
                onClick={handlePasswordModelClose}
                className={`btn-square`}
              >{`Close`}</Button>
              {!isPasswordButtonLoad && (
                <Button
                  color="secondary"
                  type="submit"
                  className={`btn-square btn-info text-light`}
                >{`Change Password`}</Button>
              )}
              {isPasswordButtonLoad && (
                <Button
                  color="secondary"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default GeneralEmployee;
