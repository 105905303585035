import React, { useEffect, useState } from "react";
import General from "./General";
import ManagementVehicle from "./ManagementVehicle";
import Extra from "./Extra";
import PrivacyMode from "./PrivacyMode";
import WorkingPeriod from "./WorkingPeriod";
import VehicleStatus from "./VehicleStatus";
import { getVehicleAllDetail } from "../../services/VehicleService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import Tachograph from "./Tachograph";
import PermittedDriving from "./PermittedDriving";

const AddVehicle = ({
  setAddVehicleStatus,
  VehicleStatusData,
  setActiveTabs,
  setRefreshKey,
  activeTabs,
}) => {
  const [activeTab, setActiveTab] = useState("general");
  const [vehicleDetails, setVehicleDetails] = useState({});

  const VehicleDataId = localStorage.getItem("vehicleId");

  const isTabEnabled = VehicleDataId || VehicleStatusData?.id;

  const handleTabClick = (tab) => {
    if (!isTabEnabled && tab !== "general") return;
    setActiveTab(tab);
  };

  useEffect(function () {
    if (VehicleStatusData?.uuid) {
      let data = {
        uuid: VehicleStatusData?.uuid,
      };
      getVehicleAllDetail(data)
        .then((response) => {
          setVehicleDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, []);

  const fetchUpdatedVehicles = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block">
          <h5>Management</h5>
        </div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => {
              setAddVehicleStatus(false);
              setActiveTabs("vehicle");
              fetchUpdatedVehicles();
            }}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="tight-block"></div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "management" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("management");
                }}
              >
                <span data-href="#tab-1">Management</span>
              </li>

              <li
                className={`nav-link ${activeTab === "extra" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("extra");
                }}
              >
                <span data-href="#tab-1">Extra</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "privacyMode" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("privacyMode");
                }}
              >
                <span data-href="#tab-1">Privacy Mode</span>
              </li>

              <li
                className={`nav-link ${activeTab === "status" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("status");
                }}
              >
                <span data-href="#tab-1">Status</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "WorkingPeriod" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("WorkingPeriod");
                }}
              >
                <span data-href="#tab-1">Working Period</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "DrivingWorkingPeriod" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("DrivingWorkingPeriod");
                }}
              >
                <span data-href="#tab-1">Permitted Driving Period</span>
              </li>

              <li
                className={`nav-link ${
                  activeTab === "tachnograph" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("tachnograph");
                }}
              >
                <span data-href="#tab-1">Tachograph</span>
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <General
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "management" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <ManagementVehicle
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "extra" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Extra
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "privacyMode" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <PrivacyMode
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "status" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <VehicleStatus
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
              />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "WorkingPeriod" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <WorkingPeriod
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "DrivingWorkingPeriod" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <PermittedDriving
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "tachnograph" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Tachograph
                setActiveTab={setActiveTab}
                vehicleDetails={vehicleDetails}
                setAddVehicleStatus={setAddVehicleStatus}
                setActiveTabs={setActiveTabs}
                setRefreshKey={setRefreshKey}
                activeTabs={activeTabs}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVehicle;
