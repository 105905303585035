import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import {
  actionDelSubscription,
  actionStoreSubscription,
  actionUpdateSubscription,
  getSubscriptionList,
} from "../../services/SubscriptionService";
import plusIcon from "../../assets/images/plus-i.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const tableLengthList = configDb.data.dataTableLength;

function Subscription(props) {
  const subscriptionTypeList = ConfigDB.data.SUBSCRIPTION_TYPE_LIST;
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    uuid: "",
    name: "",
    description: "",
    type: "",
    price: "",
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { name: "", description: "", price: "", type: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [editorData, setEditorData] = useState("");
  const [isChecked, setIsChecked] = useState(0);

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };
  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getSubscriptionList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setSubscriptionList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setSubscriptionData({
      uuid: "",
      name: "",
      description: "",
      type: "",
      price: "",
    });
    setModifyModal(true);
  };
  const onEditSubscription = (e, item) => {
    e.preventDefault();
    setSubscriptionData({
      uuid: item.uuid,
      name: item.subscription,
      description: item.subscription_desc,
      type: item.type,
      price: item.price,
    });
    setModifyModal(true);
  };

  const onDeleteSubscription = (e, item) => {
    e.preventDefault();
    setDelSubscription(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    actionDelSubscription(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleSubscriptionForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!subscriptionData.name) {
      errorObj.name = `Subscription name required.`;
      error = true;
    }

    if (!subscriptionData.type) {
      errorObj.type = `Select subscription type.`;
      error = true;
    }

    if (!subscriptionData.price) {
      errorObj.price = `Subscription price required.`;
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let data = {
      subscription: subscriptionData.name,
      subscription_desc: editorData,
      monthly_show_price: subscriptionData.price,
      price:
        subscriptionData.type === "2"
          ? Math.round(subscriptionData.price * 12)
          : subscriptionData.price,

      type: subscriptionData.type,
      is_most_popular: isChecked ? isChecked : 0,
    };

    console.log(
      "Math.round(subscriptionData.price * 12)",
      Math.round(subscriptionData.price * 12)
    );

    if (subscriptionData.uuid) {
      data["uuid"] = subscriptionData.uuid;
      modifySubscription(actionUpdateSubscription, data);
    } else {
      modifySubscription(actionStoreSubscription, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setSubscriptionData({
          uuid: "",
          name: "",
          description: "",
          type: "",
          price: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setSubscriptionData({
      uuid: "",
      name: "",
      description: "",
      type: "",
      price: "",
    });
    setModifyModal(false);
    setErrors(errorsObj);
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Subscription List</h5>
        </div>

        <div className="right-block w-auto">
          {allPermissionsList.findIndex((e) => e.name == "modify_roles") >
            -1 && (
            <Button
              type={`button`}
              onClick={onAddSubscription}
              className={`add-btn btn  float-right`}
            >
              {`Add Subscription`}
              <i className="plus-circle">
                <img src={plusIcon} alt="" className="plus-icons" />
              </i>
            </Button>
          )}
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between">
                <div className="col-2">
                  <div className="selected-block">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-table-row">
        <table
          className={`table table-striped ${isLoading ? "loading" : ""}`}
          width="100%"
        >
          <thead>
            <tr>
              <th scope={`col`}>#</th>
              <th scope={`col`}>{`Title`}</th>
              <th scope={`col`}>{`Description`}</th>
              <th scope={`col`}>{`Type`}</th>
              <th scope={`col`}>{`Price`}</th>
              {(allPermissionsList.findIndex(
                (e) => e.name == "modify_subscription"
              ) > -1 ||
                allPermissionsList.findIndex((e) => e.name == "delete_roles") >
                  -1) && <th scope={`col`}>{`Action`}</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={6} className="text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {subscriptionList &&
                  subscriptionList?.data?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.index}</td>
                      <td>{item.subscription}</td>
                      <td>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.subscription_desc,
                          }}
                        />
                      </td>
                      <td>
                        {
                          subscriptionTypeList.find((e) => e.value == item.type)
                            ?.label
                        }
                      </td>
                      <td>{item.price} €</td>
                      {(allPermissionsList.findIndex(
                        (e) => e.name == "modify_subscription"
                      ) > -1 ||
                        allPermissionsList.findIndex(
                          (e) => e.name == "delete_subscription"
                        ) > -1) && (
                        <td>
                          {allPermissionsList.findIndex(
                            (e) => e.name == "delete_subscription"
                          ) > -1 && (
                            <button
                              type={`button`}
                              onClick={(e) => onDeleteSubscription(e, item)}
                              className={`btn btn-circle btn-danger add-btn-circle me-2`}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </>
            )}

            {subscriptionList && subscriptionList?.data?.length === 0 && (
              <tr>
                <td
                  colSpan={
                    allPermissionsList.findIndex(
                      (e) => e.name == "modify_subscription"
                    ) > -1 ||
                    allPermissionsList.findIndex(
                      (e) => e.name == "delete_subscription"
                    ) > -1
                      ? 6
                      : 5
                  }
                  className={`text-center`}
                >{`Record Not Found`}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={`float-right`}>
        <Pagination
          activePage={params.page}
          itemsCountPerPage={parseInt(params.limit)}
          totalItemsCount={parseInt(totalRecords)}
          pageRangeDisplayed={5}
          itemClass={`page-item`}
          linkClass={`page-link`}
          onChange={(e) => handleParams(e, "pagination")}
        />
      </div>

      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {subscriptionData.uuid ? `Edit subscription` : `Create subscription`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleSubscriptionForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"Subscription name"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={subscriptionData.name}
                  onChange={(e) => {
                    setSubscriptionData({
                      ...subscriptionData,
                      name: e.target.value,
                    });
                  }}
                  name={`subscription_title`}
                  maxLength={50}
                  placeholder="Subscription name"
                />
                {errors.name && (
                  <span className="input-error">{errors.name}</span>
                )}
              </div>

              <Label className="col-form-label">
                {"Subscription description"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              {/* <div className="input-div">
                <textarea
                  className="form-control"
                  id="id_subscription_description_txt"
                  rows="3"
                  placeholder="Subscription description"
                  name={`subscription_description`}
                  onChange={(e) => {
                    setSubscriptionData({
                      ...subscriptionData,
                      description: e.target.value,
                    });
                  }}
                  // disabled={isApproveRejectLoad}
                  defaultValue={subscriptionData.description}
                  // onChange={(e) => setReason(e.target.value)}
                ></textarea>
                {errors.description && (
                  <span className="input-error">{errors.description}</span>
                )}
              </div> */}

              <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleChange}
              />

              <Label className="col-form-label">
                {"Subscription type"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  defaultValue={subscriptionData.type}
                  onChange={(e) => {
                    setSubscriptionData({
                      ...subscriptionData,
                      type: e.target.value,
                    });
                  }}
                >
                  <option value="">Select subscription type</option>
                  {subscriptionTypeList.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {errors.type && (
                  <span className="input-error">{errors.type}</span>
                )}
              </div>

              <Label className="col-form-label">
                {"Subscription price"}€
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={subscriptionData.price}
                  onChange={(e) => {
                    setSubscriptionData({
                      ...subscriptionData,
                      price: e.target.value,
                    });
                  }}
                  min={0}
                  name={`subscription_title`}
                  maxLength={50}
                  placeholder="Subscription price"
                />
                {errors.price && (
                  <span className="input-error">{errors.price}</span>
                )}
              </div>

              <Label className="col-form-label">Most Popular</Label>
              <div className="input-div">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked ? 1 : 0)}
                />
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Subscription`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this subscription?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Subscription;
