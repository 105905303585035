import React, { useEffect, useState } from "react";
import { getEmployeeList } from "../../services/EmployeeService";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

const tableLengthList = ConfigDB.data.dataTableLength;

const Driver = ({ onSelectionChange }) => {
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setSelectedDrivers((prevSelected) => {
      const newSelection = isChecked
        ? [...prevSelected, item.id]
        : prevSelected.filter((id) => id !== item.id);
      onSelectionChange(newSelection);
      return newSelection;
    });
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelection = isChecked
      ? driverList.data.map((item) => item.id)
      : [];
    setSelectedDrivers(newSelection);
    onSelectionChange(newSelection);
  };

  useEffect(() => {
    setIsLoading(true);
    getEmployeeList(params)
      .then((response) => {
        setDriverList(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(Utils.getErrorMessage(err));
      });
  }, [params]);

  return (
    <div>
      <div className="container-event">
        <div className="page-table-row vehicle-td">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={
                      driverList?.data?.length > 0 &&
                      selectedDrivers.length === driverList?.data?.length
                    }
                  />
                </th>
                <th scope="col">Users</th>
                <th scope="col">Employee Email</th>
                <th scope="col">Employee Contact</th>
              </tr>
            </thead>
            {driverList?.data?.map((item, i) => (
              <tr key={i}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedDrivers.includes(item.id)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.number}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Driver;
