import React from "react";

const TermsAndCondition = () => {
  return (
    <div>
      <div>
        <div>
          <section className="page-title page-title-mini">
            <div className="container">
              <div className="page-title-row">
                <div className="page-title-content">
                  <h1>Terms & Condition</h1>
                </div>
              </div>
            </div>
          </section>

          <section className="team-area team-area-custom">
            <div className="container">
              <div className="section-title">
                <h2>
                  Welcome to InRoute, a GPS-based vehicle tracking system. By
                  accessing and using our services, you agree to comply with
                  these Terms and Conditions. If you do not agree with any part
                  of these terms, you should refrain from using the service.
                </h2>
              </div>

              <div className="section-content">
                <h3>
                  Services <span>Provided</span>
                </h3>
                <p style={{ fontWeight: "200" }}>
                  InRoute offers real-time vehicle tracking, route optimization,
                  and fleet management solutions. The system relies on GPS and
                  other tracking technologies to provide accurate location data.
                </p>
                <h3>
                  User <span>Responsibilities</span>
                </h3>
                <p style={{ fontWeight: "200" }}>
                  Users must ensure that all information provided to InRoute is
                  accurate and up to date.
                </p>

                <p style={{ fontWeight: "200" }}>
                  Users must not use the tracking system for unlawful purposes,
                  including but not limited to unauthorized surveillance.
                </p>
                <h3>
                  {" "}
                  Data Collection <span>and Privacy</span>
                </h3>
                <p style={{ fontWeight: "200" }}>
                  InRoute collects location and vehicle data to provide tracking
                  services. By using the system, users consent to this data
                  collection.
                </p>

                <p style={{ fontWeight: "200" }}>
                  We do not share personal data with third parties except as
                  required by law or with explicit user consent.
                </p>

                <p style={{ fontWeight: "200" }}>
                  Users should review our Privacy Policy for more details on
                  data handling.
                </p>
                <h3>
                  Service Availability <span>and Limitations</span>
                </h3>
                <p style={{ fontWeight: "200" }}>
                  While we strive to maintain uninterrupted service, InRoute is
                  not responsible for any downtime due to technical issues,
                  maintenance, or force majeure events.
                </p>

                <p style={{ fontWeight: "200" }}>
                  The accuracy of GPS data may be affected by environmental
                  factors, hardware limitations, or network connectivity issues.
                </p>
                <h3>
                  Modifications <span>to Terms</span>
                </h3>
                <p style={{ fontWeight: "200" }}>
                  InRoute reserves the right to update these Terms and
                  Conditions at any time. Users will be notified of significant
                  changes, and continued use of the service implies acceptance
                  of the revised terms.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
