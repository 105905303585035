import React, { useEffect, useRef, useState } from "react";

const DailyTachnoDetails = ({ TachnoDriverDetailsList }) => {
  const [hoveredInfo, setHoveredInfo] = useState(null);

  const svgContainerRef = useRef(null);

  const handleMouseEnter = (date) => {
    // const dayInfo = item?.days?.find((day) => day.date === date);
    // setHoveredInfo(dayInfo);
  };

  const handleMouseLeave = () => {
    setHoveredInfo(null);
  };

  useEffect(() => {
    if (svgContainerRef.current) {
      const rectElements = svgContainerRef.current.querySelectorAll("rect");

      rectElements.forEach((rect) => {
        rect.addEventListener("mouseenter", (e) => {
          handleMouseEnter(e.target);
        });

        rect.addEventListener("mouseleave", handleMouseLeave);
      });

      return () => {
        rectElements.forEach((rect) => {
          rect.removeEventListener("mouseenter", (e) => {
            handleMouseEnter(e.target);
          });

          rect.removeEventListener("mouseleave", handleMouseLeave);
        });
      };
    }
  }, [handleMouseEnter, handleMouseLeave]);

  return (
    <div>
      <div className="card card-primary general-ac tachnoCard">
        <p>
          {TachnoDriverDetailsList?.activityAnalysis?.weeks?.map((item) => {
            return (
              <>
                {item?.days?.map((items) => {
                  const fixedSvgString = items?.graph?.replace(/\\"/g, '"');

                  return (
                    <>
                      {items?.graph && (
                        <div>
                          <h4>{items.date}</h4>

                          <div
                            className="tab-pane fade show active"
                            id="home-tab-pane"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                            tabIndex={0}
                          >
                            <div className="tabContent">
                              <div className="driving">
                                <span />
                                <p>Driving({items.metrics.totalDriving})</p>
                              </div>
                              <div className="driving">
                                <span className="colorGreen" />
                                <p>Other Works ({items.metrics.totalWork})</p>
                              </div>
                              <div className="driving">
                                <span className="colorYellow" />
                                <p>
                                  Available ({items.metrics.totalAvailable})
                                </p>
                              </div>
                              <div className="driving">
                                <span className="colorGrey" />
                                <p>Break ({items.metrics.totalBreak})</p>
                              </div>
                              <div className="driving">
                                <span className="colorWhite" />
                                <p>Unknown ({items.metrics.totalUnknown})</p>
                              </div>
                              <div className="driving">
                                <span className="colorBlue" />
                                <p>
                                  Amplitude ({items.metrics.totalAmplitude})
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            className="svgSize"
                            dangerouslySetInnerHTML={{ __html: fixedSvgString }}
                            // ref={svgContainerRef}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            );
          })}
        </p>
      </div>
    </div>
  );
};

export default DailyTachnoDetails;
