import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddDriverEmergency } from "../../services/EmployeeService";

const EmergencyDriver = ({ setActiveTab, driverDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [professonalData, setProfessonalData] = useState({
    emergency_contact_name: "",
    emergency_contact_number: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  let DriverId = localStorage.getItem("driverId");

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      uuid: driverDetails?.uuid ? driverDetails?.uuid : DriverId,
      emergency_contact_name: professonalData?.emergency_contact_name,
      emergency_contact_number: professonalData?.emergency_contact_number,
    };

    modifyDriver(createAddDriverEmergency, data);
  };

  useEffect(() => {
    if (driverDetails) {
      setProfessonalData({
        emergency_contact_name: driverDetails?.emergency_contact_name,
        emergency_contact_number: driverDetails?.emergency_contact_number,
      });
    }
  }, [driverDetails]);

  const modifyDriver = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("personal_Info");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Emergency</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Emergency Name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.emergency_contact_name}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            emergency_contact_name: e.target.value,
                          });
                        }}
                        name={`emergency_contact_name`}
                        placeholder="Emergency Name"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Emergency Contact"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={professonalData.emergency_contact_number}
                        onChange={(e) => {
                          setProfessonalData({
                            ...professonalData,
                            emergency_contact_number: e.target.value,
                          });
                        }}
                        name={`emergency_contact_number`}
                        maxLength={9}
                        placeholder="Emergency Contact"
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmergencyDriver;
