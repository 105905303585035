import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import configDb, { ConfigDB } from "../../config";
import {
  createDepartment,
  getDepartmentList,
} from "../../services/ManagementService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const tableLengthList = configDb.data.dataTableLength;

const GeneralDepartment = ({ addDepatmentData, setAddDepartmentStatus }) => {
  const [vehicleData, setVehicleData] = useState({
    uuid: "",
    name: "",
    description: "",
    code: "",
    contact: "",
    phone: "",
    email: "",
  });

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(() => {
    if (addDepatmentData) {
      setVehicleData({
        uuid: addDepatmentData?.uuid,
        name: addDepatmentData?.name,
        description: addDepatmentData?.description,
        code: addDepatmentData?.code,
        contact: addDepatmentData?.contact,
        phone: addDepatmentData?.phone,
        email: addDepatmentData?.email,
      });
    }
  }, [addDepatmentData]);

  const handleGeneralForm = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;

    if (!vehicleData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let data = {
      name: vehicleData?.name,
      description: vehicleData?.description,
      code: vehicleData?.code,
      contact: vehicleData?.contact,
      phone: vehicleData?.phone,
      email: vehicleData?.email,
    };

    let updateData = {
      uuid: vehicleData?.uuid,
      name: vehicleData?.name,
      description: vehicleData?.description,
      code: vehicleData?.code,
      contact: vehicleData?.contact,
      phone: vehicleData?.phone,
      email: vehicleData?.email,
    };

    if (vehicleData?.uuid) {
      modifyprofileAccount(createDepartment, updateData);
    } else {
      modifyprofileAccount(createDepartment, data);
    }
  };

  const modifyprofileAccount = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        setAddDepartmentStatus(false);
        let data = params;

        getDepartmentList(data)
          .then((response) => {})
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleGeneralForm}>
              <FormGroup className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Name"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={vehicleData.name}
                      onChange={(e) => {
                        setVehicleData({
                          ...vehicleData,
                          name: e.target.value,
                        });
                      }}
                      name={`name`}
                      maxLength={50}
                      placeholder="Name"
                    />

                    {errors.name && (
                      <span className="input-error">{errors.name}</span>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Description"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Description"
                      name={`description`}
                      onChange={(e) => {
                        setVehicleData({
                          ...vehicleData,
                          description: e.target.value,
                        });
                      }}
                      value={vehicleData.description}
                    ></input>
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Code"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      type="text"
                      value={vehicleData.code}
                      onChange={(e) => {
                        setVehicleData({
                          ...vehicleData,
                          code: e.target.value,
                        });
                      }}
                      name={`code`}
                      maxLength={8}
                      placeholder="Code"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Contact Name"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Contact"
                      name={`contact`}
                      onChange={(e) => {
                        setVehicleData({
                          ...vehicleData,
                          contact: e.target.value,
                        });
                      }}
                      maxLength={20}
                      value={vehicleData.contact}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Phone"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Phone"
                      name={`phone`}
                      onChange={(e) => {
                        setVehicleData({
                          ...vehicleData,
                          phone: e.target.value,
                        });
                      }}
                      minLength={9}
                      maxLength={9}
                      value={vehicleData.phone}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setVehicleData({
                          ...vehicleData,
                          email: e.target.value,
                        });
                      }}
                      value={vehicleData.email}
                    ></input>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralDepartment;
