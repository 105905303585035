import React, { useState } from "react";
import ConfigDB from "../../config";
import CustomerList from "./CustomerList";
import TrackingOrderList from "./TrackingOrderList";

const Customer = () => {
  const [activeTab, setActiveTab] = useState("customer");
  const [activeSubTab, setActiveSubTab] = useState("department");

  const [refreshKey, setRefreshKey] = useState(0);

  const fetchUpdatedVehicles = () => {
    setRefreshKey((prevKey) => prevKey + 1); // Force re-render
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSubTabClick = (subTab) => {
    setActiveSubTab(subTab);
  };

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const isAdminRole = JsonParse?.user_role === ConfigDB.data.ROLE_ADMIN;

  return (
    <div>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Customer</h5>
        </div>
      </div>
      <div className="leaflet-control-management managementui-tab">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "customer" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("customer");
                }}
              >
                <span data-href="#tab-1">Customer</span>
              </li>
              <li
                className={`nav-link ${
                  activeTab === "trackingOrder" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("trackingOrder");
                }}
              >
                <span data-href="#tab-1">Tracking Order</span>
              </li>
            </ul>
          </nav>
          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "customer" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <CustomerList setRefreshKey={setRefreshKey} />
            </div>
            <div
              className={`tab-pane fade ${
                activeTab === "trackingOrder" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <TrackingOrderList setRefreshKey={setRefreshKey} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customer;
