// let environment = "production";
// let apiUrl = "http://192.168.4.18:9832/api/v1/";

// if (environment === "production") {
//   apiUrl = "https://admininroute.neutroveg.com/api/v1/";

// }

const environment = process.env.REACT_APP_ENV;
let apiUrl = process.env.REACT_APP_API_URL_LOCAL;
let stripePublishKey =
  "pk_test_51P11ahCSIJAsscJW11kInQrq4Y9wypcjpVJ3lJgFKY2OBliq7JmPWRpNmw4nbbfkvLmi99zeKhXgdjklkl5yO3BT00QQSlutEc";
let stripeScreteKey =
  "sk_test_51P11ahCSIJAsscJWS9f0dd0Oy1lK8SClNG3oyafIOvhvUswzy1iDQiUiDo09lRdamE0oJfwP7ipNQgOEWhnL5Qzg00CwJh9V7H";

if (environment === "production") {
  apiUrl = process.env.REACT_APP_API_URL;
  stripeScreteKey =
    "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";
  stripePublishKey =
    "pk_live_51P11ahCSIJAsscJWK0SfnAJynNfM1T99Qogld19QzjOJz6ZwWHVjRQL37LL9dCrOpNTQUMCZYVR1dObGHvAjHXFe00Qt95diOl";
}
let languageList = ["EN", "GU", "FR", "AR"];

export class ConfigDB {
  static data = {
    app_name: "In-Route",
    // api_url: process.env.REACT_APP_API_URL,
    // stripe_publish_key: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
    api_url: apiUrl,
    stripe_publish_key: stripePublishKey,
    stripe_screte_key: stripeScreteKey,

    login_data: "@secure.@data",
    strongPassword: {
      minLength: 6,
      minLowercase: 1,
      minUppercase: 1,

      minNumbers: 1,
      minSymbols: 1,
    },
    dataTableLength: [10, 25, 75, 100],
    ROLE_ADMIN: 1,
    ROLE_DRIVER: 4,
    ROLE_COMPANY: 2,
    GOOGLE_MAP_KEY: "AIzaSyB3iQbwmhRJeAZ53jGXTfCDV8imGBtGFDs",
    // GOOGLE_MAP_KEY: "AIzaSyBmy7N0jKQLcRLAsCYGXwdrt1kxbE3fmxc",
    SUBSCRIPTION_TYPE_LIST: [
      { value: 1, label: "Month" },
      { value: 2, label: "Year" },
    ],
    LANGUAGE_LIST: languageList,
  };
}

export default ConfigDB;
