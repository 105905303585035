import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ConfigDB from "../../config";
import { getTripDetails } from "../../services/TripService";
import { toast } from "react-toastify";
import Utils from "../../utils";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

function TripUpdate(props) {
  const state = useLocation();

  let uuid = state.state;
  const [tripDetails, setTripDetails] = useState([]);

  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const [clickCount, setClickCount] = useState(0);

  const mapRef = useRef();

  const panTo = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setOrigin({ lat, lng, placeName });
  }, []);

  const panToDestination = useCallback(async ({ lat, lng, zoom }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
    );
    const data = await response.json();
    const placeName = data.results[0]?.formatted_address || "";
    setDestination({ lat, lng, placeName });
  }, []);

  const panToStop = useCallback(
    async ({ lat, lng, zoom }) => {
      if (mapRef.current) {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
      }
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ConfigDB.data.GOOGLE_MAP_KEY}`
      );
      const data = await response.json();
      const placeName = data.results[0]?.formatted_address || "";
      setWaypoints((prevWaypoints) => [
        ...prevWaypoints,
        { lat, lng, placeName, time: new Date() },
      ]);
    },
    [waypoints]
  );

  const handleButtonClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  const renderSearchDestination = () => {
    const searchComponents = [];
    for (let i = 0; i < clickCount; i++) {
      searchComponents.push(
        <SearchStop key={i} panToStop={panToStop} waypoints={waypoints} />
      );
    }
    return searchComponents;
  };

  useEffect(function () {
    let data = {
      uuid: uuid,
    };
    getTripDetails(data)
      .then((response) => {
        setTripDetails(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  return (
    <>
      <div className="signup-content mx-auto" style={{ marginTop: "120px" }}>
        <form>
          <div>
            <div className="container">
              <div className="row" style={{ marginTop: "10px" }}>
                {/* <div className="col">
                            <div>
                              <div style={{ marginBottom: "4px" }}>
                                <i className="bi bi-person me-2"></i>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Driver
                                </span>
                              </div>
                              <Select
                                options={DriverList}
                                value={DriverList.find(
                                  (option) => option.value == driver
                                )}
                                onChange={(selectedOption) =>
                                  setDriver((prevData) => ({
                                    ...prevData,
                                    driverId: selectedOption.value,
                                  }))
                                }
                                placeholder={"Select Driver"}
                              />
                            </div>
                          </div> */}
                {/* <div className="col">
                            <div>
                              <div style={{ marginBottom: "4px" }}>
                                <i className="bi bi-truck me-2"></i>
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  Vehicle
                                </span>
                              </div>
                              <Select
                                options={vehicleList}
                                value={vehicleList?.find(
                                  (option) => option.value == vehicle
                                )}
                                onChange={(selectedOption) =>
                                  setVehicle((prevData) => ({
                                    ...prevData,
                                    vehicleId: selectedOption.value,
                                  }))
                                }
                                placeholder={"Select Vehicle"}
                              />
                            </div>
                          </div> */}
              </div>
            </div>

            <div className="row" style={{ padding: "25px" }}>
              <div className="col">
                <div>
                  <Search panTo={panTo} tripDetails={tripDetails} />
                </div>
              </div>
              <div className="col">
                <div>
                  <SearchDestination
                    panToDestination={panToDestination}
                    tripDetails={tripDetails}
                  />
                </div>
              </div>
            </div>

            <div className="row" style={{ padding: "25px" }}>
              <div className="col">
                <div>
                  <div
                    style={{
                      margin: "10px",
                      marginTop: "-15px",
                      marginTop: "8px",
                    }}
                  >
                    <i className="bi bi-geo-alt me-2"></i>
                    <button
                      onClick={handleButtonClick}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                    >
                      Add Destination
                    </button>
                  </div>
                  {renderSearchDestination()}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: "-195px",
            }}
          >
            <Button
              color="success"
              type={`submit`}
              className={`btn-square`}
            >{`Trip Updated`}</Button>
          </div>
        </form>
        {/* <div className="col-xl-12 mt-3 d-flex justify-content-between align-items-center">
              <NavLink
                to={`/company`}
                role={`button`}
                end
                className={`btn btn-dark me-2`}
              >{`Back`}</NavLink>
            </div> */}
      </div>
    </>
  );
}

const Search = ({ panTo, setOrigin, tripDetails }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  useEffect(() => {
    if (tripDetails && tripDetails.source_place) {
      setValue(tripDetails.source_place);
    }
  }, [tripDetails]);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });

      setOrigin({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup style={{ marginBottom: "0rem !important" }}>
          <i className="bi bi-geo me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>Source</Label>
          <Input
            type="text"
            value={value}
            onChange={handleInput}
            placeholder="Search your location"
            style={{
              background: "#ffff",
              padding: "6px 20px",
              borderRadius: "9px",
              border: "solid #D5DEE2 1px",
              marginBottom: "12px",
              fontSize: "14px",
              lineHeight: "24px",
            }}
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchDestination = ({
  panToDestination,
  setDestination,
  tripDetails,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (tripDetails && tripDetails.destination_place) {
      setValue(tripDetails.destination_place);
    }
  }, [tripDetails]);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panToDestination({ lat, lng });
      setDestination({ lat, lng, time: new Date() });
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup
          style={{
            marginBottom: "0rem !important",
            marginTop: "-37px",
            width: "315px",
          }}
        >
          <i className="bi bi-geo-alt me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>
            Destination
          </Label>

          <Input
            type="text"
            value={value}
            onChange={handleInput}
            // disabled={!ready}
            style={{
              background: "#ffff",
              padding: "6px 20px",
              borderRadius: "9px",
              border: "solid #D5DEE2 1px",
              marginBottom: "12px",
              fontSize: "14px",
              lineHeight: "24px",
            }}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

const SearchStop = ({ panToStop, setWaypoints, waypoints }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 23.02436178148717, lng: () => 72.570038014801548 },
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panToStop({ lat, lng });
      setWaypoints([
        ...waypoints,
        { lat, lng, time: new Date(), placeName: address },
      ]);
    } catch (error) {}
  };

  return (
    <div className="search">
      <Form>
        <FormGroup
          style={{
            padding: "10px",
            marginBottom: "0rem !important",
            marginTop: "-20px",
          }}
        >
          <i className="bi bi-geo-alt me-2"></i>
          <Label style={{ fontSize: "16px", fontWeight: "600" }}>Stop</Label>

          <Input
            type="text"
            value={value}
            onChange={handleInput}
            style={{
              background: "#ffff",
              padding: "6px 20px",
              borderRadius: "9px",
              border: "solid #D5DEE2 1px",
              marginBottom: "12px",
              fontSize: "14px",
              lineHeight: "24px",
            }}
            placeholder="Search your location"
          />
        </FormGroup>
      </Form>
      {status === "OK" && (
        <ListGroup>
          {data.map(({ id, description }) => (
            <ListGroupItem
              key={id}
              action
              onClick={() => handleSelect(description)}
            >
              {description}
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default TripUpdate;
