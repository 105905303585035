import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import ConfigDB from "../../config";
import Select from "react-select";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddDriverStatus } from "../../services/EmployeeService";

const DriverStatusType = [
  {
    value: "1",
    label: "Available",
  },
  { value: "2", label: "Fired" },
  { value: "3", label: "Holidays" },
];

const DriverStatus = ({ setActiveTab, driverDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [tachnographData, setTachnographData] = useState({
    status: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  let DriverId = localStorage.getItem("driverId");

  useEffect(() => {
    if (driverDetails) {
      setTachnographData({
        status: driverDetails?.status,
      });
    }
  }, [driverDetails]);

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      uuid: driverDetails?.uuid ? driverDetails?.uuid : DriverId,
      status: tachnographData.status,
    };

    modifyDriver(createAddDriverStatus, data);
  };

  const modifyDriver = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("privacyMode");

        localStorage.removeItem("driverId");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const SelctedDriverType = DriverStatusType.find(
    (option) => option.value === JSON.stringify(tachnographData.status)
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>Driver Status</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Driver Status"}</Label>
                    <div className="input-div">
                      <Select
                        options={DriverStatusType}
                        value={SelctedDriverType}
                        onChange={(selectedOption) =>
                          setTachnographData((prevData) => ({
                            ...prevData,
                            status: selectedOption.value,
                          }))
                        }
                        placeholder={"Driver Status"}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverStatus;
