import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { createAccountGeneral } from "../../services/ManagementService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import * as validator from "validator";

const timeFormateType = [
  { value: "1", label: "1D 12:47" },
  { value: "2", label: "36:47" },
];

const speedType = [
  { value: "1", label: "Km,Km/h" },
  { value: "2", label: "mi, mph" },
];

const fuelUnit = [
  { value: "1", label: "Liter" },
  { value: "2", label: "gallon(US)" },
  { value: "3", label: "gallon(imperial)" },
];

const fuelConsumptionUnit = [
  { value: "1", label: "L/100km" },
  { value: "2", label: "mpg(imperial)" },
  { value: "3", label: "km/L" },
  { value: "4", label: "mpg(US)" },
  { value: "5", label: "km/gal(US)" },
];

const electricConsumptionUnit = [
  { value: "1", label: "kWH/100km" },
  { value: "2", label: "Wh/mi" },
];

const pressureUnit = [
  { value: "1", label: "Kpa" },
  { value: "2", label: "Bar" },
  { value: "3", label: "Psi" },
];

const GeneralAccount = ({ setActiveTab, AccountDetails }) => {
  const [AccountGeneralData, setAccountGeneralData] = useState({
    contact: "",
    time_format: "",
    speed_unit: "",
    fuel_unit: "",
    fuel_consumption_unit: "",
    electric_consumption_unit: "",
    pressure_unit: "",
    default_period: 0,
    companyEmail: "",
  });

  let errorsObj = { contact: "" };
  const [errors, setErrors] = useState(errorsObj);

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setAccountGeneralData({
      ...AccountGeneralData,
      default_period: newValue,
    });
  };

  useEffect(() => {
    if (AccountDetails) {
      setAccountGeneralData({
        contact: AccountDetails?.contact,
        time_format: AccountDetails?.time_format,
        speed_unit: AccountDetails?.speed_unit,
        fuel_unit: AccountDetails?.fuel_unit,
        fuel_consumption_unit: AccountDetails?.fuel_consumption_unit,
        electric_consumption_unit: AccountDetails?.electric_consumption_unit,
        pressure_unit: AccountDetails?.pressure_unit,
        default_period: AccountDetails?.default_period,
        companyEmail: AccountDetails?.company_email,
      });
    }
  }, [AccountDetails]);

  const handleGeneralForm = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    if (!AccountGeneralData.contact) {
      errorObj.contact = "Contact number is required";
      error = true;
    } else if (!validator.isMobilePhone(AccountGeneralData.contact)) {
      errorObj.contact = "Invalid contact number";
      error = true;
    } else if (
      AccountGeneralData.contact &&
      AccountGeneralData.contact.length !== 9
    ) {
      errorObj.contact = "Invalid contact number";
      error = true;
    }

    setErrors(errorObj);

    if (error) return;

    let data = {
      contact: AccountGeneralData?.contact,
      time_format: AccountGeneralData?.time_format,
      speed_unit: AccountGeneralData?.speed_unit,
      fuel_unit: AccountGeneralData?.fuel_unit,
      fuel_consumption_unit: AccountGeneralData?.fuel_consumption_unit,
      electric_consumption_unit: AccountGeneralData?.electric_consumption_unit,
      pressure_unit: AccountGeneralData?.pressure_unit,
      default_period: AccountGeneralData?.default_period,
      companyEmail: AccountGeneralData?.companyEmail,
    };

    let UpdateData = {
      uuid: AccountDetails?.uuid,
      contact: AccountGeneralData?.contact,
      time_format: AccountGeneralData?.time_format,
      speed_unit: AccountGeneralData?.speed_unit,
      fuel_unit: AccountGeneralData?.fuel_unit,
      fuel_consumption_unit: AccountGeneralData?.fuel_consumption_unit,
      electric_consumption_unit: AccountGeneralData?.electric_consumption_unit,
      pressure_unit: AccountGeneralData?.pressure_unit,
      default_period: AccountGeneralData?.default_period,
      companyEmail: AccountGeneralData?.companyEmail,
    };
    if (AccountDetails?.uuid) {
      modifyprofileAccount(createAccountGeneral, UpdateData);
    } else {
      modifyprofileAccount(createAccountGeneral, data);
    }
  };

  const modifyprofileAccount = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        setActiveTab("profile");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleGeneralForm}>
              <FormGroup>
                <div className="row">
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"E-mail list for alarm sending and auto reports"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={AccountGeneralData.companyEmail}
                        onChange={(e) => {
                          setAccountGeneralData({
                            ...AccountGeneralData,
                            companyEmail: e.target.value,
                          });
                        }}
                        name={`companyEmail`}
                        placeholder="E-mail list for alarm sending and auto reports"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Mobile phone list for alarm sending"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="tel"
                        value={AccountGeneralData.contact}
                        onChange={(e) => {
                          setAccountGeneralData({
                            ...AccountGeneralData,
                            contact: e.target.value,
                          });
                        }}
                        name={`contact`}
                        placeholder="Mobile phone list for alarm sending"
                        maxLength={9}
                      />
                    </div>
                    {errors.contact && (
                      <span className="input-error">{errors.contact}</span>
                    )}
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Time format (duration)"}
                    </Label>

                    <Select
                      options={timeFormateType}
                      value={timeFormateType.find(
                        (option) =>
                          option.value === AccountGeneralData.time_format
                      )}
                      onChange={(selectedOption) =>
                        setAccountGeneralData((prevData) => ({
                          ...prevData,
                          time_format: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Time format (duration)"}
                    />
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Preferred mileage and speed units"}
                    </Label>

                    <Select
                      options={speedType}
                      value={speedType.find(
                        (option) =>
                          option.value === AccountGeneralData.speed_unit
                      )}
                      onChange={(selectedOption) =>
                        setAccountGeneralData((prevData) => ({
                          ...prevData,
                          speed_unit: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Preferred mileage and speed units"}
                    />
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Preferred fuel units"}
                    </Label>

                    <Select
                      options={fuelUnit}
                      value={fuelUnit.find(
                        (option) =>
                          option.value === AccountGeneralData.fuel_unit
                      )}
                      onChange={(selectedOption) =>
                        setAccountGeneralData((prevData) => ({
                          ...prevData,
                          fuel_unit: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Preferred fuel units"}
                    />
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Preferred fuel consumption units"}
                    </Label>

                    <Select
                      options={fuelConsumptionUnit}
                      value={fuelConsumptionUnit.find(
                        (option) =>
                          option.value ===
                          AccountGeneralData.fuel_consumption_unit
                      )}
                      onChange={(selectedOption) =>
                        setAccountGeneralData((prevData) => ({
                          ...prevData,
                          fuel_consumption_unit: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Preferred fuel consumption units"}
                    />
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Preferred electric consumption units"}
                    </Label>

                    <Select
                      options={electricConsumptionUnit}
                      value={electricConsumptionUnit.find(
                        (option) =>
                          option.value ===
                          AccountGeneralData.electric_consumption_unit
                      )}
                      onChange={(selectedOption) =>
                        setAccountGeneralData((prevData) => ({
                          ...prevData,
                          electric_consumption_unit: selectedOption.value,
                        }))
                      }
                      placeholder={
                        "Select Preferred electric consumption units"
                      }
                    />
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Preferred pressure unit"}
                    </Label>

                    <Select
                      options={pressureUnit}
                      value={pressureUnit.find(
                        (option) =>
                          option.value === AccountGeneralData.pressure_unit
                      )}
                      onChange={(selectedOption) =>
                        setAccountGeneralData((prevData) => ({
                          ...prevData,
                          pressure_unit: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Preferred pressure unit"}
                    />
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Shorter default period
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="default_period"
                          checked={AccountGeneralData.default_period}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="default_period"
                        >
                          Shorter default period
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralAccount;
