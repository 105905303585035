import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import { createAccountCanbus } from "../../services/ManagementService";

const tableLengthList = configDb.data.dataTableLength;

const Canbus = ({ setActiveTab, AccountDetails }) => {
  const [canBusData, setCanBusData] = useState({
    avg_fuel_cost_per_liter: "",
    consider_na_rest: 0,
  });

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  let errorsObj = { avg_fuel_cost_per_liter: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (AccountDetails) {
      setCanBusData({
        avg_fuel_cost_per_liter:
          AccountDetails?.canbus?.avg_fuel_cost_per_liter,
        consider_na_rest: AccountDetails?.canbus?.consider_na_rest,
      });
    }
  }, [AccountDetails]);

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    const decimalCommaRegex = /^[0-9]+([,][0-9]+)?$/;

    if (!decimalCommaRegex.test(canBusData.avg_fuel_cost_per_liter)) {
      errorObj.avg_fuel_cost_per_liter =
        "Only numbers (0-9) and decimals (comma) allowed";
      error = true;
    } else {
      errorObj.avg_fuel_cost_per_liter = "";
    }

    setErrors(errorObj);

    if (error) return;

    let data = {
      avg_fuel_cost_per_liter: canBusData?.avg_fuel_cost_per_liter,
      consider_na_rest: canBusData?.consider_na_rest,
    };

    let updateData = {
      uuid: AccountDetails?.canbus?.uuid,
      avg_fuel_cost_per_liter: canBusData?.avg_fuel_cost_per_liter,
      consider_na_rest: canBusData?.consider_na_rest,
    };

    if (AccountDetails?.canbus?.uuid) {
      modifymanagament(createAccountCanbus, updateData);
    } else {
      modifymanagament(createAccountCanbus, data);
    }
  };

  const handleCheckboxChangeAllowDriving = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setCanBusData({
      ...canBusData,
      consider_na_rest: newValue,
    });
  };

  const modifymanagament = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);

        setActiveTab("automationRule");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Average fuel cost per liter(€)"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={canBusData.avg_fuel_cost_per_liter}
                        onChange={(e) => {
                          setCanBusData({
                            ...canBusData,
                            avg_fuel_cost_per_liter: e.target.value,
                          });
                        }}
                        name={`avg_fuel_cost_per_liter`}
                        maxLength={20}
                        placeholder="Average fuel cost per liter(€)"
                      />
                    </div>

                    {errors.avg_fuel_cost_per_liter && (
                      <span className="input-error">
                        {errors.avg_fuel_cost_per_liter}
                      </span>
                    )}
                  </div>

                  <div className="col-md-6">
                    <Label className="col-form-label">
                      Consider N/A (Not Available) as consider na rest
                    </Label>
                    <div className="input-div" style={{ marginTop: "10px" }}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="consider_na_rest"
                          checked={canBusData.consider_na_rest}
                          onChange={handleCheckboxChangeAllowDriving}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="consider_na_rest"
                        >
                          Consider N/A (Not Available) as consider na rest
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Canbus;
