import React, { useEffect, useState } from "react";

import validator from "validator";
import configDb, { ConfigDB } from "../config";
import {
  actionChangeGeneral,
  actionChangeImage,
  actionChangePassword,
  actionDetail,
  SubScriptionCancel,
} from "../services/ProfileService";
import { toast } from "react-toastify";
import Utils from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { authActions, subscriptionPlanActions } from "../store";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getSubscriptionList } from "../services/SubscriptionService";
import { useNavigate } from "react-router-dom";

function Profile() {
  const auth = useSelector((x) => x.auth.value);
  const dispatch = useDispatch();
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const navigate = useNavigate();
  const subscriptionTypeList = ConfigDB.data.SUBSCRIPTION_TYPE_LIST;

  const [isPasswordButtonLoad, setIsPasswordButtonLoad] = useState(false);
  const [isProfileButtonLoad, setIsProfileButtonLoad] = useState(false);
  const [isGeneralButtonLoad, setIsGeneralButtonLoad] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const errorsGeneralObj = { name: "", email: "", number: "" };
  const [errorsGeneral, setErrorsGeneral] = useState(errorsGeneralObj);
  const [activeTab, setActiveTab] = useState("general");
  const [SubscriptionList, setSubscriptionList] = useState([]);

  let errorsPicObj = {
    image: "",
  };
  const [errorsPic, setErrorsPic] = useState(errorsPicObj);

  const errorsObj = { newPassword: "", confirmPassword: "", oldPassword: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isChecked, setIsChecked] = useState(false); // false = Monthly, true = Yearly
  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false);
  const [isChangePlanOpen, setIsChangePlanOpen] = useState(false); // Controls modal visibility

  const filteredSubscriptions = SubscriptionList?.filter(
    (item) => item.type === (isChecked ? 2 : 1)
  );

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
    };
    getSubscriptionList(data)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          setSubscriptionList(response.data.data);
        }
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const onGetStart = async (item) => {
    await dispatch(subscriptionPlanActions.subscriptionPlan(item.id));
    navigate("/payment");
  };

  const handleChangeSubscriptionCancelPlan = () => {
    setSubscriptionCancelModal(true);
  };

  const handleChangeSubscriptionCancelPlanClose = () => {
    setSubscriptionCancelModal(false);
  };

  const onCancelSubPlan = (e) => {
    e.preventDefault();
    let data = { company_subscription_id: JsonParse?.subscription_data?.id };
    SubScriptionCancel(data)
      .then((response) => {
        toast.success(response.data.message);
        setSubscriptionCancelModal(false);
        navigate("/dashboard");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(function () {
    actionDetail()
      .then((response) => {
        let data = response?.data?.data;
        if (data) {
          setPreviewUrl(data?.profile);
          setName(data?.name);
          setEmail(data?.email);
          setNumber(data?.number);
        }
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrl(imageUrl);
    }
  };

  const validateImage = (image) => {
    if (!image) {
      return false;
    }
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 2 * 1024 * 1024; // 2MB
    return allowedTypes.includes(image.type) && image.size <= maxSize;
  };

  const onChangePasswordSubmit = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!newPassword) {
      errorObj.newPassword = "New Password is required";
      error = true;
    } else if (
      !validator.isStrongPassword(newPassword, configDb.data.strongPassword)
    ) {
      errorObj.newPassword =
        "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
      error = true;
    }

    if (!oldPassword) {
      errorObj.oldPassword = "Old Password is required";
      error = true;
    } else if (
      !validator.isStrongPassword(oldPassword, configDb.data.strongPassword)
    ) {
      errorObj.oldPassword =
        "Your password must be more than 6 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.";
      error = true;
    }

    if (oldPassword === newPassword) {
      errorObj.newPassword = "New Password should not be same as Old Password";
      error = true;
    }

    if (!confirmPassword) {
      errorObj.confirmPassword = "Confirm password is required";
      error = true;
    } else if (newPassword !== confirmPassword) {
      errorObj.confirmPassword =
        "New password and confirm password does not match";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    setIsPasswordButtonLoad(true);
    let data = { password: newPassword, oldPassword: oldPassword };
    actionChangePassword(data)
      .then((response) => {
        toast.success(response.data.message);
        setIsPasswordButtonLoad(false);
        setNewPassword("");
        setOldPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
        setIsPasswordButtonLoad(false);
      });
  };

  const onFormPictureSubmit = (e) => {
    e.preventDefault();
    let errorObjPic = { ...errorsPicObj };
    let errorPic = false;

    if (!image) {
      errorObjPic.image = "Image is required";
      errorPic = true;
    } else if (!validateImage(image)) {
      errorObjPic.image =
        "Invalid file. Please upload a valid image. Size must be < 2mb";
      errorPic = true;
    }

    setErrorsPic(errorObjPic);
    if (errorPic) return;

    setIsProfileButtonLoad(true);
    const formData = new FormData();
    formData.append("image", image);

    actionChangeImage(formData)
      .then((response) => {
        let userData = { ...auth, user_image: response.data.path };
        Utils.setDetail(userData);
        dispatch(authActions.login(userData));
        toast.success(response.data.message);
        setIsProfileButtonLoad(false);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
        setIsProfileButtonLoad(false);
      });
  };

  const onGeneralSave = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsGeneralObj };
    let error = false;

    if (!name) {
      errorObj.name = "Name is required";
      error = true;
    }

    if (!email) {
      errorObj.email = "Email is required";
      error = true;
    } else if (!validator.isEmail(email)) {
      errorObj.email = "Please enter valid email address";
      error = true;
    }

    if (!number) {
      errorObj.number = "Contact is required";
      error = true;
    } else if (number.length < 9) {
      errorObj.number = "Contact must be at least 9 digits";
      error = true;
    } else if (!validator.isMobilePhone(number, "pt-PT")) {
      errorObj.number = "Invalid Portugal contact";
      error = true;
    }

    setErrorsGeneral(errorObj);
    if (error) return;

    setIsGeneralButtonLoad(true);
    let data = {
      name: name,
      email: email,
      number: number,
    };

    actionChangeGeneral(data)
      .then((response) => {
        toast.success(response.data.message);
        setIsGeneralButtonLoad(false);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
        setIsGeneralButtonLoad(false);
      });
  };

  return (
    <>
      <div className="profle-header">
        <h4>USER PROFILE</h4>
      </div>
      <div className="container-event-profle profile-container">
        <ul className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
          <li
            className={`nav-link ${activeTab === "general" ? "active" : ""}`}
            role="tab"
            onClick={(e) => {
              e.preventDefault();
              handleTabClick("general");
            }}
          >
            <a href="#">General</a>
          </li>

          <li
            className={`nav-link ${activeTab === "profile" ? "active" : ""}`}
            role="tab"
            onClick={(e) => {
              e.preventDefault();
              handleTabClick("profile");
            }}
          >
            <a href="#">Profile</a>
          </li>

          <li
            className={`nav-link ${
              activeTab === "changePassword" ? "active" : ""
            }`}
            role="tab"
            onClick={(e) => {
              e.preventDefault();
              handleTabClick("changePassword");
            }}
          >
            <a href="#">Change Password</a>
          </li>
          {JsonParse?.user_role === 2 && (
            <li
              className={`nav-link ${
                activeTab === "subscriptionPlan" ? "active" : ""
              }`}
              role="tab"
              onClick={(e) => {
                e.preventDefault();
                handleTabClick("subscriptionPlan");
              }}
            >
              <a href="#">Subscription Plan</a>
            </li>
          )}
        </ul>
        <div className="profle-block tab-content" id="nav-tabContent">
          <div
            className={`tab-pane fade ${
              activeTab === "general" ? "show active" : ""
            }`}
            role="tabpanel"
          >
            <div className="row">
              <div className="col-lg-12 mb-4 mb-sm-5">
                <div className="card">
                  <div className="card-body" style={{ padding: "0px" }}>
                    <div className="row">
                      <div className="col-sm-4 align-content-center">
                        <h6 className="mb-3">Name</h6>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {errorsGeneral.name && (
                          <span className="input-error">
                            {errorsGeneral.name}
                          </span>
                        )}
                      </div>

                      {/* Add other fields here */}
                      <div className="col-sm-4 align-content-center">
                        <h6 className="mb-3">Email</h6>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {errorsGeneral.email && (
                          <span className="input-error">
                            {errorsGeneral.email}
                          </span>
                        )}
                      </div>

                      <div className="col-sm-4 align-content-center">
                        <h6 className="mb-3">Contact</h6>

                        <input
                          type="number"
                          className="form-control"
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                          min={100000000}
                        />

                        {errorsGeneral.number && (
                          <span className="input-error">
                            {errorsGeneral.number}
                          </span>
                        )}
                      </div>

                      {/* Add other fields similarly */}

                      <div className="col-sm-10 mt-4">
                        {/*<input type="button"*/}
                        {/*onClick={onGeneralSave}*/}
                        {/*className="change-password"*/}
                        {/*value="Save Changes"/>*/}

                        {!isGeneralButtonLoad && (
                          <button
                            type="button"
                            onClick={onGeneralSave}
                            className="change-password"
                          >
                            <span>Save Changes</span>
                          </button>
                        )}

                        {isGeneralButtonLoad && (
                          <button
                            type="button"
                            disabled={true}
                            className="change-password"
                          >
                            <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                            {`Loading`}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`tab-pane fade ${
              activeTab === "profile" ? "show active" : ""
            }`}
            role="tabpanel"
          >
            <div className="row">
              <div className="col-lg-3">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      <h3>Edit Profile</h3>
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                          <label htmlFor="imageUpload"></label>
                        </div>
                        <div className="avatar-preview profile-user">
                          <img src={previewUrl} className="preview-image" />
                        </div>
                      </div>
                      {errorsPic.image && (
                        <span className="input-error">{errorsPic.image}</span>
                      )}
                      {/*<a className="change-password" href="#" onClick={onFormPictureSubmit}>*/}
                      {/*Save Profile*/}
                      {/*</a>*/}
                      {!isProfileButtonLoad && (
                        <button
                          type="button"
                          onClick={onFormPictureSubmit}
                          className="change-password"
                        >
                          <span>Save Profile</span>
                        </button>
                      )}

                      {isProfileButtonLoad && (
                        <button
                          type="button"
                          disabled={true}
                          className="change-password"
                        >
                          <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                          {`Loading`}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`tab-pane fade ${
              activeTab === "changePassword" ? "show active" : ""
            }`}
            role="tabpanel"
          >
            <div className="cardStyle">
              <form method={`post`} onSubmit={onChangePasswordSubmit}>
                <h2 className="formTitle">Change Password</h2>
                <div className="inputDiv">
                  <label className="inputLabel">Old Password</label>
                  <input
                    className="form-control"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  {errors.oldPassword && (
                    <span className="input-error">{errors.oldPassword}</span>
                  )}
                </div>
                <div className="inputDiv">
                  <label className="inputLabel">New Password</label>
                  <input
                    className="form-control"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {errors.newPassword && (
                    <span className="input-error">{errors.newPassword}</span>
                  )}
                </div>
                <div className="inputDiv">
                  <label className="inputLabel">Confirm Password</label>
                  <input
                    className="form-control"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {errors.confirmPassword && (
                    <span className="input-error">
                      {errors.confirmPassword}
                    </span>
                  )}
                </div>
                <div className="buttonWrapper mt-4">
                  {!isPasswordButtonLoad && (
                    <button
                      type="submit"
                      id="submitButton"
                      className="change-password"
                    >
                      <span>Change Password</span>
                    </button>
                  )}

                  {isPasswordButtonLoad && (
                    <button
                      type="button"
                      disabled={true}
                      className="change-password"
                    >
                      <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                      {`Loading`}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {JsonParse?.user_role === 2 && (
            <div
              className={`tab-pane fade ${
                activeTab === "subscriptionPlan" ? "show active" : ""
              }`}
              role="tabpanel"
            >
              <div className="cardStyle">
                <form method={`post`} onSubmit={onChangePasswordSubmit}>
                  <h2 className="formTitle">Subscription Plan</h2>
                  <div className="row">
                    <div className="col-sm-4 align-content-center">
                      <div className="inputDiv">
                        <label className="inputLabel">Subscription Name</label>
                        <p>{JsonParse?.subscription_data?.subscription_name}</p>
                      </div>
                    </div>

                    <div className="col-sm-4 align-content-center">
                      <div className="inputDiv">
                        <label className="inputLabel">Subscription Price</label>
                        <p>
                          {JsonParse?.subscription_data?.subscription_price}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 align-content-center">
                      <div className="inputDiv">
                        <label className="inputLabel">Subscription Type</label>
                        <p>
                          {" "}
                          {subscriptionTypeList.find(
                            (type) =>
                              type.value ===
                              JsonParse?.subscription_data?.subscription_type
                          )?.label || "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4 align-content-center">
                      <div className="inputDiv">
                        <label className="inputLabel">Purchase Date</label>
                        <p>{JsonParse?.subscription_data?.cover_from}</p>
                      </div>
                    </div>

                    <div className="col-sm-4 align-content-center">
                      <div className="inputDiv">
                        <label className="inputLabel">Expire Date</label>
                        <p>{JsonParse?.subscription_data?.cover_till}</p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="buttonWrapper mt-4">
                        <button
                          type="button"
                          id="submitButton"
                          className="change-password"
                          onClick={() => setIsChangePlanOpen(true)}
                        >
                          <span>Change Plan</span>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="buttonWrapper mt-4">
                        <button
                          type="button"
                          id="submitButton"
                          className="change-password"
                          onClick={handleChangeSubscriptionCancelPlan}
                        >
                          <span>Cancel Plan</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

        {/* <Modal isOpen={subscriptionModal} className="modal-dialog modal-xl">
          <ModalBody>
            <button
              type="button"
              className="close close-button-modal"
              onClick={handleChangeSubscriptionPlanClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="form">
              <div className="heading mb-4">Subscription</div>

              <div className="heading mb-4 text-center">
                <div
                  className={`bootstrap-switch ${
                    isChecked ? "bootstrap-switch-on" : "bootstrap-switch-off"
                  }`}
                  style={{ marginLeft: "10px" }}
                >
                  <div
                    className="bootstrap-switch-container"
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    <span
                      className={`bootstrap-switch-handle-on ${
                        isChecked ? "bootstrap-switch-primary" : ""
                      }`}
                    >
                      Yearly
                    </span>
                    <span className="bootstrap-switch-label">&nbsp;</span>
                    <span
                      className={`bootstrap-switch-handle-off ${
                        !isChecked ? "bootstrap-switch-default" : ""
                      }`}
                    >
                      Monthly
                    </span>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>

              <div className="row">
                <div className="padding-bottom--24 mt-50">
                  <div className="container">
                    <div className="card-deck mb-3 card-deck-custome">
                      <div
                        className="card mb-4 box-shadow"
                        style={{ border: "transparent" }}
                      >
                        <div className="pricing">
                          {filteredSubscriptions?.length > 0 ? (
                            filteredSubscriptions?.map((item, index) => {
                              return (
                                <>
                                  <div className="plan popular" key={index}>
                                    {item.is_most_popular === 1 && (
                                      <span>Most Popular</span>
                                    )}
                                    <h4 className="font-weight-normal">
                                      {item.subscription}
                                    </h4>
                                    <h6 className="pricing-card-title text-center">
                                      <b>
                                        € {item.price}{" "}
                                        {isChecked ? "/ Year" : "/ Month"}
                                      </b>
                                    </h6>
                                    <div className="list-unstyled mt-3 mb-4">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item.subscription_desc,
                                        }}
                                      />{" "}
                                    </div>
                                    {item?.id ===
                                    Number(
                                      JsonParse?.subscription_data
                                        ?.subscription_id
                                    ) ? (
                                      <>
                                        <Button
                                          className="login-btn"
                                          type="button"
                                          // onClick={() => onGetStart(item)}
                                          // disabled={
                                          //   item?.id ===
                                          //   Number(
                                          //     JsonParse?.subscription_data
                                          //       ?.subscription_id
                                          //   )
                                          // }
                                          style={{
                                            height: "48px",
                                            backgroundColor: "gray",
                                          }}
                                        >
                                          <p>Active Plan</p>
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex justify-content-center">
                                          <Button
                                            className="login-btn"
                                            type="button"
                                            onClick={() => onGetStart(item)}
                                            disabled={
                                              item?.id ===
                                              Number(
                                                JsonParse?.subscription_data
                                                  ?.subscription_id
                                              )
                                            }
                                          >
                                            Upgrade
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <div className="pricing">
                              <div className="plan">
                                <p className="text-center">
                                  No plans available
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal> */}
        {isChangePlanOpen && (
          <div className="subscriptions-blox subscriptions-blox-custom subscriptions-blox-custom-2">
            <div className="heading  text-center">
              <div
                className={`bootstrap-switch  ${
                  isChecked ? "bootstrap-switch-on" : "bootstrap-switch-off"
                }`}
              >
                <div
                  className="bootstrap-switch-container"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  <span
                    className={`bootstrap-switch-handle-off ${
                      !isChecked ? "bootstrap-switch-default" : ""
                    }`}
                  >
                    Monthly
                  </span>
                  <span className="bootstrap-switch-label">&nbsp;</span>

                  <span
                    className={`bootstrap-switch-handle-on ${
                      isChecked ? "bootstrap-switch-primary" : ""
                    }`}
                  >
                    Yearly
                  </span>

                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    style={{ display: "none" }}
                  />
                </div>
              </div>

              <button
                type="button"
                className="close close-button-modal"
                onClick={() => setIsChangePlanOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="promos">
              {filteredSubscriptions?.length > 0 &&
                filteredSubscriptions?.map((item, index) => (
                  <>
                    <div
                      className={`promo ${
                        item.is_most_popular === 1 ? "scale promo-active" : ""
                      }`}
                    >
                      <div className="card">
                        <div className="card-header">
                          {item.is_most_popular === 1 && (
                            <span>Most Popular</span>
                          )}

                          <div className="text-center">
                            <span className="price">
                              {" "}
                              € {item.price} {isChecked ? "/ Year" : "/ Month"}{" "}
                            </span>
                          </div>
                          <p>{item.subscription}</p>
                        </div>
                        <div className="card-body">
                          <div className="inner-body-card">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.subscription_desc,
                              }}
                            />{" "}
                          </div>
                          <div className="d-flex justify-content-center">
                            {item?.id ===
                            Number(
                              JsonParse?.subscription_data?.subscription_id
                            ) ? (
                              <>
                                <button className="buy buy-custom-btn active-plan-btn">
                                  Active Plan
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="buy buy-custom-btn"
                                  onClick={() => onGetStart(item)}
                                >
                                  Upgrade{" "}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
        )}

        <Modal
          isOpen={subscriptionCancelModal}
          className="modal-dialog modal-md"
        >
          <ModalHeader>Cancel Plan</ModalHeader>
          <ModalBody>Are you sure you want to cancel your plan ?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleChangeSubscriptionCancelPlanClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`button`}
              className={`btn-square`}
              onClick={onCancelSubPlan}
            >{`Yes`}</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Profile;
