import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import ConfigDB from "../../config";
import {
  actionUpdateSetting,
  getSettingDetails,
} from "../../services/SettingService";

const tableLengthList = ConfigDB.data.dataTableLength;

const Setting = () => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const [settingDetails, setsettingDetails] = useState([]);
  const [priceperdistance, setPriceperditance] = useState("");
  const [priceperhour, setPriceperhour] = useState("");

  let errorsObj = {
    priceperdistance: "",
    priceperhour: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [activeTab, setActiveTab] = useState("form");
  const navigate = useNavigate();
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  useEffect(
    function () {
      let data = params;
      getSettingDetails(data)
        .then((response) => {
          setsettingDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  useEffect(
    function () {
      if (settingDetails) {
        setPriceperditance(settingDetails[0]?.price_per_distance);
        setPriceperhour(settingDetails[0]?.price_per_hour);
      }
    },
    [settingDetails]
  );

  const onFormSubmit = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;

    if (!priceperdistance) {
      errorObj.priceperdistance = "Priceperdistance is required";
      error = true;
    }

    if (!priceperhour) {
      errorObj.priceperhour = "Priceperhour is required";
      error = true;
    }

    // const error = Object.keys(errorObj).length > 0;

    setErrors(errorObj);
    if (error) return;

    let data = {
      priceperdistance: priceperdistance,
      priceperhour: priceperhour,
      companyId: JsonParse.company_id,
    };
    let updatedata = {
      priceperdistance: priceperdistance,
      priceperhour: priceperhour,
      companyId: JsonParse.company_id,
      uuid: settingDetails[0]?.uuid,
    };

    if (settingDetails[0]?.uuid) {
      actionUpdateSetting(updatedata)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/setting");
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    } else {
      actionUpdateSetting(data)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/setting");
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    }

    // }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="employee-header setting-header d-none">
        <h4>SETTING FORM</h4>
      </div>
      <div className="container-event-profle setting-profle">
        <ul className="nav nav-tabs mb-3" id="nav-tab" role="tablist"></ul>
        <div className="profle-block tab-content" id="nav-tabContent">
          <div
            className={`tab-pane fade ${
              activeTab === "form" ? "show active" : ""
            }`}
            role="tabpanel"
          >
            <div className="row ">
              <div className="col-lg-12 ">
                <form method={`post`} onSubmit={onFormSubmit}>
                  <div className="card">
                    <div className="card-body" style={{ padding: "0" }}>
                      <div className="row">
                        <div className="col-xl-12">
                          <h3 className="title-setting">Distance Management</h3>
                        </div>
                        <div className="col-xl-6">
                          <label className="mb-2">Price For Distance</label>
                          <div className="mb-3">
                            <input
                              type="number"
                              id=""
                              className="form-control"
                              value={priceperdistance}
                              onChange={(e) =>
                                setPriceperditance(e.target.value)
                              }
                              placeholder="Price for distance"
                            />
                            {errors.priceperdistance && (
                              <span className="input-error">
                                {errors.priceperdistance}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <label className="mb-2">Price Per Hour</label>
                          <div className="mb-3">
                            <input
                              type="number"
                              id=""
                              className="form-control"
                              value={priceperhour}
                              onChange={(e) => setPriceperhour(e.target.value)}
                              placeholder="Price for hour"
                            />
                            {errors.priceperhour && (
                              <span className="input-error">
                                {errors.priceperhour}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-sm-10 p-0">
                          <button type="submit" className="change-password">
                            <span>Submit</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
