import React, { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ConfigDB from "../../config";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import { getVehicleList } from "../../services/VehicleService";
import {
  getFixedAllCostList,
  getVariableAllCostList,
} from "../../services/ManagementService";
import { actionDetailsCost } from "../../services/ReportsService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const DetailedCostReport = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const [dateRange, setDateRange] = useState([null, null]);

  const handleChange = (range) => {
    setDateRange(range);
  };
  const [detailsCostData, setDetailsCostData] = useState({
    fixed_type_id: "",
    vehicle_id: "",
    variable_type_id: "",
    show_data_group: 0,
  });

  const [modifyModal, setModifyModal] = useState(null);
  const [vehicleList, setVehicleList] = useState([]);
  const [fixedCosAlltList, setfixedAllCostList] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleSendEmailClose = () => {
    setModifyModal(false);
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setDetailsCostData({
      ...detailsCostData,
      show_data_group: newValue,
    });
  };

  useEffect(
    function () {
      let data = { ...params };
      getVehicleList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].vehicleName,
            });
          }

          setVehicleList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  useEffect(
    function () {
      let data = params;
      getFixedAllCostList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setfixedAllCostList(list);
        })
        .catch((err) => {});
    },
    [params]
  );

  const date = new Date(dateRange);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const handleDailyForm = (e) => {
    e.preventDefault();

    if (!dateRange) {
      toast.error("Please select date");
      return; // Stop further execution if no vehicle is selected
    }
    if (!detailsCostData.vehicle_id) {
      toast.error("Please select  vehicle.");
      return; // Stop further execution if no vehicle is selected
    }

    if (!detailsCostData.fixed_type_id) {
      toast.error("Please select cost type.");
      return; // Stop further execution if no vehicle is selected
    }

    let data = {
      vehicle_id: detailsCostData?.vehicle_id,
      date: formattedDate,
      cost_type_id: detailsCostData.fixed_type_id,
      show_data_group: detailsCostData.show_data_group,
    };

    modifyDailyReports(actionDetailsCost, data);
  };
  const modifyDailyReports = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        //toast.success(response.data.message);

        const dataToSend = {
          vehicle_id: detailsCostData?.vehicle_id,
          date: formattedDate,
          cost_type_id: detailsCostData.fixed_type_id,
          show_data_group: detailsCostData.show_data_group,
        };
        if (
          !response?.data ||
          response?.data?.data === null ||
          response?.data.data?.length <= 0
        ) {
          toast.error("No data found.");
          return;
        }
        localStorage.setItem(
          "DetailsCostReportData",
          JSON.stringify(dataToSend)
        );
        window.open("/detail-cost-reports", "_blank");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDailyForm}>
              <h6>DETAILED COSTS</h6>
              <h6>
                This report lists all costs, variable and fixed, occurred within
                a certain time period.
              </h6>
              <div className="d-flex justify-content-start">
                <div className="me-1">
                  <Button
                    color="success btn-info"
                    type={`submit`}
                    className={`btn-square`}
                  >{`Generate`}</Button>
                </div>
                {/* <div>
                  <Button
                    color="success btn-info"
                    type={`button`}
                    onClick={handleSendEmailOpen}
                    className={`btn-square`}
                  >{`Send Report By EMail`}</Button>
                </div> */}
              </div>
            </Form>
          </div>
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleDailyForm}>
              <h6>Configuration</h6>

              <div className="row">
                <div className="col-md-3">
                  <FormGroup>
                    <Label className="col-form-label">{"Period"}</Label>
                    <div className="input-group mb-3 datePicker">
                      <DatePicker
                        selected={dateRange}
                        onChange={handleChange}
                        dateFormat="yyyy-MM-dd"
                        calendarPosition="bottom-center"
                        placeholder="Select Date"
                        style={{
                          border: "1px solid #CCCCCC",
                          borderRadius: "5px",
                          height: "40px",
                          margin: "1px 0",
                          padding: "2px 5px",
                          paddingRight: "30px",
                          width: "100%",
                        }}
                      />
                      <span className="input-group-text calendarIcon">
                        <i className="bi bi-calendar-event"></i>
                      </span>
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-3">
                  <Label className="col-form-label">{"Vehicle"}</Label>
                  <div className="input-div">
                    <Select
                      options={vehicleList}
                      value={vehicleList?.find(
                        (option) =>
                          option.value ===
                          JSON.stringify(detailsCostData.vehicle_id)
                      )}
                      onChange={(selectedOption) =>
                        setDetailsCostData((prevData) => ({
                          ...prevData,
                          vehicle_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Vehicle"}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <Label className="col-form-label">{"Select Cost Type"}</Label>
                  <div className="input-div">
                    <Select
                      options={fixedCosAlltList}
                      value={fixedCosAlltList?.find(
                        (option) =>
                          option.value ===
                          JSON.stringify(detailsCostData.fixed_type_id)
                      )}
                      onChange={(selectedOption) =>
                        setDetailsCostData((prevData) => ({
                          ...prevData,
                          fixed_type_id: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Cost Type"}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <Label className="col-form-label">
                    Show data grouped by cost type
                  </Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="show_data_group"
                        checked={detailsCostData?.show_data_group === 1}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="show_data_group"
                      >
                        Show data grouped by cost type
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <Modal isOpen={modifyModal}>
        <ModalHeader>Send Report By Email </ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Label className="col-form-label">{"Subject"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      name={`subject`}
                      onChange={(e) => {
                        setDetailsCostData({
                          ...detailsCostData,
                          subject: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={detailsCostData.subject}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>

                <div className="col-md-6">
                  <Label className="col-form-label">{"Email"}</Label>
                  <div className="input-div">
                    <input
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      name={`email`}
                      onChange={(e) => {
                        setDetailsCostData({
                          ...detailsCostData,
                          email: e.target.value,
                        });
                      }}
                      // disabled={isApproveRejectLoad}
                      value={detailsCostData.email}
                      // onChange={(e) => setReason(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={handleSendEmailClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success btn-info"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DetailedCostReport;
