import React, { useState, useEffect } from "react";

import { Button, Form, FormGroup, Label } from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  createCustomer,
  getCustomerList,
} from "../../services/CustomerService";

const tableLengthList = configDb.data.dataTableLength;

const ContactCustomer = ({
  setAddCustomerStatus,
  customerDetails,
  setRefreshKey,
}) => {
  const [contactData, setContactData] = useState({
    uuid: "",
    title: "",
    office_phone: "",
    office_phone1: "",
    mobile_number: "",
    email: "",
    use_for_tracking: 0,
  });

  let errorsObj = {
    office_phone: "",
    mobile_number: "",
    email: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    device_imei: "",
  });

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);
    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "device_imei") {
      paramsObj.page = 1;
      paramsObj.device_imei = getValue(e);
    } else if (type === "vehicleClassId") {
      paramsObj.page = 1;
      paramsObj[type === "vehicleClassId" ? "vehicle_class" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };

  const handleCheckBox = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setContactData({
      ...contactData,
      use_for_tracking: newValue,
    });
  };

  useEffect(() => {
    if (customerDetails) {
      setContactData({
        uuid: customerDetails?.uuid,
        title: customerDetails?.title,
        office_phone: customerDetails?.office_phone,
        office_phone1: customerDetails?.office_phone1,
        mobile_number: customerDetails?.mobile_number,
        email: customerDetails?.email,
        use_for_tracking: customerDetails?.use_for_tracking,
      });
    }
  }, [customerDetails]);

  const fetchUpdatedVehicles = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleCustomerForm = (e) => {
    e.preventDefault();

    let data = {
      title: contactData?.title,
      office_phone: contactData?.office_phone,
      office_phone1: contactData?.office_phone1,
      mobile_number: contactData?.mobile_number,
      email: contactData?.email,
      use_for_tracking: contactData?.use_for_tracking,
    };

    let updateData = {
      uuid: contactData?.uuid,
      title: contactData?.title,
      office_phone: contactData?.office_phone,
      office_phone1: contactData?.office_phone1,
      mobile_number: contactData?.mobile_number,
      email: contactData?.email,
      use_for_tracking: contactData?.use_for_tracking,
    };

    if (contactData?.uuid) {
      modifyCustomer(createCustomer, updateData);
    } else {
      modifyCustomer(createCustomer, data);
    }
  };

  const modifyCustomer = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setAddCustomerStatus(false);
        let data = { ...params };
        getCustomerList(data)
          .then((response) => {})
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
        fetchUpdatedVehicles();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleCustomerForm}>
              <FormGroup>
                <div className="add-vehicle-rw">
                  <div className="row">
                    <div className="col-md-4">
                      <Label className="col-form-label">{"Title"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Title"
                          name={`title`}
                          onChange={(e) => {
                            setContactData({
                              ...contactData,
                              title: e.target.value,
                            });
                          }}
                          value={contactData.title}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Office Phone 1"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          type="text"
                          placeholder="Office Phone 1"
                          name={`office_phone`}
                          onChange={(e) => {
                            setContactData({
                              ...contactData,
                              office_phone: e.target.value,
                            });
                          }}
                          value={contactData.office_phone}
                          maxLength={9}
                        ></input>
                        {errors.office_phone && (
                          <span className="input-error">
                            {errors.office_phone}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">
                        {"Office Phone 2"}
                      </Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          type="text"
                          placeholder="Office Phone 2"
                          name={`office_phone1`}
                          onChange={(e) => {
                            setContactData({
                              ...contactData,
                              office_phone1: e.target.value,
                            });
                          }}
                          value={contactData.office_phone1}
                          maxLength={9}
                        ></input>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Phone"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          type="text"
                          placeholder="Phone"
                          name={`mobile_number`}
                          onChange={(e) => {
                            setContactData({
                              ...contactData,
                              mobile_number: e.target.value,
                            });
                          }}
                          value={contactData.mobile_number}
                          maxLength={9}
                        ></input>
                        {/* {errors.mobile_number && (
                          <span className="input-error">
                            {errors.mobile_number}
                          </span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Label className="col-form-label">{"Email"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control"
                          id=""
                          placeholder="Email"
                          name={`email`}
                          onChange={(e) => {
                            setContactData({
                              ...contactData,
                              email: e.target.value,
                            });
                          }}
                          value={contactData.email}
                        ></input>
                        {/* {errors.email && (
                          <span className="input-error">{errors.email}</span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-4 mt-4">
                      <Label className="col-form-label">Use for tracking</Label>
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="use_for_tracking"
                            checked={contactData.use_for_tracking}
                            onChange={handleCheckBox}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="use_for_tracking"
                          >
                            Use for tracking
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCustomer;
