import React from "react";
import { NavLink } from "react-router-dom";

import loginLogoImgs from "../../assets/images/logo-1.png";

function LeftHeader() {
  return (
    <div className="app-header__logo" style={{ display: "none" }}>
      <div className="logo-src">
        <NavLink to={`/dashboard`}>
          <img src={loginLogoImgs} alt="logo" />
        </NavLink>
      </div>
    </div>
  );
}

export default LeftHeader;
