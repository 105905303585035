import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import configDb, { ConfigDB } from "../../config";
import Select from "react-select";
import { actionCountryList } from "../../services/Common";
import { createAccountProfile } from "../../services/ManagementService";
import { toast } from "react-toastify";
import Utils from "../../utils";

const industryType = [
  {
    value: "1",
    label:
      "Industries - Agriculture, animal production, hunting, forestry and fishing",
  },
  { value: "2", label: "Industries - Automotive" },
  { value: "3", label: "Industries - Construction" },
  { value: "4", label: "Industries - Manufacturing / Engineering" },
  { value: "5", label: "Industries - Mining" },
  { value: "6", label: "Industries - Other" },
  { value: "7", label: "Industries - Utilities (electricity, gas and water)" },
  { value: "8", label: "Other" },
  { value: "9", label: "Services - Administrative and support services" },
  { value: "10", label: "Services - Car rental and leasing" },
  { value: "11", label: "Services - Education" },
  { value: "12", label: "Services - Emergency" },
  { value: "13", label: "Services - Financial and insurance activities" },
  { value: "14", label: "Services - Information and communication" },
  { value: "15", label: "Services - Municipality" },
  { value: "16", label: "Services - Other" },
  { value: "17", label: "Services - Public or health service" },
  { value: "18", label: "Trade - Retail" },
  { value: "19", label: "Trade - Wholesale" },
  {
    value: "20",
    label: "Transportation - Passengers - Buses and coaches - International",
  },
  {
    value: "21",
    label: "Transportation - Passengers - Buses and coaches - Urban",
  },
  { value: "22", label: "Transportation - Passengers - Light vehicles" },
];

const ProfileAccount = ({ setActiveTab, AccountDetails }) => {
  const [AccountProfileData, setAccountProfileData] = useState({
    name: "",
    website: "",
    company_logo: "",
    industry: "",
    address: "",
    vatNumber: "",
    country: "",
  });

  let errorsObj = {
    name: "",
    industry: "",
    address: "",
    country: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [country, setCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");
  const fileInputRef = React.createRef();

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (AccountDetails) {
      setAccountProfileData({
        name: AccountDetails?.name,
        website: AccountDetails?.company_website,
        company_logo: AccountDetails?.logo,
        industry: AccountDetails?.industry,
        address: AccountDetails?.address,
        vatNumber: AccountDetails?.vat_number,
        country: AccountDetails?.country,
      });
      setImagePreview(AccountDetails?.logo);
    }
  }, [AccountDetails]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];

      if (!allowedTypes.includes(file.type)) {
        setError("Please select a PNG, JPG, or JPEG file.");
        return;
      }

      setError("");

      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
      setFileName(file.name);
      setImage(file);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview("");
    setError("");
    setFileName("");
    fileInputRef.current.value = "";
  };

  const handleProfileForm = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;

    if (!AccountProfileData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    if (!AccountProfileData?.industry) {
      errorObj.industry = "Industry is required";
      error = true;
    }

    if (!AccountProfileData?.address) {
      errorObj.address = "Address is required";
      error = true;
    }

    if (!AccountProfileData?.country) {
      errorObj.country = "Country is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    const formData = new FormData();
    formData.append("name", AccountProfileData?.name || null);
    formData.append("website", AccountProfileData?.website || null);
    formData.append("files", image || null);
    formData.append("industry", AccountProfileData?.industry || null);
    formData.append("address", AccountProfileData?.address || null);
    formData.append("vatNumber", AccountProfileData?.vatNumber || null);
    formData.append("country", AccountProfileData?.country || null);

    const formDatas = new FormData();
    formDatas.append("name", AccountProfileData?.name || null);
    formDatas.append("website", AccountProfileData?.website || null);
    formDatas.append("files", image || null);
    formDatas.append("industry", AccountProfileData?.industry || null);
    formDatas.append("address", AccountProfileData?.address || null);
    formDatas.append("vatNumber", AccountProfileData?.vatNumber || null);
    formDatas.append("country", AccountProfileData?.country || null);
    formDatas.append("uuid", AccountDetails?.uuid || null);

    if (AccountDetails?.uuid) {
      modifyprofileAccount(createAccountProfile, formDatas);
    } else {
      modifyprofileAccount(createAccountProfile, formData);
    }
  };

  const modifyprofileAccount = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        setActiveTab("workingperiod");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const countryId = Number(AccountProfileData.country);

  const selectedCountry = countryList?.find(
    (option) => option.value === countryId
  );

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary card-primary-profile general-ac">
            <Form method={`post`} onSubmit={handleProfileForm}>
              <FormGroup>
                <div className="row">
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Company name"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={AccountProfileData.name}
                        onChange={(e) => {
                          setAccountProfileData({
                            ...AccountProfileData,
                            name: e.target.value,
                          });
                        }}
                        name={`name`}
                        placeholder="Company name"
                      />

                      {errors.name && (
                        <span className="input-error">{errors.name}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Website"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={AccountProfileData.website}
                        onChange={(e) => {
                          setAccountProfileData({
                            ...AccountProfileData,
                            website: e.target.value,
                          });
                        }}
                        name={`website`}
                        placeholder="Website"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Company Address"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={AccountProfileData.address}
                        onChange={(e) => {
                          setAccountProfileData({
                            ...AccountProfileData,
                            address: e.target.value,
                          });
                        }}
                        name={`address`}
                        placeholder="Company Address"
                      />
                      {errors.address && (
                        <span className="input-error">{errors.address}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"VAT Number"}</Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={AccountProfileData.vatNumber}
                        onChange={(e) => {
                          setAccountProfileData({
                            ...AccountProfileData,
                            vatNumber: e.target.value,
                          });
                        }}
                        name={`vatNumber`}
                        placeholder="VAT Number"
                      />
                    </div>
                  </div>

                  <div className="col-xl-4">
                    <Label className="col-form-label">{"Select Country"}</Label>
                    <Select
                      options={countryList}
                      value={selectedCountry}
                      onChange={(selectedOption) =>
                        setAccountProfileData((prevData) => ({
                          ...prevData,
                          country: selectedOption.value,
                        }))
                      }
                      placeholder={country ? country.label : "Select Country"}
                    />
                    {errors.country && (
                      <span className="input-error">{errors.country}</span>
                    )}
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">{"Industry"}</Label>
                    <div className="mb-3">
                      <Select
                        options={industryType}
                        value={industryType.find(
                          (option) =>
                            option.value === AccountProfileData.industry
                        )}
                        onChange={(selectedOption) =>
                          setAccountProfileData((prevData) => ({
                            ...prevData,
                            industry: selectedOption.value,
                          }))
                        }
                        placeholder={"Select Industry"}
                      />
                      {errors.industry && (
                        <span className="input-error">{errors.industry}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="company-logo">
                      <Label className="col-form-label">{"Company logo"}</Label>
                      <div className="input-div">
                        <input
                          className="form-control input-upload"
                          type="file"
                          accept="image/jpeg, image/png"
                          onChange={handleImageChange}
                          style={{ paddingLeft: "12px" }}
                          ref={fileInputRef}
                        />
                        {error && (
                          <div className="text-danger mt-2">{error}</div>
                        )}
                        {imagePreview && (
                          <div className="image-preview-container mt-2">
                            <img
                              src={imagePreview}
                              alt="Preview"
                              style={{ maxWidth: "100%", maxHeight: "200px" }}
                              className="img-thumbnail"
                            />
                          </div>
                        )}
                        <Button
                          onClick={handleRemoveImage}
                          className="mt-2 btn-delete bg-danger border-0 text-light"
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileAccount;
