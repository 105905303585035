import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { NavLink } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import UpdateInvoice from "./UpdateInvoice";
import {
  deleteTripInvoice,
  getTripInvoiceList,
} from "../../services/TripInvoice";

const tableLengthList = configDb.data.dataTableLength;

const InvoiceList = ({ customerDetails }) => {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [orderList, setOrderList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [AddInvoiceStatus, setAddInvoiceStatus] = useState(false);
  const [InvoiceAddData, setInvoiceAddData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    customer_id: customerDetails ? customerDetails?.id : 0,
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleCustomer = (e) => {
    setAddInvoiceStatus(true);
  };

  const handleCustomerEdit = (e, item) => {
    setAddInvoiceStatus(true);
    setInvoiceAddData(item);
  };

  useEffect(
    function () {
      let data = {
        customer_id: customerDetails ? customerDetails?.id : 0,
        limit: tableLengthList[0],
        page: 1,
        search: "",
        is_reload: false,
      };
      setIsLoading(true);
      getTripInvoiceList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setOrderList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [customerDetails]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);
    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "device_imei") {
      paramsObj.page = 1;
      paramsObj.device_imei = getValue(e);
    } else if (type === "vehicleClassId") {
      paramsObj.page = 1;
      paramsObj[type === "vehicleClassId" ? "vehicle_class" : ""] = getValue(e);
    }

    setParams(paramsObj);
  };

  const onDeleteCustomer = (e, item) => {
    e.preventDefault();
    setDelSubscription(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyCustomer = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    deleteTripInvoice(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const ItemComponent = ({ item }) => {
    let badgeColor = "";
    let label = "";

    switch (item?.status_id) {
      case 1:
        label = "Unpaid";
        badgeColor = "bg-danger";
        break;
      case 2:
        label = "Paid";
        badgeColor = "bg-success";
        break;
      case 3:
        label = "Overdue";
        badgeColor = "bg-warning";
        break;
    }

    return <span className={`badge rounded-pill ${badgeColor}`}>{label}</span>;
  };

  return (
    <>
      {AddInvoiceStatus ? (
        <UpdateInvoice
          setAddInvoiceStatus={setAddInvoiceStatus}
          InvoiceAddData={InvoiceAddData}
          customerDetails={customerDetails}
        />
      ) : (
        <>
          <div className="inner-header py-3">
            <div className="left-block">
              <h5>Invoice</h5>
            </div>

            <div className="right-block w-auto">
              {allPermissionsList.findIndex((e) => e.name == "modify_vehicle") >
                -1 && (
                <Button
                  type={`button`}
                  onClick={handleCustomer}
                  className={`add-btn btn  float-right`}
                >
                  {`Add`}
                  <i className="plus-circle">
                    <img src={plusIcon} alt="" className="plus-icons" />
                  </i>
                </Button>
              )}
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row company-section-Area">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between company-section">
                    <div className="col-xl-3">
                      <div className="selected-block">
                        <p>Show</p>
                        <select
                          className="form-select"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-table-row">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>{`Invoice Number`}</th>
                    <th scope={`col`}>{`Invoice Date`}</th>
                    <th scope={`col`}>{`Due Date`}</th>
                    <th scope={`col`}>{`Total Amount Due`}</th>
                    <th scope={`col`}>{`Status`}</th>
                    <th scope={`col`}>{`Payment Date`}</th>

                    {(allPermissionsList.findIndex(
                      (e) => e.name == "modify_vehicle"
                    ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_vehicle"
                      ) > -1) && <th scope={`col`}>{`Action`}</th>}
                  </tr>
                </thead>
                <tbody>
                  {orderList &&
                    orderList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td
                            style={{ cursor: "pointer", color: "#45abe3" }}
                            onClick={(e) => handleCustomerEdit(e, item)}
                          >
                            {item.invoice_number}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {item.invoice_date || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.due_date || "-"}
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {item.total_due || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <ItemComponent item={item} />
                          </td>

                          <td style={{ cursor: "pointer" }}>
                            {item.payment_date || "-"}
                          </td>

                          {(allPermissionsList.findIndex(
                            (e) => e.name == "modify_vehicle"
                          ) > -1 ||
                            allPermissionsList.findIndex(
                              (e) => e.name == "delete_vehicle"
                            ) > -1) && (
                            <td>
                              {allPermissionsList.findIndex(
                                (e) => e.name == "modify_vehicle"
                              ) > -1 && (
                                <NavLink
                                  to="#"
                                  role="button"
                                  className="btn btn-info add-btn-circle me-2"
                                  onClick={(e) => handleCustomerEdit(e, item)}
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </NavLink>
                              )}
                              {allPermissionsList.findIndex(
                                (e) => e.name == "delete_vehicle"
                              ) > -1 && (
                                <button
                                  type={`button`}
                                  onClick={(e) => onDeleteCustomer(e, item)}
                                  className={`btn btn-circle btn-danger add-btn-circle me-2`}
                                >
                                  <i className="bi bi-trash-fill"></i>
                                </button>
                              )}

                              {/* <button
                            type={`button`}
                            //
                            onClick={() => {
                              navigate(`/vehicle/${item.id}/history-details`, {
                                state: item,
                              });
                            }}
                            className={`btn btn-success add-btn-circle me-2`}
                          >
                            <i className="bi bi-eye-fill" />
                          </button> */}
                            </td>
                          )}
                        </tr>
                      );
                    })}

                  {orderList && orderList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name == "modify_vehicle"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_vehicle"
                          ) > -1
                            ? 7
                            : 7
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>

          <Modal isOpen={delModal}>
            <ModalHeader>{`Delete Customer`}</ModalHeader>
            <ModalBody>
              <p
                className={`mb-0`}
              >{`Are you sure you want to delete this invoice?`}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                type={`button`}
                disabled={isDelButtonLoad}
                outline
                onClick={onDeleteModalClose}
                className={`btn-square`}
              >{`Close`}</Button>
              {!isDelButtonLoad && (
                <Button
                  color="secondary"
                  type={`button`}
                  className={`btn-square`}
                  onClick={onDestroyCustomer}
                >{`Delete`}</Button>
              )}
              {isDelButtonLoad && (
                <Button
                  color="secondary"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  );
};

export default InvoiceList;
