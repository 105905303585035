import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import {
  getEmployeeDelete,
  getEmployeeList,
} from "../../services/EmployeeService";
import { forgotPassword } from "../../services/AuthService";
import AddEmployee from "./AddEmployee";

const tableLengthList = configDb.data.dataTableLength;

function Employee({ setActiveTab, setRefreshKey }) {
  const allPermissionsList = useSelector((x) => x.permission.value);

  const [companyList, setCompanyList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delCompany, setDelCompany] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  let filterData = localStorage.getItem("filter");

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
    email: "",
    number: "",
    filter_id: filterData,
  });

  const navigate = useNavigate();

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const isAdminRole = JsonParse?.user_role === ConfigDB.data.ROLE_ADMIN;

  const [email, setEmail] = useState({});

  const [AddUserStatus, setAddUserStatus] = useState(false);
  const [UserData, setUserAddData] = useState({});

  const handleUserStatus = (e, item) => {
    setAddUserStatus(true);
    setUserAddData(item);
  };

  const onForgotPassword = (e) => {
    e.preventDefault();

    if (email) {
      let obj = {
        email: email.email,
      };
      forgotPassword(obj)
        .then((response) => {
          toast.success(response.data.message);
          setPasswordModal(false);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        });
    }
  };

  useEffect(
    function () {
      let data = { ...params };
      setInterval(function () {
        if (data.filter_id !== localStorage.getItem("filter")) {
          data.filter_id = localStorage.getItem("filter");
          data.is_reload = !data.is_reload;
          setParams(data);
        }
      }, 1000);
    },
    [params]
  );

  useEffect(
    function () {
      let data = { ...params };
      setIsLoading(true);
      getEmployeeList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setCompanyList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "email") {
      paramsObj.email = e.target.value;
      paramsObj.page = 1;
    } else if (type === "number") {
      paramsObj.number = e.target.value;
      paramsObj.page = 1;
    }
    setParams(paramsObj);
  };

  const onDeleteCompany = (e, item) => {
    e.preventDefault();
    setDelCompany(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelCompany("");
    setDelModal(false);
  };

  const onDestroyUser = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delCompany };
    getEmployeeDelete(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelCompany("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onResetModalClose = () => {
    setPasswordModal(false);
  };

  const onResetModalOpen = (item) => {
    setEmail(item);
    setPasswordModal(true);
  };

  return (
    <>
      {AddUserStatus ? (
        <AddEmployee
          setAddUserStatus={setAddUserStatus}
          UserData={UserData}
          setRefreshKey={setRefreshKey}
          setActiveTabs={setActiveTab}
        />
      ) : (
        <>
          <div className="inner-header py-3">
            <div className="left-block">
              <h5>Users</h5>
            </div>

            <div className="right-block w-auto">
              {allPermissionsList.findIndex((e) => e.name == "modify_user") >
                -1 && (
                <Button
                  type={`button`}
                  onClick={handleUserStatus}
                  className={`add-btn btn  float-right`}
                >
                  {`Add Employee`}
                  <i className="plus-circle">
                    <img src={plusIcon} alt="" className="plus-icons" />
                  </i>
                </Button>
              )}
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row page-header-task-vehical">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between">
                    <div className="col-xl-3">
                      <div className="selected-block selected-mobile">
                        <p>Show</p>
                        <select
                          className="form-select"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-3">
                      <div className="selected-block selected-mobile">
                        <p>Email</p>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            value={params.email}
                            onChange={(e) => handleParams(e, "email")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3">
                      <div className="selected-block selected-mobile">
                        <p>Number</p>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Number"
                            value={params.number}
                            onChange={(e) => handleParams(e, "number")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3">
                      <div className="selected-block selected-mobile">
                        <p>Search</p>
                        <div className="search-bar">
                          <i className="bi bi-search"></i>
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-table-row">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>#</th>
                    <th scope={`col`}>{`Users`}</th>

                    <th scope={`col`}>{`Employee Email`}</th>
                    <th scope={`col`}>{`Employee Contact`}</th>

                    <th scope={`col`}>{`Role`}</th>

                    {isAdminRole ? <th scope={`col`}>{`Company`}</th> : null}

                    {(allPermissionsList.findIndex(
                      (e) => e.name == "modify_user"
                    ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_user"
                      ) > -1) && <th scope={`col`}>{`Action`}</th>}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {companyList &&
                        companyList?.data?.map((item, i) => (
                          <tr key={i}>
                            <td>{item.index}</td>
                            <td
                              onClick={(e) => handleUserStatus(e, item)}
                              style={{ cursor: "pointer", color: "#45abe3" }}
                            >
                              {item.name}
                            </td>
                            <td style={{ cursor: "pointer" }}>{item.email}</td>
                            <td style={{ cursor: "pointer" }}>{item.number}</td>

                            <td style={{ cursor: "pointer" }}>
                              {item.role_name}
                            </td>

                            {isAdminRole ? (
                              <td
                                onClick={() => {
                                  navigate(`/user/${item.uuid}`);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {item.company_name}
                              </td>
                            ) : null}
                            {(allPermissionsList.findIndex(
                              (e) => e.name == "modify_user"
                            ) > -1 ||
                              allPermissionsList.findIndex(
                                (e) => e.name == "delete_user"
                              ) > -1) && (
                              <td>
                                {allPermissionsList.findIndex(
                                  (e) => e.name == "modify_user"
                                ) > -1 && (
                                  <NavLink
                                    to="#"
                                    role="button"
                                    onClick={(e) => handleUserStatus(e, item)}
                                    className={`btn btn-info add-btn-circle me-2`}
                                  >
                                    <i className="bi bi-pencil-fill"></i>
                                  </NavLink>
                                )}
                                {allPermissionsList.findIndex(
                                  (e) => e.name == "delete_user"
                                ) > -1 && (
                                  <button
                                    type={`button`}
                                    onClick={(e) => onDeleteCompany(e, item)}
                                    className="btn btn-danger add-btn-circle btn-circle me-2"
                                  >
                                    <i className="bi bi-trash-fill"></i>
                                  </button>
                                )}

                                <button
                                  type={`button`}
                                  onClick={() => onResetModalOpen(item)}
                                  className="btn btn-success add-btn-circle me-2"
                                >
                                  <i class="bi bi-key"></i>
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                    </>
                  )}

                  {companyList && companyList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={
                          allPermissionsList.findIndex(
                            (e) => e.name == "modify_user"
                          ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_user"
                          ) > -1
                            ? 6
                            : 3
                        }
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </>
      )}

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Employee`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this Employee?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyUser}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={passwordModal}>
        <ModalHeader>{`Reset Password`}</ModalHeader>
        <ModalBody>Are you sure want to send Reset password email ?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onResetModalClose}
            className={`btn-square`}
          >{`No`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onForgotPassword}
            >{`Yes`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Employee;
