import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddUserInteact } from "../../services/EmployeeService";

const InteractEmployee = ({ setAddUserStatus, usersDetails, setActiveTab }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [employeeData, setEmployeeData] = useState({
    allow_driver: 0,
    hide_message_driver: 0,
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  let userId = localStorage.getItem("UserId");

  useEffect(() => {
    if (usersDetails) {
      setEmployeeData({
        allow_driver: usersDetails?.allow_driver,
        hide_message_driver: usersDetails?.hide_message_driver,
      });
    }
  }, [usersDetails]);

  const handleEmployeeForm = (e) => {
    e.preventDefault();

    let data = {
      user_id: userId,
      allow_driver: employeeData?.allow_driver,
      hide_message_driver: employeeData?.hide_message_driver,
    };

    let updateData = {
      user_id: usersDetails?.id,
      uuid: usersDetails?.uuid,
      allow_driver: employeeData?.allow_driver,
      hide_message_driver: employeeData?.hide_message_driver,
    };

    if (usersDetails?.uuid) {
      modifyEmployee(createAddUserInteact, updateData);
    } else {
      modifyEmployee(createAddUserInteact, data);
    }
  };

  const modifyEmployee = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setAddUserStatus(false);
        setActiveTab("assignVehicle");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setEmployeeData({
      ...employeeData,
      allow_driver: newValue,
    });
  };

  const handleCheckboxChangeHide = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    setEmployeeData({
      ...employeeData,
      hide_message_driver: newValue,
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleEmployeeForm}>
              <h4>Interact Settings</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Allow drivers to send messages to this user
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allow_driver"
                          checked={employeeData.allow_driver === 1}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allow_driver"
                        >
                          Allow drivers to send messages to this user
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      Hide messages between drivers
                    </Label>
                    <div className="input-div">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="hide_message_driver"
                          checked={employeeData.hide_message_driver === 1}
                          onChange={handleCheckboxChangeHide}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="hide_message_driver"
                        >
                          Hide messages between drivers
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractEmployee;
