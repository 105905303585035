import React from "react";
import {useEffect} from "react";
import WebFont from "webfontloader";


const PrivacyPolicy = () => {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ["Poppins:400,600", "sans-serif"],
            },
        });
    }, []);

    return (

        <div>
            <div>
                <div>
                    <section className="page-title page-title-mini">
                        <div className="container">
                            <div className="page-title-row justify-content-center">
                                <div className="page-title-content">
                                    <h3 style={{fontFamily: "Poppins, sans-serif", fontWeight: "bold"}}>Privacy
                                        Policy</h3>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="team-area team-area-custom">
                        <div className="container">
                            <div className="section-title">
                                <h2 style={{fontFamily: "Poppins, sans-serif"}}>ON-ROUTE</h2>
                                <p style={{
                                    fontWeight: "100",
                                    fontFamily: "Poppins, sans-serif",
                                    textAlign: "left",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    The protection of privacy and personal data is a priority for ON-ROUTE, Lda
                                    (hereinafter referred to as ON-ROUTE) and a clear commitment made by us.
                                </p>

                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    textAlign: "left",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    This policy concerns the privacy practices of the portals and websites managed by
                                    ON-ROUTE. Any personal data you provide to us will be treated with the security and
                                    confidentiality guarantees required by the legal framework regarding the protection
                                    of personal data.
                                </p>

                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    textAlign: "left",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    Access to and use of the sites under the responsibility of ON-ROUTE must be free for
                                    the user, with ON-ROUTE assuming that the user has read, understood, and accepted
                                    the Terms and Conditions of use.
                                </p>

                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    textAlign: "left",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    Any attempts to alter the information, or any other action that may cause damage and
                                    put the integrity of the system at risk, are strictly prohibited in accordance with
                                    current legislation.
                                </p>

                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    textAlign: "left",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    The user of this website undertakes to comply with applicable legislation,
                                    particularly in terms of computer crime and intellectual property rights, and is
                                    exclusively responsible for any infringement of these regulations.
                                </p>
                            </div>

                            <div className="section-content">
                                <h3>
                                    Data Protection Officer
                                </h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    ON-ROUTE has appointed a Data Protection Officer who can be contacted via email at
                                    info@on-route.eu or by registered letter with acknowledgment of receipt.
                                </p>

                                <h3>
                                    Data Collection and Usage
                                </h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    ON-ROUTE collects, uses, and shares user data strictly for providing and improving
                                    our services. The types of personal and sensitive data we may collect include:
                                    <ul>
                                        <li>Name</li>
                                        <li>Date of birth</li>
                                        <li>Tax identification number</li>
                                        <li>Civil identification number</li>
                                        <li>Social security number</li>
                                        <li>Identification document number</li>
                                        <li>Address</li>
                                        <li>Email address</li>
                                        <li>Mobile phone number</li>
                                        <li>Location data (to enhance navigation and user experience)</li>
                                    </ul>
                                </p>

                                <h3>
                                    Purpose of Data Collection
                                </h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    Your personal data is collected for:
                                    <ul>
                                        <li>Registration and authentication purposes</li>
                                        <li>Providing customer support and resolving issues</li>
                                        <li>Compliance with legal obligations</li>
                                        <li>Enhancing user experience and personalization</li>
                                    </ul>
                                </p>


                                <h3>Data Sharing</h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    We do not sell your data or share it with third parties for commercial or advertising purposes. However, we may share personal and sensitive data with:
                                    <ul>
                                        <li>Government and law enforcement agencies when required by legal obligations</li>
                                        <li>Third-party service providers necessary for fulfilling our contractual obligations</li>
                                    </ul>
                                </p>

                                <h3>Data Security Measures</h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    ON-ROUTE employs industry-standard security measures to protect your personal data from unauthorized access, misuse, or disclosure, including:
                                    <ul>
                                        <li>Physical security measures such as restricted access to data centers</li>
                                        <li>Logical security measures such as identity management, firewalls, and encryption</li>
                                        <li>Secure communication channels for transmitting sensitive data</li>
                                    </ul>
                                </p>

                                <h3>
                                    Data Retention and Deletion
                                </h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    We retain personal data only as long as necessary for the purposes outlined in this policy. Once data is no longer required, it is securely deleted following industry-standard data destruction practices.
                                </p>

                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    Users may request the deletion of their personal data by contacting us at info@on-route.eu.
                                </p>


                                <h3>
                                    User Rights
                                </h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    Users have the right to:
                                    <ul>
                                        <li>Access, rectify, or erase their personal data</li>
                                        <li>Object to the processing of their personal data</li>
                                        <li>Withdraw consent where applicable, without affecting prior data processing</li>
                                    </ul>

                                </p>


                                <h3>
                                    Location Data Usage
                                </h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    ON-ROUTE may collect and process location data when you use our services to improve navigation, optimize routes, and enhance service delivery. You can disable location tracking at any time through your device settings; however, this may affect certain functionalities.
                                </p>

                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    To exercise these rights, please contact our Data Protection Officer.
                                </p>
                                <h3>
                                    Policy Updates
                                </h3>
                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    ON-ROUTE reserves the right to update this privacy policy at any time. Users will be notified of significant changes through our website or other communication channels.
                                </p>

                                <p style={{
                                    fontWeight: "200",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "17px",
                                    lineHeight: "26px"
                                }}>
                                    For any questions regarding this privacy policy, please contact us at info@on-route.eu.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
