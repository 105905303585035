import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { getSubscriptionList } from "../../services/SubscriptionService";
import Utils from "../../utils";
import { subscriptionPlanActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { actionSubscriptionSucceeded } from "../../services/CompanyService";

function SubscriptionPopup({}) {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false); // false = Monthly, true = Yearly
  const auth = useSelector((x) => x.auth.value);

  const filteredSubscriptions = subscriptionList?.filter(
    (item) => item.type === (isChecked ? 2 : 1)
  );
  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
    };
    setIsLoading(true);
    getSubscriptionList(data)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          setSubscriptionList(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);
  const onGetStart = async (item) => {
    if (item?.price === 0) {
      try {
        let succeededData = {
          stripe_subscription_id: "free",
          plan_id: item.id,
        };

        await actionSubscriptionSucceeded(succeededData)
          .then((response) => {
            let responseData = response.data;
            let userData = {
              ...auth,
              subscription_data: responseData.subscription_data,
            };
            Utils.setDetail(userData);
            toast.success(responseData.message);
            dispatch(subscriptionPlanActions.subscriptionPlan(0));
            window.location.href = "/dashboard";
          })
          .catch((error) => {
            throw Utils.getErrorMessage(error);
          });
      } catch (error) {
        toast.error(error);
      }
    } else {
      await dispatch(subscriptionPlanActions.subscriptionPlan(item));
      navigate("/payment");
    }
  };

  return (
    <>
      <div className="subscriptions-blox subscriptions-blox-custom">
        <div className="heading  text-center">
          <div
            className={`bootstrap-switch  ${
              isChecked ? "bootstrap-switch-on" : "bootstrap-switch-off"
            }`}
          >
            <div
              className="bootstrap-switch-container"
              onClick={() => setIsChecked(!isChecked)}
            >
              <span
                className={`bootstrap-switch-handle-off ${
                  !isChecked ? "bootstrap-switch-default" : ""
                }`}
              >
                Monthly
              </span>
              <span className="bootstrap-switch-label">&nbsp;</span>
              <span
                className={`bootstrap-switch-handle-on ${
                  isChecked ? "bootstrap-switch-primary" : ""
                }`}
              >
                Yearly
              </span>

              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="promos">
          {filteredSubscriptions?.length > 0 &&
            filteredSubscriptions?.map((item, index) => {
              return (
                <>
                  <div
                    className={`promo ${
                      item.is_most_popular === 1 ? "scale promo-active" : ""
                    }`}
                  >
                    <div className="card">
                      <div className="card-header">
                        {item.is_most_popular === 1 ? (
                          <span className="most-h">Most Popular</span>
                        ) : (
                          <span className="most-h"></span>
                        )}

                        <div className="text-center">
                          <span className="price">
                            {" "}
                            € {item.price} {isChecked ? "/ Year" : "/ Month"}{" "}
                          </span>
                        </div>
                        <p>{item.subscription}</p>
                      </div>
                      <div className="card-body">
                        <div className="inner-body-card">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.subscription_desc,
                            }}
                          />{" "}
                        </div>

                        <div className="d-flex justify-content-center">
                          <button
                            className="buy buy-custom-btn"
                            onClick={() => onGetStart(item)}
                          >
                            Get Started
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>

      {/* <Modal isOpen={subscriptionModal} className="modal-dialog modal-xl">
        <ModalBody>
          <div className="form">
            <div className="heading mb-4">Subscription</div>

            <div className="heading mb-4 text-center">
              <div
                className={`bootstrap-switch  ${
                  isChecked ? "bootstrap-switch-on" : "bootstrap-switch-off"
                }`}
                style={{ marginLeft: "10px" }}
              >
                <div
                  className="bootstrap-switch-container"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  <span
                    className={`bootstrap-switch-handle-on ${
                      isChecked ? "bootstrap-switch-primary" : ""
                    }`}
                  >
                    Yearly
                  </span>
                  <span className="bootstrap-switch-label">&nbsp;</span>
                  <span
                    className={`bootstrap-switch-handle-off ${
                      !isChecked ? "bootstrap-switch-default" : ""
                    }`}
                  >
                    Monthly
                  </span>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="clearfix"></div>

            <div className="row">
              <div className="padding-bottom--24 mt-2">
                <div className="container">
                  <div className="card-deck mb-3 card-deck-custome card-deck-custom-scroll">
                    <div
                      className="card mb-4 box-shadow"
                      style={{ border: "transparent" }}
                    >
                      <div className="pricing">
                        {filteredSubscriptions?.length > 0 ? (
                          filteredSubscriptions?.map((item, index) => (
                            <div className="plan popular" key={index}>
                              <div className="card-body">
                                {item.is_most_popular === 1 && (
                                  <span>Most Popular</span>
                                )}{" "}
                                <h4 className="font-weight-normal">
                                  {item.subscription}
                                </h4>
                                <h6 className="pricing-card-title text-center">
                                  <b>
                                    € {item.price}{" "}
                                    {isChecked ? "/ Year" : "/ Month"}
                                  </b>
                                </h6>
                                <div
                                  className="list-unstyled mt-3"

                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.subscription_desc,
                                    }}
                                  />{" "}
                                </div>
                              </div>
                              <div className="card-footer">
                                <>
                                  <div className="d-flex justify-content-center">
                                    <Button
                                      className="login-btn"
                                      type="button"
                                      onClick={() => onGetStart(item)}
                                    >
                                      Get Started
                                    </Button>
                                  </div>
                                </>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="pricing">
                            <div className="plan">
                              <p className="text-center">No plans available</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </>
  );
}

export default SubscriptionPopup;
