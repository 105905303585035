import React, { useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import {
  createAddDriverPrivacyMode,
  getDriverListPrivacyMode,
} from "../../services/EmployeeService";

const PrivacyMode = ({ driverDetails }) => {
  const [tachnographData, setTachnographData] = useState({
    mode: 1,
  });

  const [privacyList, setprivacyList] = useState([]);
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  let DriverIdss = localStorage.getItem("driverIds");

  useEffect(() => {
    if (driverDetails) {
      setTachnographData(driverDetails?.driver_privacy_mode);
    }
  }, [driverDetails]);

  const handleCheckboxChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;

    setTachnographData(() => ({
      ...tachnographData,
      mode: newValue,
    }));

    const data = {
      mode: newValue,
      driver_id: driverDetails.id ? driverDetails.id : DriverIdss,
    };

    createAddDriverPrivacyMode(data)
      .then((response) => {
        toast.success(response.data.message);
        let datas = {
          driver_id: driverDetails.id ? driverDetails.id : DriverIdss,
        };
        getDriverListPrivacyMode(datas)
          .then((response) => {
            setprivacyList(response.data.data);

            // setAddDriverStatus(false);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <h4>Privacy Mode</h4>
            <FormGroup>
              <div
                className="row"
                style={{
                  border: "solid #eee 1px",
                  borderRadius: "5px",
                  paddingBottom: "12px",
                }}
              >
                <div className="col-md-4">
                  <Label className="col-form-label">Driver privacy mode</Label>
                  <div className="input-div">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mode"
                        checked={tachnographData?.mode === 1}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="mode">
                        Driver privacy mode
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </FormGroup>

            <div className="page-table-row">
              <table className={`table table-striped`} width="100%">
                <thead>
                  <tr>
                    <th scope={`col`}>{`Change Date`}</th>
                    <th scope={`col`}>{`Mode`}</th>
                    <th scope={`col`}>{`Source`}</th>
                    <th scope={`col`}>{`UserName`}</th>
                  </tr>
                </thead>
                <tbody>
                  {privacyList &&
                    privacyList?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td style={{ cursor: "pointer" }}>
                            {item.change_date || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.mode === 1 ? "Active" : "Deactivate"}
                          </td>
                          <td style={{ cursor: "pointer" }}>{"On-Route"}</td>
                          <td style={{ cursor: "pointer" }}>
                            {JsonParse.name}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyMode;
