import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import configDb, { ConfigDB } from "../../config";
import { useSelector } from "react-redux";
import {
  getInvoiceAllList,
  getInvoiceDetails,
} from "../../services/TripService";
import { toast } from "react-toastify";
import Utils from "../../utils";
const tableLengthList = configDb.data.dataTableLength;

function InVoice(props) {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [invoiceList, setInvoiceList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyDetailsModal, setModifyDetailsModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
    company_id: JsonParse.company_id,
  });

  const [invoiceData, setInvoiceData] = useState({});

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getInvoiceAllList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setInvoiceList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const ItemComponent = ({ item }) => {
    let badgeColor = "";
    let displayStatus = item.status;

    if (item.invoice_received_status === 0) {
      displayStatus = "Pending";
      badgeColor = "bg-warning";
    } else if (item.invoice_received_status === 1) {
      displayStatus = "Completed";
      badgeColor = "bg-success";
    }

    return (
      <span className={`badge rounded-pill btn-warning ${badgeColor}`}>
        {displayStatus}
      </span>
    );
  };

  const ItemComponents = ({ item }) => {
    let badgeColor = "";
    let displayStatus = item.status;

    if (item.invoice_paid_by === 0) {
      displayStatus = "Not Paid";
      badgeColor = "bg-warning";
    } else if (item.invoice_paid_by === 1) {
      displayStatus = "Paid";
      badgeColor = "bg-success";
    }

    return (
      <span className={`badge rounded-pill btn-warning ${badgeColor}`}>
        {displayStatus}
      </span>
    );
  };

  const onDetailsModalClose = (e) => {
    setModifyDetailsModal(false);
  };

  const onDetailsModalOpen = (item) => {
    setModifyDetailsModal(true);
    setInvoiceData(item);
  };

  const handleDownload = async (item) => {
    try {
      const data = {
        uuid: item.uuid,
      };

      setIsLoading(true);

      const response = await getInvoiceDetails(data, true);

      if (response.data.data && response.data.data.url) {
        let data = await Utils.downloadAnyFile(
          response.data.data.url,
          response.data.data.filename
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error("Oops..something went wrong.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error downloading the PDF:", error);
    }
    // } finally {
    //   // Set loading state to false after the download attempt
    //   setIsLoading(false);
    // }
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Invoice List</h5>
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between company-section">
                <div className="col-xl-2">
                  <div className="selected-block">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Trip Name`}</th>
                {JsonParse.company_id === 0 ? (
                  <th scope={`col`}>{`Company Name`}</th>
                ) : null}

                <th scope={`col`}>{`Customer Name`}</th>
                <th scope={`col`}>{`Customer Contact`}</th>
                <th scope={`col`}>{`Invoice Amount`}</th>
                <th scope={`col`}>{`Paid Status`}</th>
                <th scope={`col`}>{`Status`}</th>
                <th scope={`col`}>{`Action`}</th>
              </tr>
            </thead>
            <tbody>
              {invoiceList &&
                invoiceList.data
                  ?.filter((item) => item.invoice_paid_amount)
                  .map((item, i) => (
                    <tr key={i}>
                      <td>{item.trip_name || "-"}</td>
                      {JsonParse.company_id === 0 ? (
                        <td>{item.company_name || "-"}</td>
                      ) : null}

                      <td>{item.customer_name || "-"}</td>
                      <td>{item.customer_contact || "-"}</td>
                      <td>{item.invoice_paid_amount || "-"}</td>
                      <td>
                        <ItemComponents item={item} />
                      </td>
                      <td>
                        <ItemComponent item={item} />
                      </td>
                      <td>
                        <button
                          type={`button`}
                          onClick={() => onDetailsModalOpen(item)}
                          className={`btn btn-success add-btn-circle me-2`}
                        >
                          <i className="bi bi-eye-fill" />
                        </button>
                        {item.invoice_paid_by === 1 ? (
                          <button
                            type="button"
                            onClick={() => handleDownload(item)}
                            className="btn btn-info add-btn-circle me-2"
                          >
                            <i className="bi bi-file-earmark-pdf-fill"></i>
                          </button>
                        ) : // <InvoiceComponent item={item}/>
                        null}
                      </td>
                    </tr>
                  ))}

              {invoiceList && invoiceList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modfiy_order"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_order"
                      ) > -1
                        ? 5
                        : 3
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={modifyDetailsModal}>
        <ModalHeader>Invoice Details</ModalHeader>

        <ModalBody>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Trip Name:
            <span style={{ color: "gray" }}> {invoiceData.trip_name}</span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Customer Name:
            <span style={{ color: "gray" }}> {invoiceData.customer_name}</span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Customer Contact:
            <span style={{ color: "gray" }}>
              {" "}
              {invoiceData.customer_contact}
            </span>
          </div>
          <div style={{ fontSize: "15px", fontWeight: "700" }}>
            Invoice Amount:
            <span style={{ color: "gray" }}>
              {" "}
              {invoiceData.invoice_paid_amount}
            </span>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            outline
            onClick={onDetailsModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default InVoice;
