import React from "react";
import logoImg from "../assets/images/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <div className="footer-logo">
                <img src={logoImg} alt="" className="img-fluid" />
              </div>
            </div>

            <div className="col-lg-9 col-md-6 footer-links">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright © 2022 On-Route Real-Time Connections
        </div>
      </div>
    </div>
  );
};

export default Footer;
