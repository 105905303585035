import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";

import GeneralFilters from "./GeneralFilters";
import Vehicles from "./Vehicles";
import Driver from "./Driver";
import ConfigDB from "../../config";
import { Button } from "reactstrap";
import { createFilter } from "../../services/ManagementService";

const tableLengthList = ConfigDB.data.dataTableLength;

const AddFilters = ({
  setFilterStatusData,
  addFiltersData,
  setRefreshKey,
  setActiveTabs,
}) => {
  const [activeTab, setActiveTab] = useState("general");
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [generalData, setGeneralData] = useState({ name: "" });

  useEffect(() => {
    if (addFiltersData?.id) {
      setGeneralData({ name: addFiltersData?.name });
    }
  }, [addFiltersData]);

  const handleVehicleSelection = (selected) => {
    setSelectedVehicles(selected);
  };

  const handleDriverSelection = (selected) => {
    setSelectedDrivers(selected);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (addFiltersData?.uuid) {
      setSelectedVehicles([addFiltersData.vehicle_total]);
      setSelectedDrivers([addFiltersData.driver_total]);
    }
  }, [addFiltersData]);

  const fetchUpdatedVehicles = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    if (!generalData?.name) {
      toast.error("Please select the name");
      return;
    }
    if (!selectedVehicles || selectedVehicles.length === 0) {
      toast.error("At least one vehicle must be selected");
      return;
    }

    if (!selectedDrivers || selectedDrivers.length === 0) {
      toast.error("At least one driver must be selected");
      return;
    }

    let data = {
      name: generalData?.name,
      vehicle_total: selectedVehicles,
      driver_total: selectedDrivers,
    };

    let updateData = {
      uuid: addFiltersData?.uuid,
      name: generalData?.name,
      vehicle_total: selectedVehicles,
      driver_total: selectedDrivers,
    };

    if (addFiltersData?.uuid) {
      createFilter(updateData)
        .then((response) => {
          toast.success(response.data.message);

          setFilterStatusData(false);
          setActiveTabs("filter");
          fetchUpdatedVehicles();
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      createFilter(data)
        .then((response) => {
          toast.success(response.data.message);
          setFilterStatusData(false);
          setActiveTabs("filter");
          fetchUpdatedVehicles();
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  return (
    <div>
      <div className="inner-header py-3 d-none">
        <div className="left-block"></div>
      </div>
      <div className="inner-header-back">
        <div className="left-col">
          <button
            type="buttton"
            className="back-btn"
            onClick={() => {
              setFilterStatusData(false);
              setActiveTabs("filter");
              fetchUpdatedVehicles();
            }}
          >
            <i class="bi bi-chevron-left"></i> Back
          </button>
        </div>
        <div className="right-block w-auto">
          <Button
            type={`button`}
            onClick={handleVehicleForm}
            className={`add-btn btn  float-right`}
            disabled={!generalData?.name}
          >
            {`Save`} <i className="bi bi-check-circle-fill checkIcon"></i>
          </Button>
        </div>
      </div>

      <div className="leaflet-control-management managementui-tab leaflet-control-var">
        <div className="tabs">
          <nav className="tab-nav">
            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "general" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("general");
                }}
              >
                <span data-href="#tab-1">General</span>
              </li>
            </ul>

            <ul className="tabs-management">
              <li
                className={`nav-link ${
                  activeTab === "vehicle" ? "active" : ""
                }`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("vehicle");
                }}
              >
                {addFiltersData?.vehicleTotalCount > 0 ? (
                  <>
                    <i
                      className="bi bi-check-lg"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <span data-href="#tab-1" style={{ marginLeft: "10px" }}>
                      Vehicle
                    </span>
                  </>
                ) : (
                  <span data-href="#tab-1">Vehicle</span>
                )}
              </li>
            </ul>

            <ul className="tabs-management">
              <li
                className={`nav-link ${activeTab === "driver" ? "active" : ""}`}
                role="tab"
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick("driver");
                }}
              >
                {addFiltersData?.driverTotalCount > 0 ? (
                  <>
                    <i
                      className="bi bi-check-lg"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <span data-href="#tab-1" style={{ marginLeft: "10px" }}>
                      Driver
                    </span>
                  </>
                ) : (
                  <span data-href="#tab-1">Driver</span>
                )}
              </li>
            </ul>
          </nav>

          <div className="dashboard-block tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "general" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <GeneralFilters
                setActiveTab={setActiveTab}
                addFiltersData={addFiltersData}
                setFilterStatusData={setFilterStatusData}
                setGeneralData={setGeneralData}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "vehicle" ? "show active" : ""
              }`}
              id="tab-1"
            >
              <Vehicles
                setActiveTab={setActiveTab}
                addFiltersData={addFiltersData}
                setFilterStatusData={setFilterStatusData}
                onSelectionChange={handleVehicleSelection}
                setActiveTabs={setActiveTabs}
                setRefreshKey={setRefreshKey}
              />
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "driver" ? "show active" : ""
              }`}
              id="tab-3"
            >
              <Driver
                setActiveTab={setActiveTab}
                addFiltersData={addFiltersData}
                setFilterStatusData={setFilterStatusData}
                onSelectionChange={handleDriverSelection}
                setActiveTabs={setActiveTabs}
                setRefreshKey={setRefreshKey}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFilters;
