import React, { useEffect, useState } from "react";
import { Form, Button } from "reactstrap";
import ConfigDB from "../../config";
import { getVehicleList } from "../../services/VehicleService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createUserFilterVehicle } from "../../services/EmployeeService";

const tableLengthList = ConfigDB.data.dataTableLength;

const AssignVehicle = ({
  setAddUserStatus,
  usersDetails,
  setActiveTabs,
  setRefreshKey,
}) => {
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  let filterData = localStorage.getItem("filter");

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
    filter_id: filterData,
  });
  const [isLoading, setIsLoading] = useState(false);

  let userId = localStorage.getItem("UserId");
  let userEmail = localStorage.getItem("UserEmail");
  let userCompanyId = localStorage.getItem("UserCompanyId");
  let userName = localStorage.getItem("UserName");

  useEffect(() => {
    if (usersDetails?.uuid) {
      setSelectedVehicles([usersDetails.vehicle_total]);
    }
  }, [usersDetails]);

  const handleCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    setSelectedVehicles((prevSelected) => {
      const newSelection = isChecked
        ? [...prevSelected, item.id]
        : prevSelected.filter((id) => id !== item.id);
      setSelectedVehicles(newSelection);
      return newSelection;
    });
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelection = isChecked
      ? vehicleList.data.map((item) => item.id)
      : [];
    setSelectedVehicles(newSelection);
    setSelectedVehicles(newSelection);
  };

  useEffect(
    function () {
      let data = { ...params };
      setInterval(function () {
        if (data.filter_id !== localStorage.getItem("filter")) {
          data.filter_id = localStorage.getItem("filter");
          data.is_reload = !data.is_reload;
          setParams(data);
        }
      }, 1000);
    },
    [params]
  );

  useEffect(() => {
    let data = { ...params };
    setIsLoading(true);
    getVehicleList(data)
      .then((response) => {
        setVehicleList(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [params]);

  const fetchUpdatedVehicles = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleDrivingForm = (e) => {
    e.preventDefault();

    if (!usersDetails || !usersDetails?.id) {
      toast.error("Please add the user first, after assigning a vehicle");
      return;
    }

    let data = {
      user_id: usersDetails ? usersDetails?.id : userId,
      vehicle_total: selectedVehicles,
      email: userEmail,
      companyId: userCompanyId,
      name: userName,
    };

    let Updatedata = {
      uuid: usersDetails?.uuid,
      user_id: usersDetails?.id,
      vehicle_total: selectedVehicles,
      email: usersDetails?.email,
      companyId: usersDetails?.companyId,
      name: usersDetails?.name,
    };

    if (usersDetails?.uuid) {
      modifyDriver(createUserFilterVehicle, Updatedata);
    } else {
      modifyDriver(createUserFilterVehicle, data);
    }
  };

  const modifyDriver = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        fetchUpdatedVehicles();
        setActiveTabs("users");
        setAddUserStatus(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="container-event">
        <h4>Assign Vehicle</h4>
        <div className="page-table-row vehicle-td">
          <Form method={`post`} onSubmit={handleDrivingForm}>
            <table
              className={`table table-striped ${isLoading ? "loading" : ""}`}
              width="100%"
            >
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={
                        vehicleList?.data?.length > 0 &&
                        selectedVehicles.length === vehicleList?.data?.length
                      }
                    />
                  </th>
                  <th scope="col">Driver Name</th>
                  <th scope="col">Vehicle Name</th>
                  <th scope="col">Vehicle Plate</th>

                  <th scope="col">Model Year</th>
                </tr>
              </thead>
              {vehicleList?.data?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedVehicles.includes(item.id)}
                      onChange={(e) => handleCheckboxChange(e, item)}
                    />
                  </td>
                  <td>{item.driver_name || "-"}</td>
                  <td>{item.vehicleName}</td>
                  <td>{item.vehiclePlate}</td>

                  <td>{item.modelYear}</td>
                </tr>
              ))}
            </table>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square `}
              >{`Submit`}</Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default AssignVehicle;
