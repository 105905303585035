import axiosInstance from "./AxiosInstance";

export function actionDetail(data) {
  return axiosInstance.post(`/profile/detail`, data);
}

export function actionChangePassword(data) {
  return axiosInstance.post(`/profile/change-password`, data);
}

export function actionChangeImage(data) {
  return axiosInstance.post(`/profile/change-profile`, data);
}

export function actionChangeGeneral(data) {
  return axiosInstance.post(`/profile/exit-detail`, data);
}

export function GetSubscriptionHistory(data) {
  return axiosInstance.post(`/subscription/history`, data);
}

export function SubScriptionCancel(data) {
  return axiosInstance.post(`/subscription/cancel`, data);
}

export function getSubScriptionCompanyList(data) {
  return axiosInstance.post(`/subscription/company-list`, data);
}
