import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { useNavigate, useParams } from "react-router-dom";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { GetSubscriptionHistory } from "../../services/ProfileService";

const tableLengthList = configDb.data.dataTableLength;

const StatusTypeList = [
  { value: 0, label: "InActive", color: "red" },
  { value: 1, label: "Active", color: "green" },
  { value: 2, label: "Cancel", color: "orange" },
];

const SubscriptionHistory = () => {
  const navigate = useNavigate();
  const subscriptionTypeList = ConfigDB.data.SUBSCRIPTION_TYPE_LIST;

  const { uuid } = useParams();

  const [subscriptionList, setSubscriptionList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });

  const getStatusDetails = (status) => {
    return (
      StatusTypeList.find((type) => type.value === status) || {
        label: "N/A",
        color: "gray",
      }
    );
  };

  const getPaymentStatusStyle = (status) => {
    switch (status?.toLowerCase()) {
      case "succeeded":
        return { color: "green" };
      case "failed":
        return { color: "red" };
      default:
        return { color: "gray" }; // Default for unknown status
    }
  };

  useEffect(
    function () {
      if (uuid) {
        let data = {
          limit: tableLengthList[0],
          page: 1,
          search: "",
          is_reload: false,
          companyId: uuid,
        };
        setIsLoading(true);
        GetSubscriptionHistory(data)
          .then((response) => {
            setTotalRecords(response.data.total);
            setSubscriptionList(response.data.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [uuid]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    const getValue = (e) => (e ? (e.target ? e.target.value : e.value) : null);
    if (type === "length") {
      paramsObj.limit = getValue(e);
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = getValue(e);
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  return (
    <>
      <div>
        <div className="inner-header py-3">
          <div className="left-block">
            <h5>Subscription Details</h5>
          </div>
          <div className="inner-header py-3 d-none">
            <div className="left-block"></div>
          </div>
          <div className="inner-header-back">
            <div className="left-col">
              <button
                type="buttton"
                className="back-btn"
                onClick={() => {
                  navigate("/subscription-company");
                }}
              >
                <i class="bi bi-chevron-left"></i> Back
              </button>
            </div>
          </div>{" "}
        </div>

        <div className="container-event">
          <div className="page-header-row company-section-Area">
            <div className="row">
              <div className="col">
                <div className=" company-section row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
                  <div className="col">
                    <div className="selected-block">
                      <p>Show</p>
                      <select
                        className="form-select"
                        aria-label="Events Only"
                        defaultValue={params.limit}
                        onChange={(e) => handleParams(e, "length")}
                      >
                        {tableLengthList &&
                          tableLengthList.map((item, i) => (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col">
                    <div className="selected-block selected-mobile"></div>
                  </div>

                  <div className="col">
                    <div className="selected-block selected-mobile">
                      <p>Search</p>
                      <div className="search-bar">
                        <i className="bi bi-search"></i>
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-table-row">
            <table
              className={`table table-striped ${isLoading ? "loading" : ""}`}
              width="100%"
            >
              <thead>
                <tr>
                  {/* {JsonParse?.user_role === 1 && (
                    <>
                      <th scope="col">{`NAME`.toUpperCase()}</th>
                      <th scope="col">{`COMPANY EMAIL`.toUpperCase()}</th>
                      <th scope="col">{`CONTACT`.toUpperCase()}</th>
                    </>
                  )} */}
                  <th scope="col">{`SUBSCRIPTION ID`.toUpperCase()}</th>
                  <th scope="col">{`SUBSCRIPTION PRICE ID`.toUpperCase()}</th>
                  <th scope="col">{`SUBSCRIPTION NAME`.toUpperCase()}</th>
                  <th scope="col">{`SUBSCRIPTION PRICE`.toUpperCase()}</th>
                  <th scope="col">{`SUBSCRIPTION TYPE`.toUpperCase()}</th>
                  <th scope="col">{`PURCHASE DATE`.toUpperCase()}</th>
                  <th scope="col">{`EXPIRED AT`.toUpperCase()}</th>
                  <th scope="col">{`STATUS`.toUpperCase()}</th>

                  <th scope="col">{`PAYMENT STATUS`.toUpperCase()}</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionList &&
                  subscriptionList?.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* {JsonParse?.user_role === 1 && (
                          <>
                            <td style={{ cursor: "pointer", color: "#45abe3" }}>
                              {item.name}
                            </td>
                            <td style={{ cursor: "pointer" }}>
                              {item.company_email}
                            </td>

                            <td style={{ cursor: "pointer" }}>
                              {item.contact}
                            </td>
                          </>
                        )} */}
                        <td style={{ cursor: "pointer" }}>
                          {item?.stripe_subscription_id}
                        </td>

                        <td style={{ cursor: "pointer" }}>
                          {item?.stripe_subscription_price_id}
                        </td>

                        <td style={{ cursor: "pointer" }}>
                          {item.subscription_name}
                        </td>

                        <td style={{ cursor: "pointer" }}>
                          €{item.subscription_price}
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {subscriptionTypeList.find(
                            (type) => type.value === item.subscription_type
                          )?.label || "N/A"}
                        </td>
                        <td style={{ cursor: "pointer" }}>
                          {item.cover_from || "-"}
                        </td>
                        <td style={{ cursor: "pointer" }}>{item.cover_till}</td>
                        <td
                          style={{
                            cursor: "pointer",
                            color: getStatusDetails(item.is_active).color,
                            fontWeight: "bold",
                          }}
                        >
                          {getStatusDetails(item.is_active).label}
                        </td>

                        <td
                          style={{
                            cursor: "pointer",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            textAlign: "center",
                            fontWeight: "bold",
                            ...getPaymentStatusStyle(item.payment_status),
                          }}
                        >
                          {item.payment_status}
                        </td>
                      </tr>
                    );
                  })}

                {subscriptionList && subscriptionList?.length === 0 && (
                  <tr>
                    <td
                      colSpan={7}
                      className={`text-center`}
                    >{`Record Not Found`}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={`float-right`}>
            <Pagination
              activePage={params.page}
              itemsCountPerPage={parseInt(params.limit)}
              totalItemsCount={parseInt(totalRecords)}
              pageRangeDisplayed={5}
              itemClass={`page-item`}
              linkClass={`page-link`}
              onChange={(e) => handleParams(e, "pagination")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionHistory;
