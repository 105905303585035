import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import { store } from "./store";
import { ToastContainer } from "react-toastify";
import AuthLayout from "./views/layouts/AuthLayout";
import Dashboard from "./views/Dashboard";
import { PrivateRoute, PublicRoute } from "./utils/functions";
import Roles from "./views/roles";
import CompanyRegistration from "./views/company/CompanyRegistration";
import Login from "./views/auth/Login";
import ForgotPassword from "./views/auth/ForgotPassword";
import ResetPassword from "./views/auth/ResetPassword";
import CheckPermission from "./utils/CheckPermission";
import Permission from "./views/roles/Permission";
import Company from "./views/company";
import CompanyDetails from "./views/company/CompanyDetails";
import Subscription from "./views/subscription";
import DashboardLayout from "./views/layouts/DashboardLayout";
import ConfigDB from "./config";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./utils/Checkout";
import LandingPage from "./views/LandingPage";
import NotFoundPage from "./views/error/404";
import ForbiddenPage from "./views/error/403";
import Profile from "./views/Profile";
import Employee from "./views/employee";
import AddEmployee from "./views/employee/AddEmployee";
import About from "./views/about";
import Layout from "./views/Layout";
import Contact from "./views/contact";
import Vehicle from "./views/vehicle";
import VehicleClass from "./views/vehicle_class";
import Location from "./views/location";
// import PlaceMap from "./views/map/PlaceMap";
import RoutePath from "./views/routePath";
import Setting from "./views/setting";
import TripDetails from "./views/trip/TripDetails";
import Tripupdate from "./views/trip/Tripupdate";
import OrderState from "./views/orderState";
import Order from "./views/order";
import OrderDetails from "./views/order/OrderDetails";
import EmployeeDetails from "./views/employee/EmployeeDetails";
import Alarm from "./views/alarm";
import HealthDashboard from "./views/HealthDashboard";
import AddCompany from "./views/company/AddCompany";
import OrderTrip from "./views/trip/orderTrip";
import Maintainanceparm from "./views/maintainanceparm";
import MaintainanceTask from "./views/maintaninacetask";
import VehicleTask from "./views/vehicleTask";
import CompanyProvider from "./views/companyProvider";
import ApiDocument from "./views/companyProvider/apiDocument";
import DeviceHelp from "./views/help/DeviceHelp";
import VehicleHelp from "./views/help/VehicleHelp";
import Help from "./views/help";
import ApiDocumentation from "./views/apiDocumentation";
import Invoice from "./views/invoice";
import InvoicePdf from "./views/invoice/InvoicePdf";
// import LeaftMap from "./views/LeaftMap";
import Notification from "./views/notification";
import VehicleActivity from "./views/vehicleActivity";
import Management from "./views/management/Management";
import AddCost from "./views/management/AddCost";
import Administration from "./views/Administration";
import FilterVehicle from "./views/Administration/FilterVehicle";
import Drivers from "./views/employee/Drivers";
import Distribution from "./views/Distribution";
import Reports from "./views/reports";
import Customer from "./views/Customer";
import TripMain from "./views/trip/TripMain";
import AlarmList from "./views/reports/AlarmList";
import CostFuelList from "./views/reports/CostFuelList";
import DailySummaryList from "./views/reports/DailySummaryList";
import FuelMangementList from "./views/reports/FuelMangementList";
import RoundTripList from "./views/reports/RoundTripList";
import RouteExecutionList from "./views/reports/RouteExecutionList";
import VehicleDetailsList from "./views/reports/VehicleDetailsList";
import VehicleTripsList from "./views/reports/VehicleTripsList";
import VirtualOdometerList from "./views/reports/VirtualOdometerList";
import DetailsCostList from "./views/reports/DetailsCostList";
import VehicleStopList from "./views/reports/VehicleStopList";
import DriverDiaryList from "./views/reports/DriverDiaryList";
import Trips from "./views/Trips";
import DrivingBehaviorEventList from "./views/reports/DrivingBehaviorEventList";
import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsAndCondition from "./views/TermsAndCondition";
import AccountDeletion from "./views/AccountDeletion";
import SubscriptionHistory from "./views/SubscriptionHistory";
import SubscriptionCompanyList from "./views/SubscriptionHistory/SubscriptionCompanyList";
// import AddDriver from "./views/employee/AddDriver";
// import AddVehicle from "./views/vehicle/AddVehicle";
// import Parent from "./views/parent";

const stripePromise = loadStripe(ConfigDB.data.stripe_publish_key);

function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Router>
          {/*<LandingPage/>*/}
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  {" "}
                  <LandingPage />{" "}
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout>
                  {" "}
                  <About />{" "}
                </Layout>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <Layout>
                  {" "}
                  <PrivacyPolicy />{" "}
                </Layout>
              }
            />
            <Route
              path="/terms-condition"
              element={
                <Layout>
                  {" "}
                  <TermsAndCondition />{" "}
                </Layout>
              }
            />
            <Route
              path="/delete-account"
              element={
                <Layout>
                  {" "}
                  <AccountDeletion />{" "}
                </Layout>
              }
            />
            <Route
              path="/contact"
              element={
                <Layout>
                  {" "}
                  <Contact />{" "}
                </Layout>
              }
            />
            <Route
              path="/api-documentation"
              element={
                <Layout>
                  {" "}
                  <ApiDocumentation />{" "}
                </Layout>
              }
            />
            <Route path={`/alarm-reports`} element={<AlarmList />} />
            <Route path={`/cost-fuel-reports`} element={<CostFuelList />} />
            {/* <Route path={`/daily-summary`} element={<CostFuelList />} /> */}
            <Route
              path={`/daily-summary-reports`}
              element={<DailySummaryList />}
            />
            <Route
              path={`/daily-driver-reports`}
              element={<DriverDiaryList />}
            />
            <Route path={`/fuel-reports`} element={<FuelMangementList />} />
            <Route path={`/round-trip-reports`} element={<RoundTripList />} />
            <Route path={`/rount-reports`} element={<RouteExecutionList />} />
            <Route
              path={`/vehicle-details-reports`}
              element={<VehicleDetailsList />}
            />
            <Route
              path={`/vehicle-trip-reports`}
              element={<VehicleTripsList />}
            />
            <Route
              path={`/driver-condition-reports`}
              element={<DrivingBehaviorEventList />}
            />
            <Route
              path={`/vehicle-stop-reports`}
              element={<VehicleStopList />}
            />
            DriverDiaryList
            <Route
              path={`/virtual-odometer-reports`}
              element={<VirtualOdometerList />}
            />
            <Route
              path={`/detail-cost-reports`}
              element={<DetailsCostList />}
            />
            <Route path={`/tracking/order/:uuid`} element={<OrderTrip />} />
            <Route path={`/invoice-download/:uuid`} element={<InvoicePdf />} />
            <Route path={"/"} element={<PublicRoute />}>
              <Route path={`/api-document`} element={<ApiDocument />} />

              <Route path={"/"} element={<AuthLayout />}>
                <Route path={"/"} element={<Login />} />
                <Route path={"/login"} element={<Login />} />
                <Route path={"/forgot-password"} element={<ForgotPassword />} />
                <Route
                  path={`/reset-password/:token`}
                  element={<ResetPassword />}
                />
                <Route
                  path={"/company-registration"}
                  element={<CompanyRegistration />}
                />
              </Route>
            </Route>
            <Route path={"/"} element={<PrivateRoute />}>
              <Route path={"/"} element={<CheckPermission />}>
                <Route path={"/"} element={<DashboardLayout />}>
                  <Route path={"/"} element={<Dashboard />} />

                  <Route path={`/vehcile-trip-data`} element={<Trips />} />
                  <Route
                    path={"/add/company-registration"}
                    element={<AddCompany />}
                  />
                  <Route path={`/dashboard`} element={<Dashboard />} />
                  <Route
                    path={`/health-dashboard`}
                    element={<HealthDashboard />}
                  />

                  <Route
                    path={`/vehicle-health-dashboard`}
                    element={<VehicleHelp />}
                  />

                  <Route
                    path={`/device-health-dashboard`}
                    element={<DeviceHelp />}
                  />

                  <Route path={`/help`} element={<Help />} />

                  {/* <Route path={`/leaftmap`} element={<LeaftMap />} /> */}

                  <Route path={`/profile`} element={<Profile />} />
                  <Route path={`/roles`} element={<Roles />} />
                  <Route
                    path={`/roles/:uuid/permissions`}
                    element={<Permission />}
                  />
                  <Route path={`/company`} element={<Company />} />
                  <Route path={`/user`} element={<Employee />} />
                  <Route
                    path={`/user/add-employee`}
                    element={<AddEmployee />}
                  />

                  <Route
                    path={`/user/add-employee/:uuid`}
                    element={<AddEmployee />}
                  />
                  <Route path={`/user/:uuid`} element={<EmployeeDetails />} />
                  <Route path={`/vehicle`} element={<Vehicle />} />
                  <Route path={`/vehicle-class`} element={<VehicleClass />} />
                  <Route path={`/company/:uuid`} element={<CompanyDetails />} />
                  <Route path={`/places`} element={<Location />} />
                  {/* <Route path={`/places/create-places`} element={<PlaceMap/>}/>
                                    <Route path={`/places/create-places/:uuid`} element={<PlaceMap/>}/> */}
                  <Route path={`/route-path`} element={<RoutePath />} />
                  <Route path={`/setting`} element={<Setting />} />

                  <Route path={`/setting/:uuid`} element={<Setting />} />
                  <Route path={`/trip`} element={<TripMain />} />
                  <Route
                    path={`/trip/:uuid/tripdetails`}
                    element={<TripDetails />}
                  />
                  <Route path={`/trip/update`} element={<Tripupdate />} />
                  <Route path={`/order/order-state`} element={<OrderState />} />
                  <Route path={`/order/:uuid`} element={<OrderDetails />} />

                  <Route path={`/order`} element={<Order />} />
                  <Route path={`/subscription`} element={<Subscription />} />
                  <Route path={`/alarm`} element={<Alarm />} />
                  <Route
                    path={`/maintaninaceparm`}
                    element={<Maintainanceparm />}
                  />
                  <Route
                    path={`/maintaninacetask`}
                    element={<MaintainanceTask />}
                  />
                  <Route
                    path={`/maintaninacetask`}
                    element={<MaintainanceTask />}
                  />
                  <Route path={`/vehicle-task`} element={<VehicleTask />} />
                  <Route
                    path={`/company-provider`}
                    element={<CompanyProvider />}
                  />

                  <Route path={`/notification`} element={<Notification />} />

                  <Route path={`/invoice`} element={<Invoice />} />
                  <Route
                    path={`/vehicle-activity`}
                    element={<VehicleActivity />}
                  />
                  <Route path={`/management`} element={<Management />} />
                  <Route path={`/add-cost`} element={<AddCost />} />
                  <Route
                    path={`/administration`}
                    element={<Administration />}
                  />
                  <Route path={`/filter`} element={<FilterVehicle />} />

                  <Route path={`/driver`} element={<Drivers />} />
                  <Route path={`/reports`} element={<Reports />} />
                  <Route path={`/distribution`} element={<Distribution />} />
                  <Route path={`/crm`} element={<Customer />} />
                  <Route
                    path={`/subscription-history/:uuid`}
                    element={<SubscriptionHistory />}
                  />
                  {/* <Route
                    path={`/subscription-history`}
                    element={<SubscriptionHistory />}
                  /> */}
                  <Route
                    path={`/subscription-company`}
                    element={<SubscriptionCompanyList />}
                  />

                  {/* <Route path={`/add-driver`} element={<AddDriver />} />

                  <Route path={`/add-driver/:uuid`} element={<AddDriver />} /> */}

                  {/* <Route path={`/add-vehicle`} element={<AddVehicle />} /> */}
                  {/* <Route path={`/add-vehicle/:uuid`} element={<AddVehicle />} /> */}
                </Route>
                <Route
                  path={`/payment`}
                  element={
                    <Elements stripe={stripePromise}>
                      <Checkout />
                    </Elements>
                  }
                />
              </Route>
            </Route>
            <Route path={`/403`} element={<ForbiddenPage />} />
            <Route path={`/404`} element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
