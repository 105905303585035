import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { createAddDriverTachograph } from "../../services/EmployeeService";

const TachnographDriver = ({ setActiveTab, driverDetails }) => {
  const tableLengthList = ConfigDB.data.dataTableLength;

  const [tachnographData, setTachnographData] = useState({
    tachnograph_driver_number: "",
    tachnograph_driver_card_expiry_date: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  let DriverId = localStorage.getItem("driverId");

  const handleDrivingForm = (e) => {
    e.preventDefault();

    let data = {
      uuid: driverDetails?.uuid ? driverDetails?.uuid : DriverId,
      tachnograph_driver_number: tachnographData?.tachnograph_driver_number,
      tachnograph_driver_card_expiry_date:
        tachnographData?.tachnograph_driver_card_expiry_date,
    };

    modifyDriver(createAddDriverTachograph, data);
  };

  useEffect(() => {
    if (driverDetails) {
      setTachnographData({
        tachnograph_driver_number: driverDetails?.tachnograph_driver_number,
        tachnograph_driver_card_expiry_date:
          driverDetails?.tachnograph_driver_card_expiry_date,
      });
    }
  }, [driverDetails]);

  const modifyDriver = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setActiveTab("status");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleDrivingForm}>
              <h4>TachnoGraph</h4>
              <FormGroup>
                <div
                  className="row"
                  style={{
                    border: "solid #eee 1px",
                    borderRadius: "5px",
                    paddingBottom: "12px",
                  }}
                >
                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Tachograph driver number"}
                    </Label>
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="text"
                        value={tachnographData.tachnograph_driver_number}
                        onChange={(e) => {
                          setTachnographData({
                            ...tachnographData,
                            tachnograph_driver_number: e.target.value,
                          });
                        }}
                        name={`tachnograph_driver_number`}
                        placeholder="Tachograph driver number"
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <Label className="col-form-label">
                      {"Tachograph driver card expiry date"}
                    </Label>
                    <div className="input-div">
                      <input
                        type="date"
                        id=""
                        className="form-control"
                        value={
                          tachnographData?.tachnograph_driver_card_expiry_date
                        }
                        onChange={(e) => {
                          setTachnographData({
                            ...tachnographData,
                            tachnograph_driver_card_expiry_date: e.target.value,
                          });
                        }}
                        placeholder="Tachograph driver card expiry date"
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TachnographDriver;
