import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import {
  actionGetRolePermission,
  actionModifyRolePermission,
} from "../../services/RoleService";
import Utils from "../../utils";
import { loaderActions } from "../../store";

function Permission(props) {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [roleName, setRoleName] = useState("");
  const [permissionList, setPermissionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loaderActions.loader(true));
    let data = { uuid: uuid };
    actionGetRolePermission(data)
      .then((response) => {
        response = response.data;
        setRoleName(response.data.name);
        setPermissionList(response.data.permission);
        dispatch(loaderActions.loader(false));
      })
      .catch((err) => {
        dispatch(loaderActions.loader(false));
        toast.error(Utils.getErrorMessage(err));
        navigate("/roles");
      });
  }, [uuid, navigate, dispatch]);

  const handlePermissionItem = (e, index, idx) => {
    let list = [...permissionList];
    list[index]["list"][idx]["is_checked"] = e.target.checked;
    setPermissionList(list);
  };

  const modifyPermission = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let list = [...permissionList];
    let data = { uuid: uuid, permissions: list };
    actionModifyRolePermission(data)
      .then((response) => {
        toast.success(response.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="b-t-primary">
              <div className="inner-header py-3">
                <div className="left-block">
                  <h5>{roleName + ` - ` + `Permission`}</h5>
                </div>
              </div>
              <CardBody>
                {permissionList.map((item, i) => (
                  <fieldset key={i} className={`pb-3 pt-2 mb-4`}>
                    <legend>{item.name}</legend>
                    <Row>
                      {item.list.map((permission, p) => (
                        <Col sm={2} key={p} className={`mb-2`}>
                          <div className="checkbox-row">
                            <FormGroup check>
                              <div className="checkbox checkbox-primary">
                                <Input
                                  type="checkbox"
                                  checked={permission.is_checked}
                                  onChange={(e) =>
                                    handlePermissionItem(e, i, p)
                                  }
                                  id={`permission_${permission.permission_id}`}
                                />
                                <div className="checkmark"></div>
                                <Label
                                  for={`permission_${permission.permission_id}`}
                                  check
                                >
                                  {permission.display}
                                </Label>
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </fieldset>
                ))}
                <Row>
                  <Col sm={12} className={`text-right`}>
                    {!isLoading && (
                      <>
                        <NavLink
                          to={`/roles`}
                          role={`button`}
                          end
                          className={`btn btn-back me-2`}
                        >{`Back`}</NavLink>
                        <Button
                          onClick={modifyPermission}
                          type={`button`}
                          className={`btn btn-back-out-line`}
                        >{`Submit`}</Button>
                      </>
                    )}
                    {isLoading && (
                      <Button
                        color="primary"
                        type={`button`}
                        className={`btn-square`}
                        disabled={true}
                      >
                        <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                        {`Loading`}
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Permission;
