import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import {
  actionDelRole,
  actionStoreRole,
  actionUpdateRole,
  getRoleList,
} from "../../services/RoleService";
import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";

import plusIcon from "../../assets/images/plus-i.svg";

const tableLengthList = configDb.data.dataTableLength;

function Roles() {
  const auth = useSelector((x) => x.auth.value);
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [roleList, setRoleList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delRole, setDelRole] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [roleData, setRoleData] = useState({
    uuid: "",
    name: "",
    isCommon: false,
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { name: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [isChecked, setIsChecked] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });
  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getRoleList(data)
        .then((response) => {
          // response = decryptData(response.data);
          setTotalRecords(response.data.total);
          setRoleList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [params]
  );
  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };
  const onDeleteRole = (e, item) => {
    e.preventDefault();
    setDelRole(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelRole("");
    setDelModal(false);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delRole };
    actionDelRole(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        // response = decryptData(response.data);
        toast.success(response.data.message);
        setDelRole("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const onAddRole = (e) => {
    e.preventDefault();
    setRoleData({ uuid: "", name: "", isCommon: false });
    setModifyModal(true);
  };
  const onEditRole = (e, item) => {
    e.preventDefault();
    setRoleData({ uuid: item.uuid, name: item.name, isCommon: item.is_common });
    setModifyModal(true);
  };
  const onModifyModalClose = (e) => {
    setRoleData({ uuid: "", name: "", isCommon: false });
    setModifyModal(false);
    setErrors(errorsObj);
  };
  const handleRoleName = (e) => {
    let data = { ...roleData };
    data.name = e.target.value;
    setRoleData(data);
  };
  const handleRoleForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!roleData.name) {
      errorObj.name = `Required role name`;
      error = true;
    }
    setErrors(errorObj);

    if (error) return;

    let dataParams = { ...params };

    setIsModifyButtonLoad(true);
    if (roleData.uuid) {
      let data = {
        uuid: roleData.uuid,
        name: roleData.name,
        isCommon: roleData.isCommon,
      };
      actionUpdateRole(data)
        .then((response) => {
          toast.success(response.data.message);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
          setRoleData({ uuid: "", name: "", isCommon: false });
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      let data = { name: roleData.name, isCommon: roleData.isCommon };
      actionStoreRole(data)
        .then((response) => {
          toast.success(response.data.message);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
          setRoleData({ uuid: "", name: "", isCommon: false });
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleCheckboxChange = (event) => {
    setRoleData({
      ...roleData,
      isCommon: !roleData.isCommon,
    });
    setIsChecked(event.target.checked);
  };
  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Role List</h5>
        </div>

        <div className="right-block w-auto">
          {allPermissionsList.findIndex((e) => e.name == "modify_roles") >
            -1 && (
            <Button
              type={`button`}
              onClick={onAddRole}
              className={`add-btn btn float-right`}
            >
              {`Add Role`}
              <i className="plus-circle">
                <img src={plusIcon} alt="" className="plus-icons" />
              </i>
            </Button>
          )}
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-2">
                  <div className="selected-block selected-mobile">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>#</th>
                <th scope={`col`} width="60%">{`Role Name`}</th>
                {(allPermissionsList.findIndex(
                  (e) => e.name == "modify_roles"
                ) > -1 ||
                  allPermissionsList.findIndex(
                    (e) => e.name == "delete_roles"
                  ) > -1) && <th scope={`col`}>{`Action`}</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {roleList &&
                    roleList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.index}</td>
                        <td>{item.name}</td>
                        {(allPermissionsList.findIndex(
                          (e) => e.name == "modify_roles"
                        ) > -1 ||
                          allPermissionsList.findIndex(
                            (e) => e.name == "delete_roles"
                          ) > -1) && (
                          <td>
                            {item.isModify &&
                              allPermissionsList.findIndex(
                                (e) => e.name == "modify_roles"
                              ) > -1 && (
                                <>
                                  <Button
                                    type={`button`}
                                    onClick={(e) => onEditRole(e, item)}
                                    className={`btn btn-info add-btn-circle me-2`}
                                  >
                                    <i className="bi bi-pencil-fill"></i>
                                  </Button>
                                  {item.isPermission && (
                                    <NavLink
                                      to={`/roles/${item.uuid}/permissions`}
                                      role={`button`}
                                      className={`btn btn-success add-btn-circle me-2`}
                                    >
                                      <i className="bi bi-shield-lock-fill" />
                                    </NavLink>
                                  )}
                                </>
                              )}
                            {item.isDelete &&
                              allPermissionsList.findIndex(
                                (e) => e.name == "delete_roles"
                              ) > -1 && (
                                <Button
                                  type={`button`}
                                  onClick={(e) => onDeleteRole(e, item)}
                                  className={`btn btn-danger add-btn-circle btn-square me-2`}
                                >
                                  <i className="bi bi-trash-fill"></i>
                                </Button>
                              )}
                          </td>
                        )}
                      </tr>
                    ))}
                </>
              )}

              {roleList && roleList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_roles"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_roles"
                      ) > -1
                        ? 3
                        : 2
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Role`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this role?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modifyModal}>
        <ModalHeader>{roleData.uuid ? `Edit Role` : `Create Role`}</ModalHeader>
        <Form method={`post`} onSubmit={handleRoleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">
                {"RoleName"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={roleData.name}
                  name={`role_name`}
                  onChange={handleRoleName}
                  maxLength={50}
                  placeholder="Role Name"
                />
                {errors.name && (
                  <span className="input-error">{errors.name}</span>
                )}
              </div>

              {auth?.user_role == configDb.data.ROLE_ADMIN && (
                <div className="input-keep-me">
                  <label className="check-label">
                    <input
                      type="checkbox"
                      checked={roleData.isCommon}
                      onChange={handleCheckboxChange}
                    />
                    <div className="checkmark"></div>
                    {`Keep Common`}
                  </label>
                </div>
              )}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success"
                type={`submit`}
                className={`btn-square btn-info`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Roles;
