import React, { useState, useEffect } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import { actionUpdatePrivacyModeVehicle } from "../../services/VehicleService";

import Select from "react-select";

const useFuelType = [
  { value: "1", label: "Do not use privacy mode for this vehicle" },
  { value: "2", label: "Set privacy mode for this vehicle" },
];
const PrivacyMode = ({ setActiveTab, vehicleDetails }) => {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const [vehicleData, setVehicleData] = useState({
    privacy_mode: "1",
  });
  const VehicleDataId = localStorage.getItem("vehicleId");

  const [modifyModal, setModifyModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setVehicleData((prevData) => ({
      ...prevData,
      privacy_mode: selectedOption.value,
    }));

    if (selectedOption.value === "2") {
      setModifyModal(true);
    }
  };

  useEffect(() => {
    if (vehicleDetails) {
      setVehicleData(vehicleDetails);
    }
  }, [vehicleDetails]);

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let data = {
      privacy_mode: vehicleData?.privacy_mode,
      vehicle_id: VehicleDataId,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      data.companyId = vehicleData.companyId;
    }

    let updateData = {
      uuid: vehicleDetails?.uuid,
      vehicle_id: vehicleDetails?.id,
      privacy_mode: vehicleData?.privacy_mode,
    };

    if (JsonParse.user_role === ConfigDB.data.ROLE_ADMIN) {
      updateData.companyId = vehicleData.companyId;
    }

    if (vehicleDetails?.uuid) {
      modifySubscription(actionUpdatePrivacyModeVehicle, updateData);
    } else {
      modifySubscription(actionUpdatePrivacyModeVehicle, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);

        setVehicleData({
          privacy_mode: "1",
        });
        setActiveTab("status");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary general-ac">
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <FormGroup>
                <div className="row">
                  <div className="col-md-4">
                    <Label className="col-form-label">{"Option"}</Label>
                    <Select
                      options={useFuelType}
                      value={useFuelType.find(
                        (option) => option.value === vehicleData?.privacy_mode
                      )}
                      onChange={handleSelectChange}
                      placeholder={"Select Option"}
                    />
                  </div>
                  <Modal
                    isOpen={modifyModal}
                    toggle={() => setModifyModal(false)}
                  >
                    <ModalHeader toggle={() => setModifyModal(false)}>
                      Set Privacy Mode for This Vehicle
                    </ModalHeader>
                    <ModalBody>
                      If you choose this configuration, this vehicle will not be
                      visible in Frotcom as long as privacy mode is active. This
                      is often chosen if a vehicle is being used in private
                      activities, for instance during holidays. To reactivate
                      the tracking of this vehicle, choose again “Do not use
                      privacy mode for this vehicle”.
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="secondary btn-info"
                        onClick={() => setModifyModal(false)}
                      >
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  color="success btn-info"
                  type={`submit`}
                  className={`btn-square `}
                >{`Submit`}</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyMode;
