import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

import configDb from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import { actionDelCompany } from "../../services/CompanyService";
import { actionOrderList } from "../../services/TripService";

const tableLengthList = configDb.data.dataTableLength;

function Order(props) {
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [tripList, setTripList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [delModal, setDelModal] = useState(false);
  const [delCompany, setDelCompany] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });
  const navigate = useNavigate();

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      actionOrderList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setTripList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );
  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelCompany("");
    setDelModal(false);
  };

  const onDestroyCompany = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delCompany };
    actionDelCompany(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelCompany("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const ItemComponent = ({ item }) => {
    let badgeColor = "";
    switch (item.name) {
      case "In Progress":
        badgeColor = "bg-warning";
        break;
      case "Cancelled":
        badgeColor = "bg-danger";
        break;
      case "Delivered":
        badgeColor = "bg-success";
        break;
      case "Picked up":
        badgeColor = "bg-info";
        break;
      case "On Route":
        badgeColor = "bg-primary";
        break;
      default:
        badgeColor = "bg-secondary";
    }

    return (
      <span className={`badge rounded-pill btn-warning ${badgeColor}`}>
        {item.name}
      </span>
    );
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Order List</h5>
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col-xl-12">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-2">
                  <div className="selected-block selected-mobile">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`}>{`Trip Name`}</th>
                <th scope={`col`}>{`Status`}</th>

                {allPermissionsList.findIndex(
                  (e) => e.name == "view_details_trip"
                ) > -1 && <th scope={`col`}>{`Action`}</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {tripList &&
                    tripList?.data?.map((item, i) => (
                      <tr key={i}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/order/${item.uuid}`);
                          }}
                        >
                          {item?.trip_name}
                        </td>
                        <td>
                          <ItemComponent item={item} />
                        </td>

                        <td>
                          <NavLink
                            to={`/order/${item.uuid}`}
                            role={`button`}
                            className={`btn btn-success add-btn-circle me-2`}
                          >
                            <i className="bi bi-eye-fill" />
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                </>
              )}

              {tripList && tripList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modify_trip"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_trip"
                      ) > -1
                        ? 6
                        : 7
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`DeleteRole`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this company?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyCompany}
            >{`Delete`}</button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Order;
