import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import { actionCompanyList } from "../../services/Common";
import Select from "react-select";
import {
  actionAddOrderState,
  actionDelOrderState,
  actionUpdateOrderState,
  getOrderStateList,
} from "../../services/OrderStateService";

const tableLengthList = configDb.data.dataTableLength;

function OrderState(props) {
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const JsonParse = JSON.parse(data);
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [orderStateList, setOrderStateList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delVehicleClass, setDelVehicleClass] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [orderStateData, setOrderStateData] = useState({
    uuid: "",
    name: "",
    companyId: "",
    isCommon: false,
  });
  const [companyList, setCompanyList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { name: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
    company_id: 0,
  });

  const selectedCompany = companyList.find(
    (item) => item.value == orderStateData.companyId
  );

  useEffect(function () {
    actionCompanyList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setCompanyList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getOrderStateList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setOrderStateList(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onDeleteOrderState = (e, item) => {
    e.preventDefault();
    setDelVehicleClass(item.uuid);
    setDelModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelVehicleClass("");
    setDelModal(false);
  };

  const onDestroyOrderClass = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delVehicleClass };
    actionDelOrderState(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelVehicleClass("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };
  const onAddOrderStateClass = (e) => {
    e.preventDefault();
    setOrderStateData({ uuid: "", name: "", companyId: "" });
    setModifyModal(true);
  };
  const onEditOrderClass = (e, item) => {
    e.preventDefault();
    setOrderStateData({
      uuid: item.uuid,
      name: item.name,
      companyId: item.company_id,
    });
    setModifyModal(true);
  };
  const onModifyModalClose = (e) => {
    setOrderStateData({ uuid: "", name: "" });
    setModifyModal(false);
    setErrors(errorsObj);
  };
  const handleOrderStateName = (e) => {
    let data = { ...orderStateData };
    data.name = e.target.value;
    setOrderStateData(data);
  };

  const handleOrderStateForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!orderStateData.name) {
      errorObj.name = `Required order status name`;
      error = true;
    }
    setErrors(errorObj);

    if (error) return;

    let dataParams = { ...params };

    let data = {
      name: orderStateData.name,
      company_id: JsonParse.company_id,
    };
    let updateData = {
      name: orderStateData.name,
      company_id: orderStateData.companyId,
      uuid: orderStateData.uuid,
    };

    if (orderStateData.uuid) {
      actionUpdateOrderState(updateData)
        .then((response) => {
          toast.success(response.data.message);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
          setOrderStateData({ uuid: "", name: "", companyId: "" });
          setModifyModal(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      actionAddOrderState(data)
        .then((response) => {
          toast.success(response.data.message);
          dataParams.page = 1;
          dataParams.is_reload = !dataParams.is_reload;
          setParams(dataParams);
          setOrderStateData({ uuid: "", name: "", isCommon: false });
          setModifyModal(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const ItemComponent = ({ item }) => {
    let badgeColor = "";
    switch (item.name) {
      case "In Progress":
        badgeColor = "bg-warning";
        break;
      case "Cancelled":
        badgeColor = "bg-danger";
        break;
      case "Delivered":
        badgeColor = "bg-success";
        break;
      case "Picked up":
        badgeColor = "bg-info";
        break;
      case "On Route":
        badgeColor = "bg-primary";
        break;
      default:
        badgeColor = "bg-secondary";
    }

    return (
      <span className={`badge rounded-pill btn-warning ${badgeColor}`}>
        {item.name}
      </span>
    );
  };

  return (
    <>
      <div className="inner-header py-3">
        <div className="left-block">
          <h5>Order State List</h5>
        </div>

        <div className="right-block w-auto">
          {allPermissionsList.findIndex((e) => e.name == "modify_order") >
            -1 && (
            <Button
              type={`button`}
              onClick={onAddOrderStateClass}
              className={`add-btn btn float-right`}
            >
              {`Add Order State`}
              <i className="plus-circle">
                <img src={plusIcon} alt="" className="plus-icons" />
              </i>
            </Button>
          )}
        </div>
      </div>

      <div className="container-event">
        <div className="page-header-row">
          <div className="row">
            <div className="col">
              <div className="row d-flex justify-content-between company-section">
                <div className="col-xl-2">
                  <div className="selected-block">
                    <p>Show</p>
                    <select
                      className="form-select"
                      aria-label="Events Only"
                      defaultValue={params.limit}
                      onChange={(e) => handleParams(e, "length")}
                    >
                      {tableLengthList &&
                        tableLengthList.map((item, i) => (
                          <option value={item} key={i}>
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="col-xl-2">
                  <div className="search-bar">
                    <i className="bi bi-search"></i>
                    <input
                      type="text"
                      className="form-control"
                      value={params.search}
                      onChange={(e) => handleParams(e, "search")}
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-table-row">
          <table
            className={`table table-striped ${isLoading ? "loading" : ""}`}
            width="100%"
          >
            <thead>
              <tr>
                <th scope={`col`} width="5%">
                  #
                </th>
                <th scope={`col`}>{`Status`}</th>

                {(allPermissionsList.findIndex(
                  (e) => e.name == "modify_order"
                ) > -1 ||
                  allPermissionsList.findIndex(
                    (e) => e.name == "delete_order"
                  ) > -1) && <th scope={`col`} width="20%">{`Action`}</th>}
              </tr>
            </thead>
            <tbody>
              {orderStateList &&
                orderStateList?.data?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.index}</td>
                    <td>
                      <ItemComponent item={item} />
                    </td>

                    <td>
                      {item.name !== "PENDING" &&
                      item.name !== "COMPLETED" &&
                      item.name !== "CANCELED" &&
                      item.name !== "UP COMING" &&
                      item.name !== "PICKED UP" ? (
                        <Button
                          type={`button`}
                          onClick={(e) => onEditOrderClass(e, item)}
                          className={`btn btn-info add-btn-circle me-2`}
                        >
                          <i className="bi bi-pencil-fill"></i>
                        </Button>
                      ) : (
                        <>-</>
                      )}

                      {item.name !== "PENDING" &&
                        item.name !== "COMPLETED" &&
                        item.name !== "CANCELED" &&
                        item.name !== "UP COMING" &&
                        item.name !== "PICKED UP" && (
                          <Button
                            type={`button`}
                            onClick={(e) => onDeleteOrderState(e, item)}
                            className={`btn btn-danger add-btn-circle btn-square me-2`}
                          >
                            <i className="bi bi-trash-fill"></i>
                          </Button>
                        )}
                    </td>
                  </tr>
                ))}
              {orderStateList && orderStateList?.data?.length === 0 && (
                <tr>
                  <td
                    colSpan={
                      allPermissionsList.findIndex(
                        (e) => e.name == "modfiy_order"
                      ) > -1 ||
                      allPermissionsList.findIndex(
                        (e) => e.name == "delete_order"
                      ) > -1
                        ? 5
                        : 3
                    }
                    className={`text-center`}
                  >{`Record Not Found`}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className={`float-right`}>
          <Pagination
            activePage={params.page}
            itemsCountPerPage={parseInt(params.limit)}
            totalItemsCount={parseInt(totalRecords)}
            pageRangeDisplayed={5}
            itemClass={`page-item`}
            linkClass={`page-link`}
            onChange={(e) => handleParams(e, "pagination")}
          />
        </div>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Order State`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this company status?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square`}
              onClick={onDestroyOrderClass}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {orderStateData.uuid ? `Edit Order State` : `Create Order State`}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleOrderStateForm}>
          <ModalBody>
            <FormGroup>
              {JsonParse.user_role == ConfigDB.data.ROLE_ADMIN ? (
                <div className="col-xl-4" style={{ width: "100%" }}>
                  <div className="mb-3">
                    <Select
                      options={companyList}
                      value={
                        companyList.find(
                          (option) => option.value === orderStateData.companyId
                        ) || selectedCompany
                      }
                      onChange={(selectedOption) =>
                        setOrderStateData((prevData) => ({
                          ...prevData,
                          companyId: selectedOption.value,
                        }))
                      }
                      placeholder={"Select Company"}
                    />
                    {errors.companyId && (
                      <span className="input-error">{errors.companyId}</span>
                    )}
                  </div>
                </div>
              ) : null}

              <Label className="col-form-label">
                {"Order Status"}
                <span className={`text-danger ml-1`}>*</span>
              </Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={orderStateData.name}
                  name={`name`}
                  onChange={handleOrderStateName}
                  maxLength={50}
                  placeholder="Order Status"
                />
                {errors.name && (
                  <span className="input-error">{errors.name}</span>
                )}
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            <Button
              color="success"
              type={`submit`}
              className={`btn-square`}
            >{`Submit`}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default OrderState;
