import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import configDb, { ConfigDB } from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useSelector } from "react-redux";
import plusIcon from "../../assets/images/plus-i.svg";
import { actionDelVehicle } from "../../services/VehicleService";
import {
  createDepartment,
  getDepartmentList,
} from "../../services/ManagementService";

import AddDepartment from "./AddDepartment";

const Organization = () => {
  const tableLengthList = configDb.data.dataTableLength;
  const allPermissionsList = useSelector((x) => x.permission.value);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [delSubscription, setDelSubscription] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    name: "",
    description: "",
    code: "",
    contact: "",
    phone: "",
    email: "",
  });
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [params, setParams] = useState({
    limit: tableLengthList[3],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [departmentList, setDepartmentList] = useState([]);
  const [addDepatmentStatus, setAddDepartmentStatus] = useState(false);
  const [addDepatmentData, setAddDepartmentData] = useState({});

  const handleAddDepartmentStatus = (e, item) => {
    setAddDepartmentStatus(true);
    setAddDepartmentData(item);
  };

  useEffect(
    function () {
      let data = params;
      setIsLoading(true);
      getDepartmentList(data)
        .then((response) => {
          setTotalRecords(response.data.total);
          setDepartmentList(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const onAddSubscription = (e) => {
    e.preventDefault();
    setVehicleData({
      name: "",
      description: "",
      code: "",
      contact: "",
      phone: "",
      email: "",
    });
    setModifyModal(true);
  };

  const onEditSubscription = (e, item) => {
    e.preventDefault();
    setVehicleData({
      uuid: item.uuid,
      name: item?.name,
      description: item?.description,
      code: item?.code,
      contact: item?.contact,
      phone: item?.phone,
      email: item?.email,
    });
    setModifyModal(true);
  };

  const onDeleteModalClose = (e) => {
    e.preventDefault();
    setDelSubscription("");
    setDelModal(false);
  };

  const onDestroyRole = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delSubscription };
    actionDelVehicle(data)
      .then((response) => {
        let paramsObj = { ...params };
        paramsObj.is_reload = !paramsObj.is_reload;
        setParams(paramsObj);
        toast.success(response.data.message);
        setDelSubscription("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleVehicleForm = (e) => {
    e.preventDefault();

    let errorObj = { ...errorsObj };
    let error = false;

    if (!vehicleData?.name) {
      errorObj.name = "Name is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    let data = {
      name: vehicleData?.name,
      description: vehicleData?.description,
      code: vehicleData?.code,
      contact: vehicleData?.contact,
      phone: vehicleData?.phone,
      email: vehicleData?.email,
    };

    let updateData = {
      uuid: vehicleData?.uuid,
      name: vehicleData?.name,
      description: vehicleData?.description,
      code: vehicleData?.code,
      contact: vehicleData?.contact,
      phone: vehicleData?.phone,
      email: vehicleData?.email,
    };

    if (vehicleData?.uuid) {
      modifySubscription(createDepartment, updateData);
    } else {
      modifySubscription(createDepartment, data);
    }
  };

  const modifySubscription = (actionFunction, data) => {
    let dataParams = { ...params };
    actionFunction(data)
      .then((response) => {
        toast.success(response.data.message);
        dataParams.page = 1;
        dataParams.is_reload = !dataParams.is_reload;
        setParams(dataParams);
        setVehicleData({
          name: "",
          description: "",
          code: "",
          contact: "",
          phone: "",
          email: "",
        });
        setModifyModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const onModifyModalClose = (e) => {
    setVehicleData({
      name: "",
    });
    setModifyModal(false);
  };

  return (
    <>
      {addDepatmentStatus ? (
        <AddDepartment
          setAddDepartmentStatus={setAddDepartmentStatus}
          addDepatmentData={addDepatmentData}
          addDepatmentStatus={addDepatmentStatus}
        />
      ) : (
        <>
          <div className="inner-header py-3">
            <div className="left-block"></div>

            <div className="right-block w-auto">
              {allPermissionsList.findIndex((e) => e.name == "modify_vehicle") >
                -1 && (
                <Button
                  type={`button`}
                  onClick={handleAddDepartmentStatus}
                  className={`add-btn btn  float-right`}
                >
                  {`Add`}
                  <i className="plus-circle">
                    <img src={plusIcon} alt="" className="plus-icons" />
                  </i>
                </Button>
              )}
            </div>
          </div>

          <div className="container-event">
            <div className="page-header-row page-header-task-vehical">
              <div className="row">
                <div className="col">
                  <div className="row d-flex justify-content-between company-section">
                    <div className="col-xl-2">
                      <div className="selected-block selected-mobile">
                        <p>Show</p>
                        <select
                          className="form-select"
                          aria-label="Events Only"
                          defaultValue={params.limit}
                          onChange={(e) => handleParams(e, "length")}
                        >
                          {tableLengthList &&
                            tableLengthList.map((item, i) => (
                              <option value={item} key={i}>
                                {item}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-xl-2">
                      <div className="search-bar">
                        <i className="bi bi-search"></i>
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-table-row">
              <table
                className={`table table-striped ${isLoading ? "loading" : ""}`}
                width="100%"
              >
                <thead>
                  <tr>
                    <th scope={`col`}>{`Name`}</th>
                    <th scope={`col`}>{`Description`}</th>
                    <th scope={`col`}>{`Code`}</th>
                    <th scope={`col`}>{`Contact`}</th>
                    <th scope={`col`}>{`Phone/Extenstion`}</th>
                    <th scope={`col`}>{`Email`}</th>
                    <th scope={`col`}>{`Action`}</th>
                  </tr>
                </thead>
                <tbody>
                  {departmentList &&
                    departmentList?.data?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td style={{ cursor: "pointer" }}>
                            {item.name || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.description}
                          </td>
                          <td style={{ cursor: "pointer" }}>{item.code}</td>
                          <td style={{ cursor: "pointer" }}>
                            {item.contact || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.phone || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            {item.email || "-"}
                          </td>
                          <td style={{ cursor: "pointer" }}>
                            <button
                              type="button"
                              onClick={(e) =>
                                handleAddDepartmentStatus(e, item)
                              }
                              className="btn btn-info add-btn-circle me-2"
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  {departmentList && departmentList?.data?.length === 0 && (
                    <tr>
                      <td
                        colSpan={7}
                        className={`text-center`}
                      >{`Record Not Found`}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={`float-right`}>
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </>
      )}

      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {vehicleData?.uuid ? "Update Department" : "Create Department"}
        </ModalHeader>
        <Form method={`post`} onSubmit={handleVehicleForm}>
          <ModalBody>
            <FormGroup>
              <Label className="col-form-label">{"Name"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.name}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      name: e.target.value,
                    });
                  }}
                  name={`name`}
                  maxLength={50}
                  placeholder="Name"
                />
                {errors.name && (
                  <span className="input-error">{errors.name}</span>
                )}
              </div>

              <Label className="col-form-label">{"Description"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  id=""
                  placeholder="Description"
                  name={`description`}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      description: e.target.value,
                    });
                  }}
                  value={vehicleData.description}
                ></input>
              </div>
            </FormGroup>

            <FormGroup>
              <Label className="col-form-label">{"Code"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  type="text"
                  value={vehicleData.code}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      code: e.target.value,
                    });
                  }}
                  name={`code`}
                  maxLength={8}
                  placeholder="Code"
                />
              </div>

              <Label className="col-form-label">{"Contact Name"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  id=""
                  placeholder="Contact"
                  name={`contact`}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      contact: e.target.value,
                    });
                  }}
                  maxLength={20}
                  value={vehicleData.contact}
                ></input>
              </div>

              <Label className="col-form-label">{"Phone"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  id=""
                  placeholder="Phone"
                  name={`phone`}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      phone: e.target.value,
                    });
                  }}
                  minLength={9}
                  maxLength={9}
                  value={vehicleData.phone}
                ></input>
              </div>

              <Label className="col-form-label">{"Email"}</Label>
              <div className="input-div">
                <input
                  className="form-control"
                  id=""
                  placeholder="Email"
                  name={`email`}
                  onChange={(e) => {
                    setVehicleData({
                      ...vehicleData,
                      email: e.target.value,
                    });
                  }}
                  value={vehicleData.email}
                ></input>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type={`button`}
              disabled={isModifyButtonLoad}
              outline
              onClick={onModifyModalClose}
              className={`btn-square`}
            >{`Close`}</Button>
            {!isModifyButtonLoad && (
              <Button
                color="success btn-info"
                type={`submit`}
                className={`btn-square`}
              >{`Submit`}</Button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle?`}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type={`button`}
            disabled={isDelButtonLoad}
            outline
            onClick={onDeleteModalClose}
            className={`btn-square`}
          >{`Close`}</Button>
          {!isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              className={`btn-square bg-danger border-0`}
              onClick={onDestroyRole}
            >{`Delete`}</Button>
          )}
          {isDelButtonLoad && (
            <Button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading`}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Organization;
