import axiosInstance from "./AxiosInstance";

export function createEmployee(data) {
  return axiosInstance.post(`/employee/create-update`, data);
}

export function getEmployeeList(data) {
  return axiosInstance.post(`/employee/list`, data);
}

export function getEmployeeDetails(data) {
  return axiosInstance.post(`/employee/detail`, data);
}

export function getEmployeeDelete(data) {
  return axiosInstance.post(`/employee/delete`, data);
}

export function getEmployeeRoleList(data) {
  return axiosInstance.post(`/employee/role-list`, data);
}

export function createAddDriver(data) {
  return axiosInstance.post(`/driver/create-update`, data);
}

export function createAddProfessional(data) {
  return axiosInstance.post(`/driver/professional-update`, data);
}

export function createAddDriverEmergency(data) {
  return axiosInstance.post(`/driver/emergency-update`, data);
}

export function createAddDriverPersonal(data) {
  return axiosInstance.post(`/driver/personal-info-update`, data);
}

export function createAddDriverTachograph(data) {
  return axiosInstance.post(`/driver/tachograph-update`, data);
}

export function createAddDriverStatus(data) {
  return axiosInstance.post(`/driver/update-status`, data);
}

export function createAddDriverPrivacyMode(data) {
  return axiosInstance.post(`/driver/update-privacy-mode`, data);
}

export function createAddDriverLicense(data) {
  return axiosInstance.post(`/driver/license-addupdate`, data);
}

export function destoryDriverLicense(data) {
  return axiosInstance.post(`/driver/license-delete`, data);
}

export function getDriverListLicense(data) {
  return axiosInstance.post(`/driver/license-list`, data);
}

export function getDriverListPrivacyMode(data) {
  return axiosInstance.post(`/driver/list-privacy-mode`, data);
}

export function getDriverAllDetails(data) {
  return axiosInstance.post(`/driver/get-all-details`, data);
}

export function createAddUserGeneral(data) {
  return axiosInstance.post(`/user/create-update`, data);
}

export function createAddUserFilter(data) {
  return axiosInstance.post(`/user/filter-add-update`, data);
}

export function createAddUserInteact(data) {
  return axiosInstance.post(`/user/interact-update`, data);
}

export function getUsersAllDetails(data) {
  return axiosInstance.post(`/user/get-all-user-details`, data);
}

export function getLoginDataUser(data) {
  return axiosInstance.post(`/get-all-log-list`, data);
}

export function createUserFilterVehicle(data) {
  return axiosInstance.post(`/user-vehicle-filter`, data);
}
